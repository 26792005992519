import React from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import {Card, CardBody} from "reactstrap";
// import Panel from '../../../../shared/components/Panel';
// import getTooltipStyles from '../../../../shared/helpers';
// import {useSecurityStatsQuery} from "../../../../queries/SecurityStats";
import EventsAggregatesLineChart from "../../../../shared/components/charts/withdata/EventsAggregatesLineChart";

// const _ = require("lodash")


const EventsPanel = () => {
  return (
    <Card>
      <CardBody>
        <EventsAggregatesLineChart />
      </CardBody>
    </Card>
  );
};

EventsPanel.propTypes = {
};

export default EventsPanel;
