import React from 'react';
import { Button } from 'reactstrap';

const UnknownError = () => {
  
  return (
    <div className='d-flex flex-row '>
      <div className='justify-content-center align-item-center'>
        <h1>Unexpected Error</h1>
        <Button color='secondary' onClick={() => window.history.go(-1)}>GO BACK</Button>
      </div>
    </div>
  )
}

export default UnknownError;