import {Button} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import UploadIcon from "mdi-react/UploadIcon";

const UpdateBoardButton = ({setIsNotificationBarVisible}) =>
    <Button outline color={'warning'} className={'topbar__button_notification mr-4'} onClick={() => setIsNotificationBarVisible(true)}>
      <UploadIcon />
    </Button>


UpdateBoardButton.propTypes = {
  setIsNotificationBarVisible: PropTypes.func.isRequired
}


export default UpdateBoardButton;
