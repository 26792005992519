import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DropdownToggle, DropdownMenu, Dropdown
} from 'reactstrap';
import {useAuth0AccessToken} from "../../../queries";

import {exportEvents} from "../../../queries/SecurityEvents";

const FORMATS = [
  'CSV',
  'JSON'
]

const SecurityEventsExport = ({ isOpen, toggle, eventType, boardId, tableState }) => {
  const { data: accessToken } = useAuth0AccessToken()
  const searchFilter = tableState.filter
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        data-toggle="dropdown"
        tag="span"
      >
        Export
      </DropdownToggle>
      <DropdownMenu>
        {
          FORMATS.map((format, i) => (
            <div onClick={() => exportEvents(accessToken, eventType, format, searchFilter, boardId, true)} key={`event-tab-${i}`} className='event-format'>
              { format }
            </div>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
};

SecurityEventsExport.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  eventType: PropTypes.string,
  boardId: PropTypes.string,
}

export default connect(state => ({
  tableState: state.tableState
}))(SecurityEventsExport);
