export const isUpdateNotificationVisible = () =>
  localStorage.getItem('updateNotificationVisibility') === null ||
    localStorage.getItem('updateNotificationVisibility') === 'visible';


export const changeUpdateNotificationVisibility = (visible) => {
  if (visible) {
    localStorage.setItem('updateNotificationVisibility', 'visible');
  } else {
    localStorage.setItem('updateNotificationVisibility', 'hidden');
  }
}