import {useMemo} from "react";
import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import moment from "moment";
import {apiUrl} from "../config/api"
import axios from "axios";


const _ = require("lodash");

// export const STATS_IPS = 'ips';
// export const STATS_DOMAINS = 'domains';

export const TIME_UNIT_DAYS = 'days';
export const TIME_UNIT_HOURS = 'hours';



/*
 Event Stats are AGGREGATES
 */

// const eventsStatsFetcher = () => (
//   fetch(`${apiUrl}/operations/security-data/stats`)
//   .then(res => res.json())
// )
//
// const eventsStatsByBoardIdFetcher = (boardId) => (
//   fetch(`${apiUrl}/operations/security-data/stats/${boardId}`)
//     .then(res => res.json())
// )


function lastNHours(n) {
  const hoursAgo = []
  for(let i = 0; i < n; i++) {
    const m = moment().subtract(i, 'hours');
    hoursAgo.push({day: m.format("YYYY/MM/DD"), hour: _.toString(m.hours())})
  }
  return hoursAgo;
}

function fillMissingByHour(data, limit) {
  /*
    This method is needed for cases in which a hour is not present in the data.
    Ex: {2021/04/22 "19" -> 4 events, 2021/04/22 "17" -> 2 events, ...} => 2021/04/22 "18" is missing and must be filled with a 0
    NOTE: The return is still an array
   */
  const lastDays = lastNHours(limit);

  return _.values(
    _.merge(
      {},
      _.keyBy(lastDays.map(value => ({day: value.day, hour: value.hour, cnt: 0})), (o) => o.day + o.hour),
      _.keyBy(data.map(value => ({day: value.day, hour: value.hour, cnt: value.cnt})), (o) => o.day + o.hour),
    )
  );

}

function lastNDays(n) {
  const daysAgo = []
  for(let i = 0; i < n; i++) {
    daysAgo.push(moment().subtract(i, 'days').format("YYYY/MM/DD"))
  }
  return daysAgo;
}

function fillMissingByDay(data, limit) {
  /*
    This method is needed for cases in which a day is not present in the data.
    Ex: {2021/04/22 -> 4 events, 2021/04/20 -> 2 events, ...} => 2021/04/21 is missing and must be filled with a 0
    NOTE: The return is still an array
   */
  const lastDays = lastNDays(limit);
  return _.values(
    _.merge(
      {},
      _.keyBy(lastDays.map(value => ({day: value, cnt: 0})), 'day'),
      _.keyBy(data.map(value => ({day: value.day, cnt: value.cnt})), 'day'),
    )
  );
}

export function combineBy(combineKey, ...sources) {
  const keyedSources = _.map(sources, value => (_.keyBy(value, combineKey)));
  return _.values(
    _.mergeWith(
      {},
      ...keyedSources,
      (objVal, srcVal) => ((objVal) ? {...objVal, cnt: objVal.cnt + srcVal.cnt} : srcVal)
    )
  );
}

function combineByDay(...sources) {
  return combineBy('day', ...sources);
}

function combineByHour(...sources) {
  return combineBy('hour', ...sources);
}


function refineData(timeUnit, limit, data, isDataAvailable) {
  const refineFn = (timeUnit === TIME_UNIT_DAYS) ? fillMissingByDay : fillMissingByHour;
  const combineFn = (timeUnit === TIME_UNIT_DAYS) ? combineByDay : combineByHour;
  if (isDataAvailable && data) {
    const refinedIpStats = refineFn(data.ipStats, limit).slice(0, limit);
    const refinedDnsStats = refineFn(data.dnsStats, limit).slice(0, limit);
    return {
      ipStats: refinedIpStats,
      dnsStats: refinedDnsStats,
      combinedStats: combineFn(refinedIpStats, refinedDnsStats),
    }
  } else {
    return null;
  }
}


export const eventsStatsFetcher = (accessToken, timeUnit, limit, blockedOnly = false, boardId = null, eventClass = null) => {
  if (timeUnit !== TIME_UNIT_DAYS && timeUnit !== TIME_UNIT_HOURS) throw new Error('timeUnit must be one of [TIME_UNIT_DAYS, TIME_UNIT_HOURS]');
  const uri = (boardId) ? `/${boardId}` : '';
  let optQueryParams = (eventClass) ? `&eventClass=${eventClass}` : '';
  return axios.get(
    `${apiUrl}/operations/security-data/stats${uri}?timeUnit=${timeUnit}&limit=${limit}&blockedOnly=${!!blockedOnly}${optQueryParams}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}


export const useSecurityStatsQuery = (timeUnit, limit, blockedOnly = false, boardId = null, eventClass = null, fetcher = eventsStatsFetcher) => {
  return useQueryWithAuthentication(
    ['events-stats', timeUnit, limit, blockedOnly, boardId, eventClass],
    (accessToken) => fetcher(accessToken, timeUnit, limit, blockedOnly, boardId, eventClass)
  );
}


export const useSecurityStatsQueryWithRefinedData = (timeUnit, limit, blockedOnly = false, boardId = null, eventClass = null, fetcher = eventsStatsFetcher) => {
  const { data, isIdle, isLoading, isError, ...other } = useQueryWithAuthentication(
    ['events-stats', timeUnit, limit, blockedOnly, boardId, eventClass],
    (accessToken) => fetcher(accessToken, timeUnit, limit, blockedOnly, boardId, eventClass)
  );
  return useMemo(() =>
    ({
      data: data,
      isLoading: isLoading,
      isError: isError,
      refinedData: refineData(timeUnit, limit, data, (!isIdle && !isLoading && !isError)),
      ...other,
    }),
  [
    data, 
    isIdle, 
    isLoading, 
    isError,
    limit,
    other,
    timeUnit
  ]
  );
}



/*****************************************************************************************************
 Shorthands -> returning data-hooks with lists [same as useQuery but data contains the selected list]
 *****************************************************************************************************/

// export const useSecurityStatsQueryDataFn = (statsType, boardId = null) => {
//   /*
//   Following useCallback has a named callback with PascalCase to avoid the ESLint rule.
//   More info on: https://github.com/facebook/react/issues/20531 and https://reactjs.org/docs/hooks-faq.html#what-exactly-do-the-lint-rules-enforce.
//   IMPORTANT NOTE: This function returns a custom hook so to enforce hook rules please assign the return value of this
//   function to a useXXX variable.
//    */
//   return useCallback(function MemoizedCustomHook() {
//     const {data, ...other} = useSecurityStatsQuery(boardId);
//     let listData;
//     if (statsType === STATS_IPS) {
//       listData = data?.ipStats;
//     } else if (statsType === STATS_DOMAINS) {
//       listData = data?.dnsStats;
//     } else {
//       throw 'statsType must be one of [STATS_IP, STATS_DOMAIN]'
//     }
//
//     return {...other, data:listData}
//   }, [boardId, statsType]);
// }
