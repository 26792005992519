import {Button, Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {WithDisableTooltip} from "../../../../shared/hocs/WithDisableTooltip";
import {useForm} from "react-hook-form";
import VpnZeroTrustGenericFieldsForm from "../../../../shared/components/form/VpnZeroTrustGenericFieldsForm";
import VpnZeroTrustBoardFieldsForm from "../../../../shared/components/form/VpnZeroTrustBoardFieldsForm";


const DisabledButtonWithTooltip = WithDisableTooltip(Button);


const VpnZeroTrustBoardSubmit = ({config, onSubmit, title = ''}) => {
  const {register, handleSubmit, formState: {errors}, control, watch} = useForm({
    defaultValues:  {
      allowedIps: config.allowed_ips,
      serverEndpoint: config.server_endpoint,
      pubKey: config.server_pub_key,
      pvtKey: config.board_pvt_key,
      boardVpnAddress: config.board_vpn_address,
      status: config.status
    }
  });

  return (
    <Card style={{height: 'auto'}}>
      <CardBody>
        {
          title && <div className="card__title">
            <h4 className={'bold-text d-inline'}>{title}</h4>
          </div>
        }
        <Row>
          <form className="form form--vertical w-100">
          <Col md={6}>
            <VpnZeroTrustBoardFieldsForm
              errors={errors}
              control={control}
              register={register}
            />
          </Col>
            <Col md={6}>
              <VpnZeroTrustGenericFieldsForm
                errors={errors}
                control={control}
                register={register}
                watch={watch}
              />
            </Col>
          </form>
        </Row>
        <Row>
          <Col className={'mt-3'}>
            <DisabledButtonWithTooltip outline className={'float-right'} onClick={handleSubmit(onSubmit)} color={'success'}>
              Update configuration
            </DisabledButtonWithTooltip>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

VpnZeroTrustBoardSubmit.propTypes = {
  config: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
}


export default VpnZeroTrustBoardSubmit;
