import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout  } = useAuth0()

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  // const logout = () => {
  //   localStorage.removeItem('easydev');
  // };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(user && user.picture) || (user && user.avatar) || Ava}
          alt="avatar"
        />
        <p className="topbar__avatar-name">
          { isLoading ? 'Loading...' : (user ? (user.name || user.fullName) : 'Guest')}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && <button className="topbar__back" type="button" onClick={toggleProfile} />}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {isAuthenticated ?
            <React.Fragment>
              {/*<TopbarMenuLink*/}
              {/*  title="My Profile"*/}
              {/*  icon="user"*/}
              {/*  path="/account/profile"*/}
              {/*  onClick={toggleProfile}*/}
              {/*/>*/}
              {/*<TopbarMenuLink*/}
              {/*  title="Inbox"*/}
              {/*  icon="inbox"*/}
              {/*  path="/mail"*/}
              {/*  onClick={toggleProfile}*/}
              {/*/>*/}
              {/*<div className="topbar__menu-divider" />*/}
              {/*<TopbarMenuLink*/}
              {/*  title="Account Settings"*/}
              {/*  icon="cog"*/}
              {/*  path="/account/profile"*/}
              {/*  // onClick={toggleProfile}*/}
              {/*  disabled*/}
              {/*/>*/}
              {/*<div className="topbar__menu-divider" />*/}

              {/* <TopbarMenuLink
                title="Log Out"
                icon="exit"
                path="/logout"
                onClick={() => logout({returnTo: `${window.location.origin}`})}
              /> */}

              {/* <p className='version-hash'>Version hash: {process.env.REACT_APP_GIT_SHA}</p> */}

              <Button className="topbar__link btn-link topbar-logout-btn" onClick={() => logout({returnTo: `${window.location.origin}`})}>
                <span className={`topbar__link-icon lnr lnr-exit`} />
                Log Out
              </Button>
            </React.Fragment>
            :
            <React.Fragment>
              <TopbarMenuLink
                  title="Log in"
                  icon="enter"
                  path="/log_in"
                  onClick={loginWithRedirect}
                  // onClick={loginWithPopup}
              />
            </React.Fragment>
          }
          {/*<TopbarMenuLink*/}
          {/*  title="Log Out"*/}
          {/*  icon="exit"*/}
          {/*  path="/log_in"*/}
          {/*  onClick={logout}*/}
          {/*/>*/}
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  // user: UserProps.isRequired,
  // auth0: AuthOProps.isRequired,
};

export default TopbarProfile;
