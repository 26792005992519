import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import Loading from "../../shared/components/Loading";
import CompanyDashboard from "./CompanyDashboard";

const Dashboard = () => {
  const {isLoading} = useAuth0()

  return (isLoading ?
      (
        <Loading />
      ) : (
        <CompanyDashboard />
      )
  );
};

export default Dashboard;