import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import paths from '../../../config/paths'
import featureFlags from '../../../config/feature-flags'

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => {
  const { logout } = useAuth0()
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Dashboard" exact="true" route="/" icon="home" onClick={hideSidebar} />
        <SidebarLink title="Dogma Boards" route={paths.boardsPath} icon="layers" onClick={hideSidebar} />
        {/*<SidebarLink title="Events" route="/devices" icon="alarm" onClick={hideSidebar} />*/}
        <SidebarLink title="Events" route={paths.eventsPath} icon="bullhorn" onClick={hideSidebar} />
        {/*<SidebarCategory title="Activity" icon="bullhorn" sidebarCollapse={sidebarCollapse}>*/}
        {/*  <SidebarLink title="All Events" route={`${paths.eventsPath}?blocked=false`} onClick={hideSidebar} />*/}
        {/*  <SidebarLink title="Blocked Events" route={`${paths.eventsPath}?blocked=true`} onClick={hideSidebar} />*/}
        {/*</SidebarCategory>*/}
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory title="Configuration" icon="cog" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Company" route={paths.configurationCompanyPath} onClick={hideSidebar} />
          <SidebarLink title="Groups" route={paths.configurationGroupsPath} onClick={hideSidebar} />
          <SidebarLink title="Boards" route={paths.configurationBoardsPath} onClick={hideSidebar} />
          <SidebarLink title="Emitters" route={paths.configurationEmittersPath} onClick={hideSidebar} />
          {featureFlags.bulkPolicyUpload === 'true' && (
            <SidebarLink title="Policies" route={paths.policyConfigurationPath} onClick={hideSidebar} />
          )}
        </SidebarCategory>
      </ul>
      <div className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" route={paths.loginPath} onClick={() => logout({ returnTo: `${window.location.origin}` })} />
        {/*

        <Button className="sidebar__link btn-link topbar-logout-btn" onClick={() => logout({ returnTo: `${window.location.origin}` })}>
          <span className={`sidebar__link-icon lnr lnr-exit`} />
          Log Out
        </Button>*/}
      </div>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
