import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const UpdateGroupConfigFetcher = (accessToken, {groupId, params}) => {
  return axios.put(`${apiUrl}/operations/board-groups/${groupId}/config/customer`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateGroupConfigMutation = (fetcher = UpdateGroupConfigFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-group-config-customer'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-group-config-customer')}
  )
}
