import React from 'react'
import * as Sentry from "@sentry/react";
import { Link } from 'react-router-dom';

const SENTRY_TAGS = {
  'routing.instrumentation': 'react-router-v6-custom',
};

function TrackingLink(props) {
  React.useEffect(() => {
    const transaction = Sentry.startTransaction({ name: window.location.pathname });
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
    const span = transaction.startChild({
      op: 'navigate',
      tags: SENTRY_TAGS
    });

    return function cleanUp() {
      span.finish();
      transaction.finish();
    }
  })

  return (
    <Link {...props} />
  )
}

export default TrackingLink