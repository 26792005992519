import axios from "axios";
import {useQueryClient} from "react-query";
import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {useMutationWithAuthentication} from "../mutations";
import {apiUrl} from "../config/api"


export const emittersFetcher = (accessToken) => {
  return axios.get(
    `${apiUrl}/operations/security-logs/emitters`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useEmittersQuery = (fetcher = emittersFetcher) => {
  return useQueryWithAuthentication(
    ['emitters'],
    (accessToken) => fetcher(accessToken),
    { retry: false }
  );
}

export const emitterFetcher = (accessToken, emitterId) => {
  return axios.get(
    `${apiUrl}/operations/security-logs/emitters/${emitterId}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useEmitterQuery = (emitterId, fetcher = emitterFetcher) => {
  return useQueryWithAuthentication(
    ['emitter'],
    (accessToken) => fetcher(accessToken, emitterId),
    { retry: false }
  );
}

export const createEmitterFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrl}/operations/security-logs/emitters`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useCreateEmitterMutation = (fetcher = createEmitterFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['create-emitter'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('create-emitter');
      }}
  )
}

export const updateEmitterKey = (accessToken, emitterId) => {
  return axios.post(
    `${apiUrl}/operations/security-logs/emitters/${emitterId}/refresh-key`,
    {},
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useUpdateEmitterKeyMutation = (fetcher = updateEmitterKey) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['update-emitter-key'],
    (accessToken, emitterId) => fetcher(accessToken, emitterId),
    {onSuccess: () => {
        queryClient.invalidateQueries('update-emitter-key');
      }}
  )
}

export const deleteEmitter = (accessToken, emitterId) => {
  return axios.delete(
    `${apiUrl}/operations/security-logs/emitters/${emitterId}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useDeleteEmitterMutation = (fetcher = deleteEmitter) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['del-emitter'],
    (accessToken, emitterId) => fetcher(accessToken, emitterId),
    {onSuccess: () => {
        queryClient.invalidateQueries('del-emitter');
      }}
  )
}