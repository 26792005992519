import {addAuthToFetchOpts} from '../../config/queryopts'
import {useQueryWithAuthentication} from "../index";
import {apiUrlUpdate} from "../../config/api"
import axios from "axios";


export const CompanyNumberDeviceUpdatesFetcher = (accessToken, companyId) => {
  return axios.get(`${apiUrlUpdate}/companies/${companyId}/updates`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useCompanyNumberDeviceUpdatesQuery = (companyId, fetcher = CompanyNumberDeviceUpdatesFetcher) => {
  return useQueryWithAuthentication(
    ['company-number-updates', companyId],
    (accessToken) => fetcher(accessToken, companyId),
    {refetchInterval: 10000, enabled: companyId !== null, cacheTime: 0}
  )
}
