import React, {useEffect} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {PropTypes} from "prop-types";
// import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {useUpdateEmitterKeyMutation, useDeleteEmitterMutation} from "../../../../queries/SecurityLogs";
import {truncateStr} from "../../../../shared/helpers/display"
// import {useQueryClient} from "react-query";
import {toast} from "react-hot-toast";
import EyeIcon from "mdi-react/EyeIcon";
import paths from "../../../../config/paths";
// import _ from "lodash";


const EmitterDetailsBody = ({emitter, rehydrateData}) => {
  const [canSeeFullApiKey, setApiKeyVizibility] = React.useState(false)
  const [apiKeyDisplay, _setApiKeyDisplay] = React.useState(truncateStr(emitter.apiKey))
  const nav = useNavigate();

  const mutation = useUpdateEmitterKeyMutation();
  const updateEmitterKey = () => {
    mutation.mutate(emitter.id);
  }

  const delMutation = useDeleteEmitterMutation();
  const delEmitter = () => {
    delMutation.mutate(emitter.id);
  }

  React.useEffect(() => {
    if (canSeeFullApiKey) {
      _setApiKeyDisplay(emitter.apiKey)
    } else {
      _setApiKeyDisplay(truncateStr(emitter.apiKey))
    }
  }, [canSeeFullApiKey, emitter.apiKey])

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Updating emitters API key', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          rehydrateData()
        } else if (mutation.isError) {
          toast('Error during the update of the emitter',{icon: '❌'});
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess, mutation, rehydrateData])

  useEffect(() => {
    if (!delMutation.isIdle) {
      if (delMutation.isLoading) {
        toast('Deleting emitter', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (delMutation.isSuccess) {
          nav(`${paths.configurationEmittersPath}`);
        } else if (delMutation.isError) {
          toast('Error during the deletion of the emitter',{icon: '❌'});
          delMutation.reset();
        }
      }
    }
  }, [delMutation.isIdle, delMutation.isError, delMutation.isLoading, delMutation.isSuccess, delMutation, rehydrateData, nav])

  return (
    <Card style={{height: 'auto'}} className="emitter-details--card">
      <CardBody>
        <Row>
          <Col md={12}>
            <form className="form form--vertical w-100">
              <Row className={'w-100'}>
                <Col md={6} lg={3} xs={12}>
                <div className="card__title">
                  <h5 className="bold-text">Name</h5>
                  <p className={"subhead"}>{emitter.name}</p>
                </div>
                </Col>
                <Col md={6} lg={3} xs={12}>
                  <div className="card__title">
                    <h5 className="bold-text">Company ID</h5>
                    <p className={"subhead"}>{emitter.companyId}</p>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="card__title">
                    <h5 className="bold-text">API Key</h5>
                    <div className="subhead-container">
                      <p className={"subhead"}>{apiKeyDisplay}</p>
                      <Button disabled={false} className="api-key-display-toggle" onClick={() => setApiKeyVizibility(!canSeeFullApiKey)}><EyeIcon /></Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button outline className={'float-right del-emitter-btn'} onClick={delEmitter} color={'danger'}>Delete Emitter</Button>
            <Button outline className={'float-right'} onClick={updateEmitterKey} color={'success'}>Refresh API Key</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

EmitterDetailsBody.propTypes = {
  emitter: PropTypes.shape({
    name: PropTypes.string,
    companyId: PropTypes.string,
    format: PropTypes.number,
    ipEventsCursorUTC: PropTypes.string,
    dnsEventsCursorUTC: PropTypes.string
  }).isRequired,
  rehydrateData: PropTypes.func.isRequired
}

export default EmitterDetailsBody;
