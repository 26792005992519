import React, {Fragment, useCallback, useState} from 'react';
import {Tooltip} from "reactstrap";


export const WithDisableTooltip = (Component) => {
  function ComponentWithDisableTooltip ({isOpen, setIsDetailOpen, placement = 'bottom', ...otherProps}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = useCallback(() => {setTooltipOpen(!tooltipOpen)}, [setTooltipOpen, tooltipOpen]);

    return (
      <Fragment>
        <Tooltip placement={placement}  target="TooltipExample" toggle={toggle} isOpen={tooltipOpen}>
          This feature is not available for the current user
        </Tooltip>
        <div
          id={"TooltipExample"}
          className={'d-inline-block ' + otherProps.className}
        >
          <Component
            disabled
            {...otherProps}
          />
        </div>
      </Fragment>
    );
  }

  ComponentWithDisableTooltip.propTypes = {
    ...Component.propTypes,
  }

  return ComponentWithDisableTooltip;
}
