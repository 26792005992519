import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import ConfigurationGroupsTable from "./components/ConfigurationGroupsTable";
import AddGroupModal from "./components/AddGroupModal";
import { useUserInfo } from "../../../shared/helpers/auth";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";

import BulkUploadBoardsGroup from "./components/BulkUploadBoardsGroup";
import axios from "axios";
import { apiUrl } from '../../../config/api';
import { addAuthToAxiosOpts } from "../../../config/queryopts";
import { useAuth0AccessToken } from "../../../queries";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

const ConfigurationGroups = () => {
  const nav = useNavigate();
  const { companyId, isLoading } = useUserInfo();
  const { handleSubmit, control } = useForm();
  const queryClient = useQueryClient();
  const { data: accessToken } = useAuth0AccessToken()


  const onSubmitFile = (data) => {
    const csv = data.csv;
    if (csv && csv.length > 0 && csv[0]) {
      toast('Uploading...', { icon: '⏳', duration: Infinity });
      const formData = new FormData();
      formData.append('formFile', csv[0].file, csv[0].filename);
      axios.put(`${apiUrl}/operations/board-groups/boards/bulk`, formData,
        addAuthToAxiosOpts(accessToken, { 'Content-Type': 'multipart/form-data' }, 0),
      ).then(
        (res) => {
          toast.dismiss()
          if (res.status === 200) {
            if (res.data.success.length != 0){
              toast.success(`Group ${res.data.success[0].key}\n\nBoard Update ${JSON.stringify(res.data.success[0].value)}`)
            }
            if (res.data.failed.length != 0) {
              toast.error(`Group ${res.data.failed[0].key}\n\nBoard Failed ${JSON.stringify(res.data.failed[0].value)}`)
            }
            toast('Upload Complete', { icon: '✔️' });
            queryClient.refetchQueries(['upload-board']);
          }
        }
      ).catch((error) => {
        toast.dismiss();
        if (error.request?.response) {
          toast(`Error: ${error.request.response.replace(']', '').replace('[', '')}.`, { icon: '❌' });
        } else {
          toast(`Error while uploading the CSV file.`, { icon: '❌' });
        }
      })
    }
  }
  if (isLoading) {
    return <LoadingSpinner />
  }


  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configuration groups</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AddGroupModal companyId={companyId} />
          <BulkUploadBoardsGroup control={control} onSubmit={handleSubmit(onSubmitFile)} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <ConfigurationGroupsTable
                onRowClick={(dataId) => nav(dataId)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfigurationGroups;
