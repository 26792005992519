import axios from "axios";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


export const configurationGroupFetcher = (accessToken, groupId) => {
  return axios.get(`${apiUrl}/operations/board-groups/${groupId}/config/customer`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useGroupConfigQuery = (groupId, fetcher = configurationGroupFetcher) => {
  return useQueryWithAuthentication(['group-config-customer', groupId], (accessToken) => fetcher(accessToken, groupId))
}
