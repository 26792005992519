import React, {useCallback, useEffect} from "react";
import {Badge, CardBody, Col, Card} from "reactstrap";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {getBoardRunningState, getBoardStatusLabel, getStatusColorFromLabel} from "../../../../shared/helpers/querydata/boardsdata";
import PropTypes from "prop-types";


const statusAccessor = (row) => getBoardStatusLabel(getBoardRunningState(row))


const tableColumns = [
  { Header: 'Serial Number', accessor: 'serialNumber', minWidth: 300 },
  { Header: 'Status', accessor: statusAccessor,
    Cell: ({value}) => (<Badge color={getStatusColorFromLabel(value)} className={"outline"}>{value}</Badge>)},
  // { Header: 'Group', accessor: 'group'}
];

const tableConfig = {
  withPagination: true,
  withHover: true,
  withSearchEngine: true,
}

const BoardsList = ({boards, section, setSelectedBoard}) => {
  const getRowDataId = useCallback((row, i) => i, [])  
  return (
      <Col md={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h4 className={'bold-text d-inline'}>Boards</h4>
            </div>
            <TableWithData
              tableColumns={tableColumns}
              tableConfig={tableConfig}
              data={boards}
              getRowDataId={getRowDataId}
              onRowClick={(index) => setSelectedBoard(boards[index])}
            />
          </CardBody>
        </Card>
      </Col>
  )
}

BoardsList.propTypes = {
  boards: PropTypes.array.isRequired,
  section: PropTypes.number.isRequired,
  setSelectedBoard: PropTypes.func.isRequired
}

export default BoardsList;
