import React from "react";
import {Navigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

import auth0Config from "../../../config/auth0";

const PrivateRoute = ({ children }) => {
  let {isLoading, isAuthenticated, user} = useAuth0();

  if (isAuthenticated) {
    window.pendo.initialize({
      visitor: {
        id: user.sub
      },
      account: {
        id: 'security-dashboard',
        region: auth0Config.domain
      },
      excludeAllText: true,
      disableGuides: true
    });
  }

  return !isLoading && (
    (isAuthenticated && user) ? (
      children
    ) : (
      <Navigate replace to="/login" />
    )
  );
};

export default PrivateRoute;
