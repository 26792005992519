import React, {useEffect} from "react";
import {toast} from "react-hot-toast";
import {Button, Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import ConfirmButtonModal from "../../../../shared/components/modals/ConfirmButtonModal";
import RegisteredCheckBoxField from "../../../../shared/components/form/components/RegisteredCheckBoxField";
import {useDogmaBoardsQuery} from "../../../../queries/DogmaBoards";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import MacAddressesCompanyTable from "./MacAddressesCompanyTable";
import UploadCSVModal from "../../Boards/components/UploadCSVModal";
import axios from "axios";
import {apiUrl} from "../../../../config/api";
import {addAuthToAxiosOpts} from "../../../../config/queryopts";
import {useAuth0AccessToken} from "../../../../queries";
import {useQueryClient} from "react-query";
import {useUpdateCompanyConfigMutation} from "../../../../mutations/customer/UpdateCustomerCompanyConfig";
import {mergeBoardMacAddresses} from "../../../../shared/helpers";


const WiFiAttackProtectionCompanyForm = ({companyId, dataConfigWhitelist}) => {
  const {register, handleSubmit, watch, control, setValue} = useForm({
    defaultValues: {disableFeature: !dataConfigWhitelist?.whitelist_enabled}
  });
  const { data: accessToken, isError: tokenIsError, isLoading: tokenIsLoading, isIdle: tokenIsIdle } = useAuth0AccessToken()
  const { data: dataBoards, isIdle: isIdleBoards, isLoading: isLoadingBoards, isError: isErrorBoards } = useDogmaBoardsQuery();

  const queryClient = useQueryClient();
  const mutationCompanyConfig = useUpdateCompanyConfigMutation();

  const onSubmitFile = (data) => {
    const csv = data.csv;
    if (csv && csv.length > 0 && csv[0] && csv[0].fileSize > 0) {
      toast('Updating the configuration', {icon: '⏳', duration: Infinity});

      const formData = new FormData();
      formData.append('formFile', csv[0].file, csv[0].filename);

      axios.put(`${apiUrl}/operations/companies/${companyId}/config/customer/macaddress-whitelist?enable=${data.initialEnableFeature}`, formData,
        addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
      ).then(
        (res) => {
          toast.dismiss()
          if (res.status === 204) {
            toast('Configuration updated successfully', {icon: '✔️'});
            queryClient.refetchQueries(['company-config-customer', companyId]);
            setValue('disableFeature', false)
          }
        }
      ).catch((error) => {
        toast.dismiss();

        if (error.request?.response) {
          toast(`Error: ${error.request.response.replace(']', '').replace('[', '')}.`, {icon: '❌'});
        } else {
          toast(`Error while uploading the CSV file.`, {icon: '❌'});
        }
      })
    }
  }

  useEffect(() => {
    if (!mutationCompanyConfig.isIdle) {
      if (mutationCompanyConfig.isLoading) {
        toast('Updating the configuration', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutationCompanyConfig.isSuccess) {
          queryClient.refetchQueries(['company-config-customer', companyId]);
        } else if (mutationCompanyConfig.isError) {
          toast('Error during the update of the configuration',{icon: '❌'});
        }
        mutationCompanyConfig.reset();
      }
    }
  }, [
    companyId,
    mutationCompanyConfig,
    queryClient,
    mutationCompanyConfig.isIdle, 
    mutationCompanyConfig.isError, 
    mutationCompanyConfig.isLoading, 
    mutationCompanyConfig.isSuccess,

  ])

  if (isLoadingBoards || isIdleBoards || tokenIsLoading || tokenIsIdle) {
    return <LoadingSpinner />
  }

  if (isErrorBoards || tokenIsError) {
    return <Card><CardBody><h3>Unable to load the form</h3></CardBody></Card>
  }

  const onSubmitConfiguration = (data) => {
    mutationCompanyConfig.mutate({
      params: {
        data: {
          mac_whitelist: {
            whitelist_enabled: !data.disableFeature,
            whitelist: dataConfigWhitelist.whitelist
          }
        }
      },
      companyId: companyId
    })
  }

  if (!dataConfigWhitelist) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>WiFi Attack Protection</h4>
          </div>

          <UploadCSVModal control={control} onSubmit={handleSubmit(onSubmitFile)} watch={watch} register={register} />

          <h4>Upload a CSV file to enable this feature</h4>
        </CardBody>
      </Card>
    )
  }

  const boardAllowMacAddresses = mergeBoardMacAddresses(dataBoards, dataConfigWhitelist);

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>WiFi Attack Protection</h4>
        </div>

        <UploadCSVModal control={control} onSubmit={handleSubmit(onSubmitFile)} watch={watch} register={register} />

        <MacAddressesCompanyTable boards={boardAllowMacAddresses} />

        <div className="form__form-group form__form-group-field mt-5">
          <RegisteredCheckBoxField
            name={'disableFeature'}
            value={watch('disableFeature')}
            onChange={(value) => {}}
            defaultChecked={false}
            label={"Disable WiFi Attack Protection feature for the company"}
            register={register}
          />
        </div>

        {
          watch('disableFeature') ?
            <ConfirmButtonModal
              buttonLabel={'Update configuration'}
              onConfirm={handleSubmit(onSubmitConfiguration)}
              titleModal={'Confirm the operation'}
              confirmText={'Are you sure to disable WiFi Attack Protection feature for the company?'}
            /> :
            <Button outline className={'float-right'} onClick={handleSubmit(onSubmitConfiguration)} color={'success'}>Update configuration</Button>
        }

      </CardBody>
    </Card>
  );
}


WiFiAttackProtectionCompanyForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  dataConfigWhitelist: PropTypes.object.isRequired
}


export default WiFiAttackProtectionCompanyForm;