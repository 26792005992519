import React, {useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap';
import TableWithDataHook from "../TableWithDataHook";
import { useImportsPolicyFetcherDataFn } from "../../../../mutations/blocklist/policy/BulkPolicy";
import PropTypes from "prop-types";
import TrackingLink from '../../../components/Link'

const _ = require("lodash");

function statusToColorClass(status) {
  switch (status) {
    case 'Success':
      return 'success'
    case 'Failure':
    case 'Stopped':
      return 'danger'
    case 'Pending':
    case 'Retry':
      return 'warning'
    case 'PartialSuccess':
      return 'info'
    case 'Unknown':
    default:
      return 'secondary'
  }
}

function policyItemToLink(policy) {
  switch (policy.hierarchy_level) {
    case 'Board':
      return  `/boards/${policy.id}`
    case 'Group':
        return  `/configuration/groups/${policy.id}`
    case 'Company':
      return  `/configuration/company`
    default:
      throw new Error('hierarchy level not supported')
  }
}

const TYPE_TO_DISPLAY = {
  BulkPolicyBlockedListsUpdate: 'Bulk Policy Block List Update'
}

export const tableColumns = [
  { Header: 'ID', accessor: 'externalId', tableOnly: true },
  { Header: 'Type', accessor: 'type', Cell: ({ value }) =>  TYPE_TO_DISPLAY[value] },
  {
    Header: 'Success',
    accessor: 'success', Cell: ({value}) => {
      return (
        <div className=''>
          {value.map((successItem, ind) => {
            return (
              <div className='mb-2' key={`${successItem.hierarchy_level}-${ind}`}>
                <TrackingLink to={policyItemToLink(successItem)} className='text-overflow'>
                  <u>{successItem.hierarchy_level} ID: {successItem.id}</u>
                </TrackingLink>
                <br />
                Hierarchy Level: {successItem.hierarchy_level} <br />
                Type: {successItem.type} <br />
              </div>
            )
          })}
        </div>
      )
    },
    drawerOnly: true
  },
  {
    Header: 'Failed',
    accessor: 'failed', Cell: ({value}) => {
      return (
        <div className=''>
          {value.map((failedItem, ind) => {
            return (
              <div className='mb-2' key={`${failedItem.hierarchy_level}-${ind}`}>
                <TrackingLink to={policyItemToLink(failedItem)} className='text-overflow'>
                  <u>{failedItem.hierarchy_level} ID: {failedItem.id}</u>
                </TrackingLink>
                <br />
                Hierarchy Level: {failedItem.hierarchy_level} <br />
                Type: {failedItem.type} <br />
              </div>
            )
          })}
        </div>
      )
    },
    drawerOnly: true
  },
  { Header: 'Status', accessor: 'status', Cell: ({ value }) => {
    const color = statusToColorClass(value)
    return (
      <Badge color={color} className="outline">
        { value }
      </Badge>
    )
  }
   },
];

const tableConfigDefaults = {
  withPagination: true,
  // withHover: true,
  withSearchEngine: true,
}

const EventsTable = ({
  onRowClick,
  fixedBoardId = null,
  tableConfigOverride = {},
  eventClass= null,
  blockedOnly= true,
  ...props
}) => {
  const useImportPolicyQuery = useImportsPolicyFetcherDataFn();
  const getRowDataId = useCallback((row, i) => (_.get(row, 'original.externalId', i)), [])
  const tableConfig = useMemo(() => ({...tableConfigDefaults, ...tableConfigOverride}), [tableConfigOverride])
  const cols = tableColumns.filter(col => !col.drawerOnly)
  
  return (
    <TableWithDataHook
      useDataQuery={useImportPolicyQuery}
      tableColumns={cols}
      tableConfig={tableConfig}
      getRowDataId={getRowDataId}
      onRowClick={onRowClick}
      {...props}
    />
  );
};

EventsTable.propTypes = {
  fixedBoardId: PropTypes.string,
  onRowClick: PropTypes.func,
  tableConfigOverride: PropTypes.shape(),
};

export default EventsTable;
