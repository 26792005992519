import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Modal} from "reactstrap";


const ThemeModal = ({children, themeName, ...props}) => {
  props.className += " " + themeName;

  return (
    <Modal {...props}>
      {children}
    </Modal>
  );
};

ThemeModal.propTypes = {
  children: PropTypes.array.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(ThemeModal);
