import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import DogmaBoardsTable from './components/DogmaBoardsTable';
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {useCompanyNumberDeviceUpdatesQuery} from "../../queries/updates/NumberDevicesToUpdate";
import {useDevicesUpdateListQuery} from "../../queries/updates/DevicesToUpdateList";
import UpdateBoardModal from "../../shared/components/modals/UpdateBoardModal";
import {useUpdateBoardConfigMutation} from "../../mutations/updates/UpdateDevices";
import {useQueryClient} from "react-query";
import {useUserInfo} from "../../shared/helpers/auth";
import AppError from "../DefaultPage/AppError";


const DogmaBoards = () => {
  const nav = useNavigate();
  const mutation = useUpdateBoardConfigMutation();
  const { companyId: userCompanyId, isLoading: isLoadingUser } = useUserInfo();
  const [companyId, setCompanyId] = useState(null);
  const queryClient = useQueryClient();
  const { data: dataNumber, isIdle: isIdleNumber, isLoading: isLoadingNumber, isError: isErrorNumber } = useCompanyNumberDeviceUpdatesQuery(companyId);
  const { data: dataBoardList, isIdle: isIdleBoardList, isLoading: isLoadingBoardList, isError: isErrorBoardList } = useDevicesUpdateListQuery(companyId);


  useEffect(() => {
    if (!isLoadingUser && userCompanyId) {
      setCompanyId(userCompanyId);
    }
  }, [isLoadingUser, userCompanyId]);


  if (isLoadingUser || isIdleNumber || isLoadingNumber || isLoadingBoardList || isIdleBoardList || !companyId) {
    return <Container className="devices-container"><Row><Col><LoadingSpinner /></Col></Row></Container>
  }

  if (isErrorNumber || isErrorBoardList) {
    return <AppError />;
  }

  return (
    <Container className="devices-container">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dogma Boards
          {
            dataNumber && dataNumber.number > 0 &&
            <UpdateBoardModal
              classNameButton={'float-right'}
              mutation={mutation}
              boardSerials={dataBoardList.map((board) => board.serialNumber)}
              updateTitle={`Update ${dataNumber.number} boards`}
              updateMsg={`You are going to update ${dataNumber.number} boards. This process cannot be undone.`}
              onUpdateSuccessFn={() => {
                queryClient.resetQueries(['devices-updated', companyId]);
                queryClient.resetQueries(['company-number-updates', companyId]);
              }}
              ButtonContent={<span>Update {dataNumber.number} boards</span>}
            />
          }
          </h3>

        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <DogmaBoardsTable
                onRowClick={(dataId) => {nav(dataId)}}
                boardsUpdates={dataBoardList}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DogmaBoards;
