import React, {Fragment} from "react";
import ControlledMultiSelectTextWithTitle from "./components/ControlledMultiSelectTextWithTitle";
import {isValidIP} from "../../helpers/validations";
import PropTypes from "prop-types";
import RegisteredInputFieldCopyHidden from "./components/RegisteredInputFieldCopyHidden";


const VpnZeroTrustBoardFieldsForm = ({control, errors, register}) => {
  return (
    <Fragment>
      <RegisteredInputFieldCopyHidden
        name={'pvtKey'}
        register={register}
        title={'Private Key'}
        errors={errors}
        withHidden
      />
      <ControlledMultiSelectTextWithTitle
        name={'boardVpnAddress'} title={'Board VPN IP'} control={control} valueFn={(value) => value}
        validationFn={isValidIP}
        error={errors?.whitelistIps}
      />
    </Fragment>
  )
}

VpnZeroTrustBoardFieldsForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
}

export default VpnZeroTrustBoardFieldsForm;
