import React, { useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import vector_map_data from './vector-map-data.json';
import maps from './world';
import PropTypes from "prop-types";
const vector_map = vector_map_data.vector_map;

const strokeColor = '#4ce1b6';
const mapMainColor = '#4ce1b6';
const lightGrayColor = '#F2F2F2';

const _ = require('lodash')


const heatRule = {
  property: 'fill',
  min: am4core.color('rgb(63, 68, 76)'),
};


const SimpleMapsWithData = ({
  data,
  width = '100%',
  height = '54vh',
  onCountryClick = null,
  isError = false,
  labelFn = (value) =>  value >= 0 ? `: ${value}` : ''
}) => {
  useEffect(() => {
    const map = am4core.create('chartdiv', am4maps.MapChart);
    map.geodata = maps;
    map.centerMapOnZoomOut = false;

    // Set projection
    map.projection = new am4maps.projections.Miller();
    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    heatRule.max = _.maxBy(data, (country) => country.value)?.value === 0 ? am4core.color(lightGrayColor) : am4core.color(mapMainColor);
    heatRule.target = polygonSeries.mapPolygons.template;
    polygonSeries.heatRules.push(heatRule);
    polygonSeries.useGeodata = true;

    const noDataLabel = map.createChild(am4core.Label);
    noDataLabel.text = 'There are no data to show';
    noDataLabel.fontSize = 14;
    noDataLabel.align = 'center';
    noDataLabel.valign = 'middle';
    noDataLabel.fill = am4core.color('#fff');
    noDataLabel.padding(10, 20, 10, 20);
    noDataLabel.background.fill = am4core.color('#232329');
    noDataLabel.background.stroke = am4core.color('#232329');
    noDataLabel.fillOpacity = data.length === 0 && !isError ? 1 : 0;
    noDataLabel.background.strokeOpacity = data.length === 0 && !isError ? 1 : 0;

    const isErrorLabel = map.createChild(am4core.Label);
    isErrorLabel.text = 'Unable to load the data';
    isErrorLabel.fontSize = 14;
    isErrorLabel.align = 'center';
    isErrorLabel.valign = 'middle';
    isErrorLabel.fill = am4core.color('#fff');
    isErrorLabel.padding(10, 20, 10, 20);
    isErrorLabel.background.fill = am4core.color('#232329');
    isErrorLabel.background.stroke = am4core.color('#232329');
    isErrorLabel.fillOpacity = isError ? 1 : 0;
    isErrorLabel.background.strokeOpacity = isError ? 1 : 0;

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    polygonTemplate.fill = am4core.color('rgb(63, 68, 76)');
    polygonTemplate.stroke = am4core.color('rgb(51,54,57)');

    const tooltipFormatter = (target) => {
      const { value } = target.dataItem.dataContext;
      const textValue = labelFn(value);

      return `${vector_map.countries[target.dataItem.dataContext['hc-key']]}${textValue}`;
    };

    polygonSeries.data = [...data];
    polygonTemplate.adapter.add('tooltipText', (text, target) => tooltipFormatter(target));
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color('rgba(135,152,160, 0.6)');
    polygonSeries.tooltip.label.fill = am4core.color('#000');

    if (onCountryClick){
      polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      polygonTemplate.events.on("hit", onCountryClick);
    }

    const hs = polygonTemplate.states.create('hover');
    hs.properties.stroke = am4core.color(strokeColor);
    hs.properties.strokeWidth = 2;

    const zoomControl = new am4maps.ZoomControl();
    zoomControl.align = 'left';
    zoomControl.valign = 'middle';
    map.zoomControl = zoomControl;
  }, [data, isError, labelFn, onCountryClick]);

  return (
    <div id="chartdiv" style={{ width: width, height: height }} />
  );
};

SimpleMapsWithData.propsType = {
  data: PropTypes.array.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  isError: PropTypes.bool,
  onCountryClick: PropTypes.func,
  labelFn: PropTypes.func
}

export default SimpleMapsWithData;
