import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrl} from "../config/api"
import {useCallback} from "react";
import axios from "axios";


export const dogmaDevicesFetcher = (accessToken, boardId) => {
  return axios.get(`${apiUrl}/operations/boards/${boardId}/devices`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useDogmaDevicesQuery = (boardId = null, fetcher = dogmaDevicesFetcher) => {
  return useQueryWithAuthentication(['dogma-devices', boardId], (accessToken) => fetcher(accessToken, boardId))
}



/*****************************************************************************************************
 Shorthands -> returning data-hooks with lists [same as useQuery but data contains the selected list]
 *****************************************************************************************************/


export const useDogmaDevicesQueryDataFn = (boardId) => {
  /*
  Following useCallback has a named callback with PascalCase to avoid the ESLint rule.
  More info on: https://github.com/facebook/react/issues/20531 and https://reactjs.org/docs/hooks-faq.html#what-exactly-do-the-lint-rules-enforce.
  IMPORTANT NOTE: This function returns a custom hook so to enforce hook rules please assign the return value of this
  function to a useXXX variable.
   */
  return useCallback(function MemoizedCustomHook() {
    const {data, isIdle, isLoading, isError, ...other} = useDogmaDevicesQuery(boardId);
    let listData = null;
    if (data && !isIdle && !isLoading && !isError) {
      listData = data.devices;
      if (data.isOutdated) {
        listData = listData.map(x => ({...x, active: false}))
      }
    }

    return {...other, isIdle, isLoading, isError, data: listData}
  }, [boardId]);
}
