import React, {useCallback, useState, Fragment, useEffect} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import {toast} from "react-hot-toast";
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";



const UpdateBoardModal = ({
  mutation,
  boardSerials,
  updateMsg = 'Confirm the deletion?',
  updateTitle = 'Update item',
  classNameButton= '',
  onUpdateSuccessFn = () => {},
  ButtonContent = <span className="lnr lnr-upload" />
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Processing the update', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast('Update completed successfully', {icon: '✔️'});
          onUpdateSuccessFn();
        } else if (mutation.isError) {
          toast('Error during the update',{icon: '❌'});
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess, mutation, onUpdateSuccessFn])

  return (
    <Fragment>
      <Button className={classNameButton} color={'warning'} outline size="sm" onClick={toggleModal}>{ButtonContent}</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal  modal__title">{updateTitle}</h4>
        </div>

        <div className="modal__body">{updateMsg}</div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='warning' onClick={() => {
            toggleModal();
            mutation.mutate(boardSerials);
          }}>Update</Button>
          <Button outline className="rounded" onClick={toggleModal}>Not sure</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};

UpdateBoardModal.propTypes = {
  mutation: PropTypes.shape().isRequired,
  boardSerials: PropTypes.array.isRequired,
  updateTitle: PropTypes.string,
  updateMsg: PropTypes.string,
  classNameButton: PropTypes.string
}

export default UpdateBoardModal;
