import React, {useState} from 'react';
import {Col, Container, Row, Card, CardBody, Button} from 'reactstrap';
import {Link, useNavigate, useLocation} from 'react-router-dom'
import EventsTable from '../../shared/components/table/withdata/EventsTable';
import {EVENT_CLASS_POLICY, EVENT_CLASS_SECURITY, EVENTS_DOMAINS, EVENTS_IPS} from "../../queries/SecurityEvents";
import {WithDetailDrawer} from "../../shared/hocs/WithDetailDrawer";
import {
  areParamsValid,
  fixHistoryURL,
  isParamValid,
  useQueryParams
} from "../../shared/components/router/QueryNavigationHelpers";
import SecurityEventsExport from './components/EventsExport'
import TrackingLink from '../../shared/components/Link'

const EventTableWithDrawer = WithDetailDrawer(EventsTable);

const _ = require('lodash')

const Events = () => {
  const nav = useNavigate();
  const location = useLocation();
  const params = useQueryParams();
  const [ isDetailOpen, setIsDetailOpen ] = useState(false);
  const [isOpen, setOpen] = React.useState(false)

  function toggle(_isOpen) {
    setOpen(!_isOpen)
  }


  const requiredParams = {
    focus: [EVENTS_IPS, EVENTS_DOMAINS],
  }
  const optionalParams = {
    eventClass: [EVENT_CLASS_SECURITY, EVENT_CLASS_POLICY],
  }

  let eventClass;
  const focus = isParamValid(params.get('focus'), requiredParams.focus) ? params.get('focus') : EVENTS_IPS;

  if (params.has('eventClass')) {
    if (isParamValid(params.get('eventClass'), optionalParams.eventClass)) {
      eventClass = params.get('eventClass');
    } else {
      eventClass = EVENT_CLASS_SECURITY;
    }

    if (!areParamsValid(params, requiredParams) || !areParamsValid(params, optionalParams)) {
      fixHistoryURL(params, _.merge(requiredParams, optionalParams), nav, location);
    }

  } else if (!areParamsValid(params, requiredParams)) {
    fixHistoryURL(params, requiredParams, nav, location);
  }

  return (
  <Container className="devices-container">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Security Events</h3>
        <h3 className="page-subhead subhead">Events from the last 30 days</h3>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <TrackingLink to={`?focus=${EVENTS_IPS}${eventClass ? '&eventClass=' + eventClass : ""}`} className={"p-sm-1"}>
          <Button className="rounded" size="sm" outline active={focus === EVENTS_IPS} onClick={() => setIsDetailOpen(false)}>IPs</Button>
        </TrackingLink>
        <TrackingLink to={`?focus=${EVENTS_DOMAINS}${eventClass ? '&eventClass=' + eventClass : ""}`} className={"p-sm-1"}>
          <Button className="rounded" size="sm" outline active={focus === EVENTS_DOMAINS} onClick={() => setIsDetailOpen(false)}>Domains</Button>
        </TrackingLink>
        <div className='event-export-btn'>
          <SecurityEventsExport isOpen={isOpen} toggle={() => toggle(isOpen)} eventType={focus} />
        </div>
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <EventTableWithDrawer
              eventType={focus}
              isOpen={isDetailOpen}
              setIsDetailOpen={setIsDetailOpen}
              tableConfigOverride={{withHover: true}}
              title={"EVENT DETAILS"}
              eventclassName={eventClass}
              daysBack={30}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
  )
}

export default Events;
