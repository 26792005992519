import axios from "axios";
import {useQueryWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const groupBlockListDomainFetcher = (accessToken, groupId) => {
  return axios.get(`${apiUrl}/operations/board-groups/${groupId}/blocked-lists/policy/dns`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useGroupBlockListDomainQuery = (groupId, fetcher = groupBlockListDomainFetcher) => {
  return useQueryWithAuthentication(['group-blocklist-domain', groupId], (accessToken) => fetcher(accessToken, groupId))
}
