import React, {useCallback, useMemo} from 'react';
import { Badge } from 'reactstrap';
import TableWithDataHook from "../TableWithDataHook";
import { useSecurityEventsQueryDataFn, EVENTS_DOMAINS, EVENTS_IPS } from "../../../../queries/SecurityEvents";
import PropTypes from "prop-types";
import moment from "moment";


const _ = require("lodash");


const ipTableColumns = [
  { Header: 'Serial Number', accessor: 'boardId' },
  { Header: 'Log Datetime', accessor: 'logDatetimeOnDogma', Cell: ({value}) => moment.utc(value).local().format("DD/MM/YYYY HH:mm:ss") },
  { Header: 'IP Src', accessor: 'ipSrc' },
  { Header: 'IP Dst', accessor: 'ipDest' },
  // { Header: 'Port Src', accessor: 'portSrc' },
  // { Header: 'Port Dst', accessor: 'portDest' },
  { Header: 'Port Src', accessor: row => row.portSrc.toString() },
  { Header: 'Port Dst', accessor: row => row.portDest.toString() },
  // { Header: 'Prot', accessor: 'protocol' },
  // { Header: 'Flg', accessor: 'tcpFlag' },
  { Header: 'Type', accessor: 'eventClass', Cell: ({value}) => (
      (value && value.toLowerCase() === 'security') ? <Badge color="danger" className="outline">SECURITY</Badge>
        : (value && value.toLowerCase() === 'policy') ? <Badge color="accent" className="outline">POLICY</Badge>
        : <Badge color="grey" className="outline">UNCATEGORIZED</Badge>) },
];

const domainsTableColumns = [
  { Header: 'Serial Number', accessor: 'boardId' },
  { Header: 'Log Datetime', accessor: 'logDatetimeOnDogma', Cell: ({value}) => moment.utc(value).local().format("DD/MM/YYYY HH:mm:ss") },
  { Header: 'Domain', accessor: 'requestedDomain' },
  { Header: 'Resolved IP', accessor: 'ipReturned' },
  // { Header: 'Port Src', accessor: 'portSrc' },
  // { Header: 'Port Dst', accessor: 'portDest' },
  // { Header: 'Prot', accessor: 'protocol' },
  // { Header: 'Flg', accessor: 'tcpFlag' },
  { Header: 'Type', accessor: 'eventClass', Cell: ({value}) => (
      (value && value.toLowerCase() === 'security') ? <Badge color="danger" className="outline">SECURITY</Badge>
        : (value && value.toLowerCase() === 'policy') ? <Badge color="accent" className="outline">POLICY</Badge>
        : <Badge color="grey" className="outline">UNCATEGORIZED</Badge>) },
];

const tableConfigDefaults = {
  withPagination: true,
  // withHover: true,
  withSearchEngine: true,
}

const EventsTable = ({
  onRowClick,
  eventType = EVENTS_IPS,
  fixedBoardId = null,
  tableConfigOverride = {},
  eventClass= null,
  blockedOnly= true,
  daysBack = 1,
  ...props
}) => {
  const useIpDataQuery = useSecurityEventsQueryDataFn(EVENTS_IPS, fixedBoardId, blockedOnly, eventClass, daysBack);
  const useDomainsDataQuery = useSecurityEventsQueryDataFn(EVENTS_DOMAINS, fixedBoardId, blockedOnly, eventClass, daysBack);
  const getRowDataId = useCallback((row, i) => (_.get(row, 'original.eventId', i)), [])
  const tableConfig = useMemo(() => ({...tableConfigDefaults, ...tableConfigOverride}), [tableConfigOverride])
  if (eventType === EVENTS_IPS) {
    return (
      <TableWithDataHook
        useDataQuery={useIpDataQuery}
        tableColumns={ipTableColumns}
        tableConfig={tableConfig}
        getRowDataId={getRowDataId}
        onRowClick={onRowClick}
        {...props}
      />
    );
  } else if (eventType === EVENTS_DOMAINS) {
    return (
      <TableWithDataHook
        useDataQuery={useDomainsDataQuery}
        tableColumns={domainsTableColumns}
        tableConfig={tableConfig}
        getRowDataId={getRowDataId}
        onRowClick={onRowClick}
        {...props}
      />
    );
  } else {
    throw new Error('eventType must be one of [EVENTS_IPS, EVENTS_DOMAINS]');
  }
};

EventsTable.propTypes = {
  fixedBoardId: PropTypes.string,
  onRowClick: PropTypes.func,
  eventType: PropTypes.oneOf([EVENTS_IPS, EVENTS_DOMAINS]),
  tableConfigOverride: PropTypes.shape(),
};

export default EventsTable;
