import React, {Fragment} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import EventsAggregatesLineChart from "../../../shared/components/charts/withdata/EventsAggregatesLineChart";
import PropTypes from "prop-types";
import RiskSmallGaugeChart from "../../../shared/components/charts/withdata/RiskSmallGaugeChart";
import {SECT_EVENTS} from "../index";
import {boardsPath} from "../../../config/paths";
import {TIME_UNIT_DAYS, WithSecurityStats} from "../../../shared/hocs/WithSecurityStats";
import SmallBarChartCard from "../../../shared/components/charts/SmallBarChartCard";


const SecurityEventsSmallBarChartCard = WithSecurityStats(SmallBarChartCard)


const BoardOverviewSection = ({boardId}) => {
  return (
    <Fragment>
      <Row>
        <Col md={12} xl={4} lg={4} xs={12}>
          <RiskSmallGaugeChart boardId={boardId} to={`${boardsPath}/${boardId}?overview=${SECT_EVENTS}`}/>
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <SecurityEventsSmallBarChartCard
            title={"Daily Security Events"}
            dataArgs={{
              timeUnit: TIME_UNIT_DAYS,
              limit: 7,
              blockedOnly: true,
              eventClass: 'security',
              boardId: boardId
            }}
            barDataKey={'security'}
            modal={true}
          />
        </Col>

        <Col md={12} xl={4} lg={4} xs={12}>
          <SecurityEventsSmallBarChartCard
            title={"Daily Policy Events"}
            dataArgs={{
              timeUnit: TIME_UNIT_DAYS,
              limit: 7,
              blockedOnly: true,
              eventClass: 'policy',
              boardId: boardId
            }}
            barDataKey={'policy'}
            modal={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <EventsAggregatesLineChart boardId={boardId}/>
            </CardBody>
          </Card>
        </Col>


      </Row>
    </Fragment>
  );
}

BoardOverviewSection.propTypes = {
  boardId: PropTypes.string.isRequired,
}

export default BoardOverviewSection;
