import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import getTooltipStyles from '../../../../shared/helpers';
import {useSecurityStatsQueryWithRefinedData, TIME_UNIT_DAYS} from "../../../../queries/SecurityStats";
import LoadingSpinner from "../../LoadingSpinner";
import {RTLProps} from "../../../prop-types/ReducerProps";

const _ = require('lodash')
const ZERO_PLACEHOLDER = 0.1;

function removeYear(date, sep = '/') {
  // 2021/04/22 -> 04/22 - Used for axis labels
  const dateComponents = date.split(sep);
  // return (dateComponents.length !== 3) ? date : dateComponents.slice(1,3).reverse().join(sep);
  if (dateComponents.length !== 3) {
    return date;
  } else {
    const [, month, day] = dateComponents;
    return [day, month].join(sep);
  }
}


function combineData(dailyPolicyEvents, dailySecurityEvents, dailyTotalEvents) {
  return (
    _.merge(
      [],
      dailyTotalEvents.map(value => ({day: value.day, totCnt: value.cnt > 0 ? value.cnt : ZERO_PLACEHOLDER})),
      dailySecurityEvents.map(value => ({day: value.day, secCnt: value.cnt > 0 ? value.cnt : ZERO_PLACEHOLDER})),
      dailyPolicyEvents.map(value => ({day: value.day, polCnt: value.cnt > 0 ? value.cnt : ZERO_PLACEHOLDER})),
    )
  );
}

const LineChart = ({rtl, themeName, combinedData}) => {
  const domain = _.maxBy(combinedData, val => val.totCnt)?.totCnt === ZERO_PLACEHOLDER ? [1, 1] : ['auto', 'auto'];

  function _getTooltipStyles() {
    const styles = getTooltipStyles('light-theme', 'defaultItems');
    return {
      contentStyle: { backgroundColor: styles.backgroundColor },
      itemStyle: null,
    };
  }

  return (
    <div dir="ltr">
      <ResponsiveContainer height={250} className="dashboard__area">
        <AreaChart data={combinedData} margin={{ top: 20, left: -15, bottom: 20 }}>
          <XAxis dataKey={x => removeYear(x.day)} tickLine={false} reversed={true} />
          {/*<YAxis tickLine={false} orientation={'right'} />*/}
          <YAxis domain={domain} scale={'log'} tick={false} orientation={'right'} />
          <Tooltip
            {..._getTooltipStyles(themeName)}
            formatter={(value, name, props) => value === ZERO_PLACEHOLDER ? 0 : value}
          />
          <Legend />
          <CartesianGrid />
          <Area
            name="Total Traffic"
            type="monotone"
            dataKey="totCnt"
            fill="#4ce1b6"
            stroke="#4ce1b6"
            fillOpacity={0.2}
          />
          <Area name="Blocked Events [SECURITY]" type="monotone" dataKey="secCnt" fill="#d50000" stroke="#d50000" fillOpacity={0.2} />
          <Area name="Blocked Events [POLICY]" type="monotone" dataKey="polCnt" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}


const EventsAggregatesLineChartAll = ({rtl, themeName, boardId = null}) => {
  const policyStatsQuery = useSecurityStatsQueryWithRefinedData(TIME_UNIT_DAYS, 7, true, boardId, 'policy');
  const securityStatsQuery = useSecurityStatsQueryWithRefinedData(TIME_UNIT_DAYS, 7, true, boardId, 'security');
  const totalStatsQuery = useSecurityStatsQueryWithRefinedData(TIME_UNIT_DAYS, 7, false, boardId);
  const combinedData = useMemo(() => (
      (policyStatsQuery.refinedData && !policyStatsQuery.isError && securityStatsQuery.refinedData && !securityStatsQuery.isError && totalStatsQuery.refinedData && !totalStatsQuery.isError)
        ? combineData(policyStatsQuery.refinedData.combinedStats, securityStatsQuery.refinedData.combinedStats, totalStatsQuery.refinedData.combinedStats)
        : []
    ),
    [policyStatsQuery.refinedData, policyStatsQuery.isError, securityStatsQuery.refinedData, securityStatsQuery.isError, totalStatsQuery.refinedData, totalStatsQuery.isError]
  );

  return (
    (policyStatsQuery.isIdle || policyStatsQuery.isLoading || securityStatsQuery.isIdle || securityStatsQuery.isLoading || totalStatsQuery.isIdle || totalStatsQuery.isLoading) ? (
      <LoadingSpinner />
    ) : (
      <LineChart rtl={rtl} themeName={themeName} combinedData={combinedData} />
    )
  );
}


// const EventsAggregatesLineChartBoard = ({rtl, themeName, boardId}) => {
//   const { isIdle, isLoading, isError, dailySecurityEvents, dailyTotalEvents } = useSecurityStatsQuery(boardId);
//   const combinedData = useMemo(() => (
//     (!isIdle && !isLoading && !isError)
//       ? combineData(dailySecurityEvents, dailyTotalEvents)
//       : []
//     ),
//     [isIdle, isLoading, isError, dailySecurityEvents, dailyTotalEvents]
//   );
//   return <LineChart rtl={rtl} themeName={themeName} combinedData={combinedData} />;
// }


const EventsAggregatesLineChart = ({rtl, themeName, boardId}) => (
  // (boardId) ? (
  //   <EventsAggregatesLineChartBoard rtl={rtl} themeName={themeName} boardId={boardId}/>
  // ) : (
    <EventsAggregatesLineChartAll rtl={rtl} themeName={themeName} boardId={boardId}/>
  // )
);


EventsAggregatesLineChart.propTypes = {
  rtl: RTLProps.isRequired,
  themeName: PropTypes.string.isRequired,
  boardId: PropTypes.string,
};

export default connect(state => ({ themeName: state.theme.className, rtl: state.rtl }))(EventsAggregatesLineChart);
