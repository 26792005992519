import React, {Fragment, useState, useMemo, useCallback} from 'react';
import {useDogmaBoardsQuery} from "../../../queries/DogmaBoards";
import {
  BOARD_STATUS_RUNNING,
  enrichBoardsDataWithRunningState, getBoardRunningState,
  getBoardStatusLabel
} from "../../helpers/querydata/boardsdata";
import Collapse from "../Collapse";
import ThemeModal from "../ThemeModal";
import moment from "moment";


const getBoards = (showActive, rawData, isLoading, isIdle) => {
  if (rawData && !isLoading && !isIdle) {
    const enrichedBoardData = enrichBoardsDataWithRunningState(rawData);
    return  enrichedBoardData.filter(
      board => showActive ? board.runningState === BOARD_STATUS_RUNNING : board.runningState !== BOARD_STATUS_RUNNING
    );
  }

  return [];
}

const DetailBoardsModal = ({data}) => {
  const [showModal, setShowModal] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);
  const { data: rawData, isIdle, isLoading} = useDogmaBoardsQuery();

  const boards = useMemo(
    () => getBoards(showActive, rawData, isLoading, isIdle),
    [showActive, rawData, isLoading, isIdle]
  );

  return (
    <Fragment>
      <p className="chart__small_percentage__total-label chart__small_percentage__total-label-modal">
        <span onClick={() => {toggleModal(); setShowActive(true); }}>{data?.value}</span>
        <span onClick={() => {toggleModal(); setShowActive(false); }} className="chart__small_percentage__fractional-label">{(data) ? `/${data?.total}` : ''}</span>
      </p>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header" key={"modal__header"}>
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal modal__title">{
            showActive ? "Active boards" : "Not running boards"
          }</h4>
        </div>
        <div className="modal__body" key={"modal__body"}>
          {boards.length > 0 ?
            boards.map((board) =>
              <Collapse title={board.serialNumber} className={"with-shadow"} key={board.serialNumber}>
                <p>Status is <span className={'bold-text'}>{
                  getBoardStatusLabel(getBoardRunningState(board))
                }</span></p>
                <p>Last access is <span className={'bold-text'}>{
                  moment.utc(board.lastAccessDateTimeUTC).local().format("DD/MM/YYYY HH:mm:ss")
                }</span></p>
              </Collapse>
            ) : showActive ? <p className={'bold-text'} >There is no any running devices</p> : <p className={'bold-text'}>All the devices are running</p>
          }
        </div>
      </ThemeModal>
    </Fragment>
  );
}

export default DetailBoardsModal;