import React from 'react';
import {Col, Container, Row, Card, CardBody} from 'reactstrap';
import {useNavigate} from "react-router-dom";
import EmittersTable from "./components/EmittersTable";
import AddEmitterModal from "./components/AddEmitterModal";
import {useUserInfo} from "../../../shared/helpers/auth";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";


const Emitters = () => {
  const nav = useNavigate();
  const { companyId, isLoading } = useUserInfo();

  if (isLoading) {
    return <LoadingSpinner />
  }


  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Emitters</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AddEmitterModal companyId={companyId} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <EmittersTable
                onRowClick={(dataId) => nav(dataId)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Emitters;
