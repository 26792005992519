import React, {Fragment, useCallback, useState} from "react";
import {Button, ButtonToolbar, Col, Row} from "reactstrap";
import ThemeModal from "../../../shared/components/ThemeModal";
import ControlledFilepond from "../../../shared/components/form/ControlledFilepond";
import PropTypes from "prop-types";


const BulkPolicyUploadModal = ({control, onSubmit}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);

  const _onSubmit = () => {
    toggleModal()
    onSubmit()
  }
  return (
    <Fragment>
      <Button color={'secondary'} className={'float-right'} outline size="sm" onClick={toggleModal}>Upload Import Policy</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal modal__title">Add new policies</h4>
        </div>

        <div className="modal__body">
          <Row>
            <Col>
              Upload a CSV to add new policies. <br /><br />
              <i>Only well formatted files will be accepted.</i>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <h5 className={'bold-text mb-2'}><u>Valid format</u></h5>
              <span className={'font-italic'}>
                hierarchylevel;identifier;type;indicatorlist <br />
                board;serialNumber;DNS;example.it|example.com <br />
                group;groupId;IP;0.0.0.0 <br />
                company;companyId;DNS;example.it|example.com <br />
              </span>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <ControlledFilepond name={'csv'} control={control} />
            </Col>
          </Row>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='success' onClick={_onSubmit}>Add</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};


BulkPolicyUploadModal.propTypes = {
  control: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}


export default BulkPolicyUploadModal;
