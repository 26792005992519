import React, {useEffect, useState, Fragment} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {getChangedFields, getErrorMessageFromResponse, getListBlockConfiguration} from "../../../../shared/helpers";
import {PropTypes} from "prop-types";
import BoardConfigurationSubmit from "../../../../shared/components/form/BoardConfigurationSubmit";
import {useGroupBlockListDomainQuery} from "../../../../queries/blocklist/group/GroupBlockListPolicyDomain";
import {useGroupBlockListIPQuery} from "../../../../queries/blocklist/group/GroupBlockListPolicyIP";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {useUpdateGroupBlockListPolicyDomainMutation} from "../../../../mutations/blocklist/group/UpdateGroupBlockListPolicyDomain";
import {useUpdateGroupBlockListPolicyIPMutation} from "../../../../mutations/blocklist/group/UpdateGroupBlockListPolicyIP";
import {useQueryClient} from "react-query";
import {  areAllMutation,  areAnyMutation, getFirstMutationError,  resetAllErrorMutation} from "../../../../shared/helpers/mutation";
import {toast} from "react-hot-toast";
import {SECT_GROUP_BLOCK_LIST_CONFIG} from "../index";
// import _ from "lodash";
import {useGroupConfigQuery} from "../../../../queries/customer/CustomerGroupConfig";
import {useUpdateGroupConfigMutation} from "../../../../mutations/customer/UpdateCustomerGroupConfig";


const GroupConfigurationsForms = ({groupId, overview}) => {
  const { data: dataConfig, isIdle: isIdleConfig, isLoading: isLoadingConfig, isError: isErrorConfig } = useGroupConfigQuery(groupId);
  const { data: dataBlockListDomain, isIdle: isIdleBlockListDomain, isLoading: isLoadingBlockListDomain, isError: isErrorBlockListDomain } = useGroupBlockListDomainQuery(groupId);
  const { data: dataBlockListIP, isIdle: isIdleBlockListIP, isLoading: isLoadingBlockListIP, isError: isErrorBlockListIP } = useGroupBlockListIPQuery(groupId);
  const [areToastLoadingMutex, setAreToastLoadingMutex] = useState(false);
  
  const mutationGroupConfig = useUpdateGroupConfigMutation();
  const mutationGroupBlockListDomain = useUpdateGroupBlockListPolicyDomainMutation();
  const mutationGroupBlockListIP = useUpdateGroupBlockListPolicyIPMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!areAllMutation('isIdle', mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig)) {
      if (areAnyMutation('isLoading', mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig)) {
        if (!areToastLoadingMutex) {
          setAreToastLoadingMutex(true);
          toast('Updating the configuration', {icon: '⏳', duration: Infinity});
        }
      } else {
        toast.dismiss();
        setAreToastLoadingMutex(false);
        if (areAnyMutation('isError', mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig)) {
          toast(
            `Error: ${getErrorMessageFromResponse(getFirstMutationError(mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig))}`,
            {icon: '❌'}
          );
          resetAllErrorMutation(mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig);
        } else if (areAnyMutation('isSuccess', mutationGroupBlockListIP, mutationGroupBlockListDomain, mutationGroupConfig)) {
          toast('Configuration updated successfully', {icon: '✔️'});
          queryClient.refetchQueries(['group-config-customer', groupId]);
          queryClient.refetchQueries(['group-blocklist-ip', groupId]);
          queryClient.refetchQueries(['group-blocklist-domain', groupId]);
        }
      }
    }
  }, [
    areToastLoadingMutex,
    groupId,
    mutationGroupBlockListDomain.isIdle,
    mutationGroupBlockListDomain.isLoading,
    mutationGroupBlockListDomain.isError,
    mutationGroupBlockListIP.isIdle,
    mutationGroupBlockListIP.isLoading,
    mutationGroupBlockListIP.isError,
    mutationGroupConfig.isIdle,
    mutationGroupConfig.isLoading,
    mutationGroupConfig.isError,
    queryClient
  ]);


  const onSubmitBlockList = (data) => {
    const changedFields = getChangedFields(data, getListBlockConfiguration(dataBlockListDomain, dataBlockListIP));

    if (!dataBlockListDomain && data.policyDomainsBL?.length > 0) {
      mutationGroupBlockListDomain.mutate({...{groupId: groupId}, ...{params: {blockedIndicators: data.policyDomainsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyDomainsBL) {
      mutationGroupBlockListDomain.mutate({...{groupId: groupId}, ...{params: {blockedIndicators: changedFields.policyDomainsBL}}})
    }

    if (!dataBlockListIP && data.policyIpsBL?.length > 0) {
      mutationGroupBlockListIP.mutate({...{groupId: groupId}, ...{params: {blockedIndicators: data.policyIpsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyIpsBL) {
      mutationGroupBlockListIP.mutate({...{groupId: groupId}, ...{params: {blockedIndicators: changedFields.policyIpsBL}}})
    }
  }

  // const onSubmitVpnZeroTrust = (data) => {
  //   // FEATURE NOT AVAILABLE
  //   return;

  //   const changedFields = getChangedFields(data, dataConfig);

  //   if (!_.isEmpty(changedFields)) {
  //     // mutationGroupConfig.mutate({...{groupId: groupId}, ...changedFields})
  //   }
  // }

  if (isIdleBlockListDomain || isIdleBlockListIP || isIdleConfig || isLoadingBlockListDomain || isLoadingBlockListIP || isLoadingConfig) {
    return <LoadingSpinner />
  }

  if (isErrorBlockListDomain || isErrorConfig || isErrorBlockListIP) {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h3>Unable to load the form</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    (overview === SECT_GROUP_BLOCK_LIST_CONFIG) ?
      <BoardConfigurationSubmit
        config={{...dataConfig.data, ...getListBlockConfiguration(dataBlockListDomain, dataBlockListIP)}}
        confId={groupId}
        onSubmit={onSubmitBlockList}
        listsOnly
      />
      : <Fragment />
  )
}

GroupConfigurationsForms.propTypes = {
  groupId: PropTypes.string.isRequired,
  overview: PropTypes.number.isRequired,
}

export default GroupConfigurationsForms;
