import {useLocation} from "react-router-dom";

const _ = require('lodash')


// A custom hook that builds on useLocation to parse the query string for you.
export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const isParamValid = (param, acceptedParams) => param !== null && acceptedParams.includes(param);

export const areParamsValid = (params, requiredParams) => {
  for (const [key, acceptedParams] of Object.entries(requiredParams)) {
    if (!isParamValid(params.get(key), acceptedParams)) {
      return false;
    }
  }

  return true;
}

export const fixHistoryURL = (params, requiredParams, nav, location) => {
  const path = location.pathname;
  let query = "?";

  _.forEach(requiredParams, (values, key) => {
    const paramValue = params.get(key);
    if (isParamValid(paramValue, values)) {
      query += `${key}=${paramValue}&`;
    } else {
      query += `${key}=${values[0]}&`;
      params.set(key, values[0]);
    }
  })

  query = query.slice(0, -1);
  nav(path + query);
}
