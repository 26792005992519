import axios from "axios";
import {useQueryWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const boardBlockListIPFetcher = (accessToken, boardId) => {
  return axios.get(`${apiUrl}/operations/boards/${boardId}/blocked-lists/policy/ip`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useBoardBlockListIPQuery = (boardId, fetcher = boardBlockListIPFetcher) => {
  return useQueryWithAuthentication(['board-blocklist-ip', boardId], (accessToken) => fetcher(accessToken, boardId), {
    staleTile: 0, cacheTime: 0
  })
}
