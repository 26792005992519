import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col} from "reactstrap";
import BoardConfigurationSubmit from "../../../../shared/components/form/BoardConfigurationSubmit";
import VpnZeroTrustBoardSubmit from "./VpnZeroTrustBoardSubmit";
import {SECT_BLOCK_LIST_CONFIG, SECT_VPN_ZERO_CONFIG} from "../index";
import {useBoardConfigQuery} from "../../../../queries/customer/CustomerBoardConfig";
import {useBoardBlockListDomainQuery} from "../../../../queries/blocklist/board/BoardBlockListPolicyDomain";
import {useBoardBlockListIPQuery} from "../../../../queries/blocklist/board/BoardBlockListPolicyIP";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {getChangedFields, getErrorMessageFromResponse, getListBlockConfiguration} from "../../../../shared/helpers";
// import _ from "lodash";
import {useUpdateBoardConfigMutation} from "../../../../mutations/customer/UpdateCustomerBoardConfig";
import {useUpdateBoardBlockListPolicyDomainMutation} from "../../../../mutations/blocklist/board/UpdateBoardBlockListPolicyDomain";
import {useUpdateBoardBlockListPolicyIPMutation} from "../../../../mutations/blocklist/board/UpdateBoardBlockListPolicyIP";
import {toast} from "react-hot-toast";
import {areAllMutation, areAnyMutation, getDepsMutation, getFirstMutationError, resetAllErrorMutation, resetAllMutation} from "../../../../shared/helpers/mutation";
import {useCompanyBlockListDomainQuery} from "../../../../queries/blocklist/company/CompanyBlockListPolicyDomain";
import {useCompanyBlockListIPQuery} from "../../../../queries/blocklist/company/CompanyBlockListPolicyIP";
import {useResetBoardConfigMutation} from "../../../../mutations/customer/ResetCustomerBoardConfig";
import {useQueryClient} from "react-query";
import WiFiAttackProtectionBoardsForm from "./WiFiAttackProtectionBoardsForm";


const BoardConfigurationForms = ({board, overview, companyId }) => {
  const boardId = board.serialNumber;
  const [areToastLoadingMutex, setAreToastLoadingMutex] = useState(false);
  const { data: dataConfig, isIdle: isIdleConfig, isLoading: isLoadingConfig, isError: isErrorConfig } = useBoardConfigQuery(boardId);
  const { data: dataBlockListDomain, isIdle: isIdleBlockListDomain, isLoading: isLoadingBlockListDomain, isError: isErrorBlockListDomain } = useBoardBlockListDomainQuery(boardId);
  const { data: dataBlockListIP, isIdle: isIdleBlockListIP, isLoading: isLoadingBlockListIP, isError: isErrorBlockListIP } = useBoardBlockListIPQuery(boardId);
  const { data: dataBlockListDomainCompany, isIdle: isIdleBlockListDomainCompany, isLoading: isLoadingBlockListDomainCompany, isError: isErrorBlockListDomainCompany } = useCompanyBlockListDomainQuery(companyId);
  const { data: dataBlockListIPCompany, isIdle: isIdleBlockListIPCompany, isLoading: isLoadingBlockListIPCompany, isError: isErrorBlockListIPCompany } = useCompanyBlockListIPQuery(companyId);

  const mutationBoardConfig = useUpdateBoardConfigMutation();
  const mutationBoardBlockListDomain = useUpdateBoardBlockListPolicyDomainMutation();
  const mutationBoardBlockListIP = useUpdateBoardBlockListPolicyIPMutation();
  const mutationResetBoard = useResetBoardConfigMutation();
  const queryClient = useQueryClient();

  const mutationDeps = getDepsMutation(mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard);

  useEffect(() => {
    if (!areAllMutation('isIdle', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
      if (areAnyMutation('isLoading', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
        if (!areToastLoadingMutex) {
          setAreToastLoadingMutex(true);
          toast('Updating the configuration', {icon: '⏳', duration: Infinity});
        }
      } else {
        toast.dismiss();
        setAreToastLoadingMutex(false);
        if (areAnyMutation('isError', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
          toast(
            `Not all the configuration were applied correctly. Error: ${getErrorMessageFromResponse(getFirstMutationError(mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard))}`,
            {icon: '❌'}
          );
        } else if (areAnyMutation('isSuccess', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
          if (mutationResetBoard.isSuccess) {
            queryClient.refetchQueries(['dogma-boards']);
            toast(`The board ${boardId} has been moved to Company Configuration group`, {icon: '✔️'});
          } else {
            if (!board.hasBoardCustomerConfigurationDefined) {
              queryClient.refetchQueries(['dogma-boards']);
              toast(`The board ${boardId} has been moved to Custom Configuration group`, {icon: '✔️'});
            } else {
              toast('Configuration updated successfully', {icon: '✔️'});
            }
          }
        }
        resetAllMutation(mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard);
      }
    }
  }, [
    mutationDeps,
    areToastLoadingMutex,
    board.hasBoardCustomerConfigurationDefined,
    boardId,
    mutationBoardBlockListDomain.isIdle,
    mutationBoardBlockListDomain.isLoading,
    mutationBoardBlockListDomain.isError,
    mutationBoardBlockListIP.isIdle,
    mutationBoardBlockListIP.isLoading,
    mutationBoardBlockListIP.isError,
    mutationBoardConfig.isIdle,
    mutationBoardConfig.isLoading,
    mutationBoardConfig.isError,
    mutationResetBoard.isIdle,
    mutationResetBoard.isError,
    mutationResetBoard.isLoading,
    queryClient
  ]);

  const onSubmitBlockList = (data) => {
    const changedFields = getChangedFields(
      data,
      getListBlockConfiguration(dataBlockListDomain, dataBlockListIP),
      getListBlockConfiguration(dataBlockListDomainCompany, dataBlockListIPCompany)
    );

    if (!dataBlockListDomain && data.policyDomainsBL?.length > 0) {
      mutationBoardBlockListDomain.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: data.policyDomainsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyDomainsBL) {
      mutationBoardBlockListDomain.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: changedFields.policyDomainsBL}}})
    }

    if (!dataBlockListIP && data.policyIpsBL?.length > 0) {
      mutationBoardBlockListIP.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: data.policyIpsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyIpsBL) {
      mutationBoardBlockListIP.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: changedFields.policyIpsBL}}})
    }

    mutationBoardConfig.mutate({...{boardId: boardId}, ...{params: {data: {navigation: {enabled: !data.dropInternet}}}}});
  }

  const onSubmitVpnZeroTrust = (data) => {
    // FEATURE NOT AVAILABLE
    return;

    // const changedFields = getChangedFields(data, getVpnZeroTrustBoardConfiguration(dataConfig));

    // if (!_.isEmpty(changedFields)) {
    //   mutationBoardConfig.mutate({...{boardUid: boardId}, ...changedFields})
    // }
  }

  if (isIdleBlockListDomain || isIdleBlockListIP || isIdleConfig || isLoadingBlockListDomain || isLoadingBlockListIP || isLoadingConfig
  || isIdleBlockListDomainCompany || isLoadingBlockListDomainCompany || isIdleBlockListIPCompany || isLoadingBlockListIPCompany) {
    return (
      <Col md={6}>
        <Card style={{height: 'auto'}}>
          <CardBody>
            <LoadingSpinner />
          </CardBody>
        </Card>
      </Col>
    )
  }

  if (isErrorBlockListDomain || isErrorConfig || isErrorBlockListIP || isErrorBlockListDomainCompany || isErrorBlockListIPCompany) {
    return (
      <Col md={6}>
        <Card style={{height: 'auto'}}>
          <CardBody>
            <h3>Unable to load the form</h3>
          </CardBody>
        </Card>
      </Col>
    )
  }

  return (
    <Col md={6}>
      {
        (overview === SECT_BLOCK_LIST_CONFIG) ?
          <BoardConfigurationSubmit
            config={{...dataConfig.data, ...getListBlockConfiguration(dataBlockListDomain, dataBlockListIP)}}
            onSubmit={onSubmitBlockList}
            confId={boardId}
            title={`Board ${board?.serialNumber}`}
            companyLists={getListBlockConfiguration(dataBlockListDomainCompany, dataBlockListIPCompany)}
            onResetConfiguration={() => mutationResetBoard.mutate(boardId)}
          /> :
          (overview === SECT_VPN_ZERO_CONFIG) ?
            <VpnZeroTrustBoardSubmit
              config={dataConfig}
              onSubmit={onSubmitVpnZeroTrust}
              title={`Board ${board?.serialNumber}`}
            /> :
              <WiFiAttackProtectionBoardsForm
                title={`Board ${board?.serialNumber}`}
                boardId={board.serialNumber}
              />
      }
    </Col>
  )
}

BoardConfigurationForms.propTypes = {
  board: PropTypes.shape().isRequired,
  overview: PropTypes.number.isRequired,
  companyId: PropTypes.string.isRequired,
}


export default BoardConfigurationForms;
