import React from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../scss/app.scss';
import Router from './Router';
import store from '../../redux/store';
import ScrollToTop from './ScrollToTop';
import auth0Config from "../../config/auth0";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000,
      retry: (failureCount, error) => {
        console.error(error.message);
        return failureCount < 2 && (!error?.response?.status || error.response.status >= 500);
      },
      retryDelay: attemptIndex => Math.min(1000 * 5 * (attemptIndex + 1), 10000),
    },
  },
})

const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    overrides:{
      MuiDrawer: {
        paper: {
          width: 600
        }
      }
    },
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);

const App = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);

  const faviconTag = document.getElementById("faviconTag");
  const isDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");

  React.useEffect(() => {
    const changeFavicon = () => {
      if (isDark.matches) faviconTag.href = process.env.PUBLIC_URL + "/favicon-light.svg";
      else faviconTag.href = process.env.PUBLIC_URL + "/favicon-dark.svg";
    };

    window.addEventListener('load', () => {
      setLoading(false)
      setLoaded(true)
      setTimeout(() => {
        setLoading(false)
        setLoaded(true) 
      }, 500);
    });
    changeFavicon();
    setInterval(changeFavicon, 10000);
  }, [faviconTag.href, isDark.matches]);

  return (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        redirectUri={`${window.location.origin}`}
        audience={auth0Config.audience}
        scope={auth0Config.scopes}
        // returnTo={`${window.location.origin}/`}
        // onRedirectCallback={onRedirectCallbackAuth0}
      >
        <BrowserRouter>
          <ScrollToTop>
            <React.Fragment>
              {!loaded
                && (
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
                )
              }
              <div>
                <ConnectedThemeComponent>
                  <Router routes={props.routes} />
                </ConnectedThemeComponent>
              </div>
            </React.Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Auth0Provider>
    </QueryClientProvider>
  </Provider>
  )
}

export default App;
