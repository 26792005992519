import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";



const DeleteByIdBinModal = ({
  mutation,
  itemId,
  deleteMsg = 'Confirm the deletion?',
  deleteTitle = 'Delete item',
  classNameButton = '',
  onDeleteSuccessFn = () => { }
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => { setShowModal(!showModal) }, [setShowModal, showModal]);

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Processing the delete operation', { icon: '⏳', duration: Infinity });
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast('Delete operation completed successfully', { icon: '✔️' });
          onDeleteSuccessFn();
        } else if (mutation.isError) {
          toast('Error during the delete operation', { icon: '❌' });
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  return (
    <Fragment>
      <Button className={`rounded ${classNameButton}`} outline size="sm" onClick={toggleModal}><span className="lnr lnr-trash" /></Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal  modal__title">{deleteTitle}</h4>
        </div>

        <div className="modal__body">{deleteMsg}</div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='danger' onClick={() => {
            toggleModal();
            mutation.mutate(itemId);
          }}>Delete</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};

DeleteByIdBinModal.propTypes = {
  mutation: PropTypes.shape().isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  deleteTitle: PropTypes.string,
  deleteMsg: PropTypes.string,
  classNameButton: PropTypes.string
}

export default DeleteByIdBinModal;
