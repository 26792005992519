const _ = require('lodash')


const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

function getTooltipStyles(themeName) {
  switch (themeName) {
    case 'theme-dark': {
      return darkTheme;
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default: return darkTheme;
  }
}


export const getLabelValueFromStringArray = (arr, companyConfig) => {
  let options = [];
  if (companyConfig) {
    options = options.concat(companyConfig.map((str) => ({value: str, label: str, isFixed: true})));
  }

  if (arr) {
    options = options.concat(arr.filter(val => !companyConfig.includes(val)).map((str) => ({value: str, label: str})));
  }
  
  return _.flattenDeep(options);
}


export const getDefaultFormConfiguration = (config, companyConfig = null) => {
  return ({
    policyDomainsBL: config || companyConfig ? getLabelValueFromStringArray(config.policyDomainsBL, companyConfig?.policyDomainsBL ? companyConfig.policyDomainsBL : []) : [],
    policyIpsBL: config || companyConfig ? getLabelValueFromStringArray(config.policyIpsBL, companyConfig?.policyIpsBL ? companyConfig.policyIpsBL : []) : [],
    securityDomainsBL: config || companyConfig ? getLabelValueFromStringArray(config.securityDomainsBL, companyConfig?.securityDomainsBL ? companyConfig.securityDomainsBL : []) : [],
    securityIpsBL: config || companyConfig ? getLabelValueFromStringArray(config.securityIpsBL, companyConfig?.securityIpsBL ? companyConfig.securityIpsBL : []) : [],
    whitelistDomains: config || companyConfig ? getLabelValueFromStringArray(config.whitelistDomains, companyConfig?.whitelistDomains ? companyConfig.whitelistDomains : []) : [],
    whitelistIps: config || companyConfig ? getLabelValueFromStringArray(config.whitelistIps, companyConfig?.whitelistIps ? companyConfig.whitelistIps : []) : [],
    dropInternet: config && config.navigation ? !config.navigation.enabled : false,
    whitelistMac: config || companyConfig ? getLabelValueFromStringArray(config.whitelistMac, companyConfig?.whitelistMac ? companyConfig.whitelistMac : []) : [],

  })
}


export const copyTargetContent = (target) => {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = target;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const getChangedFields = (newData, oldData, companyData = null) => {
  const changedFields = {};

  for (const [key, ] of Object.entries(oldData)) {
    if (!oldData[key] || !newData[key]) {
      continue;
    }

    const currOldData = oldData[key];
    const currCompanyData = companyData && companyData[key] ? companyData[key] : [];
    const currNewData = newData[key].map((val) => val.value).filter(val => !currCompanyData.includes(val));

    if (currNewData && !_.isEqual(currNewData.sort(), currOldData.sort())) {
      changedFields[key] = currNewData;
    }
  }

  return changedFields;
}


export const getListBlockConfiguration = (dataBlockListIP, dataBlockListDomain) => ({
    policyDomainsBL: dataBlockListIP.blockedIndicators,
    policyIpsBL: dataBlockListDomain.blockedIndicators,
    // securityDomainsBL: config.securityDomainsBL,
    // securityIpsBL: config.securityIpsBL,
    // whitelistDomains: config.whitelistDomains,
    // whitelistIps: config.whitelistIps,
  })

export const getVpnZeroTrustCompanyConfiguration = (config) => {
  return {
    allowedIps: config.allowedIps,
    serverEndpoint: config.serverEndpoint,
    pubKey: config.pubKey,
  }
}

export const getVpnZeroTrustBoardConfiguration = (config) => {
  return {...getVpnZeroTrustCompanyConfiguration(config), ...{
    pvtKey: config.pvtKey,
    boardVpnAddress: config.boardVpnAddress,
  }}
}

export const getErrorMessageFromResponse = (mutation) => {
  const resp = mutation.error.response;

  if (resp?.data?.detail) {
    return _.join(resp.data.detail.map((err) => err.msg), '. ');
  } else if (resp?.data?.message) {
    return resp.data.message;
  } else if (resp?.statusText) {
    return `${resp.status} - ${resp.statusText}`;
  } else {
    return resp?.status;
  }
}


export const mergeBoardUpdatesList = (boards, boardsUpdates) =>
  boards.map((board) => {
    const boardToUpdate = boardsUpdates.filter((b) => b.serialNumber === board.serialNumber);
    board.update = boardToUpdate.length !== 0 && boardToUpdate[0].update;
    return board;
  })


export const firstLetterCapital = (s) => {
  if (!s) { return '' };
  return s.charAt(0).toUpperCase() + s.slice(1);
}



export const mergeBoardMacAddresses = (dataBoards, dataConfigWhitelist) => {
  const boardAllowMacAddresses = [];

  _.forEach(dataBoards, (board) => {
    const match = _.keys(dataConfigWhitelist.whitelist).filter((b) => b === board.serialNumber)

    if (match.length === 0) {
      boardAllowMacAddresses.push({
        serialNumber: board.serialNumber,
        mac: 'All devices are allowed to connect to this DOGMA'
      })
    } else {
      const mac_addresses = dataConfigWhitelist.whitelist[match[0]];

      if (mac_addresses.length === 0) {
        boardAllowMacAddresses.push({
          serialNumber: board.serialNumber,
          mac: 'No devices are allowed to connect'
        })
      } else {
        _.forEach(mac_addresses, (mac) => {
          boardAllowMacAddresses.push({
            serialNumber: board.serialNumber,
            mac: mac
          })
        })
      }
    }
  })

  return boardAllowMacAddresses;
}

export default getTooltipStyles;
