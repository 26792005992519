//import {indexOf} from "@amcharts/amcharts4/.internal/core/utils/Array";


export const DEVICES_STATUS_ACTIVE = 0;
export const DEVICES_STATUS_INACTIVE = 1;

const statusLabels = ['Running', 'Inactive'];
const statusLabelsLower = statusLabels.map(s => s.toLowerCase());
const statusColors = ['success', 'gray'];


export function getDeviceStatusLabel(status) {
  return statusLabels[status];
}


export function getStatusColor(status) {
  return statusColors[status];
}


export function getStatusColorFromLabel(statusLabel) {
  const i = statusLabelsLower.indexOf(statusLabel.toLowerCase());
  return statusColors[i];
}


export function getDeviceRunningState(device) {
  return device?.active ? DEVICES_STATUS_ACTIVE : DEVICES_STATUS_INACTIVE
}
