import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const ResetBoardConfigFetcher = (accessToken, boardId) => {
  return axios.put(`${apiUrl}/operations/boards/${boardId}/reset-customizations/customer`, {}, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useResetBoardConfigMutation = (fetcher = ResetBoardConfigFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['reset-board-config-customer'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('reset-board-config-customer')}
  )
}
