import {useQueryClient} from "react-query";
import { gql } from '@urql/core'
import {gqlApiUrl} from "../../config/api";
import {useMutationWithAuthentication} from "../index";
import { LOGIN_TYPES, buildClient } from "../../shared/gql/utils";

const loginQuery = gql`
  query login($input: CompanyLoginInfoInput!) {
    loginInfo: companyLoginInfo(companyLoginInfoInput: $input) {
      ... on CompanyLoginInfoResult {
        auth0Config {
          clientId
          domain,
          audience,
          organization
          scopes
        }
      }
    }
  }
`


export const CompanyCodeLogin = (accessToken, companyCode) => {
  async function getAuth({ authState }) {
    if (!authState) {
      return { token: accessToken }
    }
  
    return null
  }

  const gqlClient = buildClient(gqlApiUrl, getAuth)

  return gqlClient
    .query(loginQuery, { input: { companyCode, loginType: LOGIN_TYPES.NATIVE } })
    .toPromise()
}

export const useCompanyCodeLoginMutation = (fetcher = CompanyCodeLogin) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['company-code-login'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('company-code-login');
      }}
  )
}
