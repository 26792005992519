import moment from "moment";
//import {indexOf} from "@amcharts/amcharts4/.internal/core/utils/Array";

// const _ = require('lodash');


export const BOARD_STATUS_RUNNING = 0;
export const BOARD_STATUS_IDLE = 1;
export const BOARD_STATUS_INACTIVE = 2;

const statusLabels = ['Running', 'Idle', 'Inactive'];
const statusLabelsLower = statusLabels.map(s => s.toLowerCase());
const statusColors = ['success', 'accent', 'gray'];


export function getBoardStatusLabel(status) {
  return statusLabels[status];
}


export function getStatusColor(status) {
  return statusColors[status];
}


export function getStatusColorFromLabel(statusLabel) {
  const i = statusLabelsLower.indexOf(statusLabel.toLowerCase());
  return statusColors[i];
}


export function getBoardRunningState(board) {
  if (board?.lastAccessDateTimeUTC) {
    if (moment.utc(board.lastAccessDateTimeUTC).isAfter(moment.utc().subtract(1, 'minutes'))) {
      return BOARD_STATUS_RUNNING
    } else if (moment.utc(board.lastAccessDateTimeUTC).isAfter(moment.utc().subtract(3, 'days'))) {
      return BOARD_STATUS_IDLE
    }
  }
  return BOARD_STATUS_INACTIVE
}


export function enrichBoardsDataWithRunningState(boards) {
  return boards.map(b => ({...b, runningState: getBoardRunningState(b)}));
}
