import React from "react";

export const FeatureNotAvailable = ({feature = ''}) => {
  return (
    <div className="d-flex flex-row align-items-center" style={{color:"white"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-2 d-block">Not Available</span>
            <div className="mb-4 lead">The feature {feature} is not available for the current user</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureNotAvailable;
