import {Table} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";


export const TableDetails = ({item, ...props}) => {
  function getHeader(key) {
    if (props.columnConfig) {
      const col = props.columnConfig.find((col) => col.accessor === key)
      return col.Header
    } else {
      return key
    }
  }
  function getValue(key, val) {
    if (props.columnConfig) {
      const col = props.columnConfig.find((col) => col.accessor === key)
      
      return col.Cell ? col.Cell({ value: val }) : val
    } else {
      return val
    }
  }

  const tableOnlyRows = props.columnConfig
    ? props.columnConfig.filter(config => !config.tableOnly).map(config => config.accessor)
    : []
  const items = props.columnConfig
    ? Object.entries(item).filter(([key]) => tableOnlyRows.includes(key))
    : Object.entries(item)

  return (
    <Table>
      <tbody>{
        items.map(([key, value]) =>
          value && <tr key={key}><td className={'text-break w-50'}>{getHeader(key)}</td><th className={'text-break w-50'}>{getValue(key, value)}</th></tr>
        )
      }</tbody>
    </Table>
  )
}


TableDetails.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default TableDetails;
