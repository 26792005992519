import React from "react";
import PropTypes from "prop-types";
import TableWithData from "../../../../shared/components/table/TableWithData";


const tableColumns = [
  { Header: 'Serial Number', accessor: 'serialNumber' },
  { Header: 'Mac Address', accessor: 'mac' },
];


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}

const MacAddressesCompanyTable = ({boards}) => {

  return (
    <TableWithData
      data={boards}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
    />
  )
}


MacAddressesCompanyTable.propTypes = {
  boards: PropTypes.array.isRequired
}

export default MacAddressesCompanyTable;
