import {
  GET_ACTIVE_FILTER,
  SET_FILTER,
} from '../actions/tableState';

const initialState = {
  filter: '',
};

const tableStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_FILTER:
      return { filter: state.filter };
    case SET_FILTER:
      return { ...state, filter: action.filter };
    default:
      return state;
  }
};

export default tableStateReducer;
