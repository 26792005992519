import React, {useCallback, useState, Fragment} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";


const ConfirmButtonModal = ({
  onConfirm,
  buttonLabel,
  titleModal,
  confirmText = 'Do you want to confirm the operation',
  color = 'success',
  float = 'right',
  disabled = false
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);

  return (
    <Fragment>
      <Button
        className={`float-${float}`} outline color={color} onClick={toggleModal} disabled={disabled}
      >{buttonLabel}</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal  modal__title">{titleModal}</h4>
        </div>

        <div className="modal__body">
          {confirmText}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color={color} onClick={() => {
            toggleModal();
            onConfirm();
          }}>Confirm</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>
      </ThemeModal>
    </Fragment>
  );
};

ConfirmButtonModal.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  titleModal: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ConfirmButtonModal;
