import React, {useCallback, useState, Fragment, useEffect} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import ThemeModal from "../../../../shared/components/ThemeModal";
import {useNavigate} from "react-router-dom";
import paths from "../../../../config/paths";
import {useCreateEmitterMutation} from "../../../../queries/SecurityLogs";
import {toast} from "react-hot-toast";
import PropTypes from "prop-types";


const AddEmitterModal = ({companyId}) => {
  const [showModal, setShowModal] = useState(false);
  const [emitterName, setEmitterName] = useState('');
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);
  const mutation = useCreateEmitterMutation();
  const nav = useNavigate();
  const addEmitter = () => {
    toggleModal();
    mutation.mutate({name: emitterName, companyId, format: 0}); // only one for now
  }


  useEffect(() => {
    toast.dismiss();
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Creating the emitter', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          nav(`${paths.configurationEmittersPath}/${mutation.data.id}`);
        } else if (mutation.isError) {
          toast('Error during the creation of the emitter',{icon: '❌'});
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess, mutation, nav])


  return (
    <Fragment>
      <Button className={"rounded float-right"} size="sm" outline onClick={toggleModal}>Add Emitter</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal  modal__title">Add emitter</h4>
        </div>

        <div className="modal__body">
          <form className="form form--vertical" onSubmit={addEmitter}>
            <div className="form__form-group">
              <span className="form__form-group-label">Insert the emitter name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                  <input name={'emitterName'} onChange={(e) => {
                    e.preventDefault();
                    setEmitterName(e.target.value);
                  }} />
                </div>
              </div>
            </div>
          </form>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='success' onClick={addEmitter}>Add</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};


AddEmitterModal.propTypes = {
  companyId: PropTypes.string.isRequired,
}

export default AddEmitterModal;
