import React from 'react';

// import LoadingSpinner from "../LoadingSpinner";
// import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import PropTypes from "prop-types";
import TableWithData from "./TableWithData";


const tableDefaultConfig = {
  withPagination: true,
  // isResizable: true,
  manualPageSize: [10, 25, 50, 100],
}

const TableWithDataHook = ({
  useDataQuery,
  tableColumns,
  tableConfig = tableDefaultConfig,
  getRowDataId,
  onRowClick,
}) => {
  const { data, isIdle, isLoading, isError } = useDataQuery();

  return <TableWithData
    data={data}
    isLoading={(isIdle || isLoading)}
    isError={isError}
    tableColumns={tableColumns}
    tableConfig={tableConfig}
    getRowDataId={getRowDataId}
    onRowClick={onRowClick}
  />
}

TableWithDataHook.propTypes = {
  useDataQuery: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableConfig: PropTypes.shape(),
  getRowDataId: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default TableWithDataHook;
