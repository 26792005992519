import React from 'react';
import { Link } from 'react-router-dom';
import TopbarNavDashboards from './TopbarNavDashboards';
import TopbarNavUIElements from './TopbarNavUIElements';
import TopbarNavOtherPages from './TopbarNavOtherPages';
import TrackingLink from '../../../../shared/components/Link'

const TopbarNav = () => (
  <nav className="topbar__nav">
    <TopbarNavDashboards />
    <TopbarNavUIElements />
    <TopbarNavOtherPages />
    <TrackingLink className="topbar__nav-link" to="/documentation/introduction">Documentation</TrackingLink>
  </nav>
);

export default TopbarNav;
