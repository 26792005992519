import {Button, Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {WithDisableTooltip} from "../../../../shared/hocs/WithDisableTooltip";
import {useForm} from "react-hook-form";
import VpnZeroTrustGenericFieldsForm from "../../../../shared/components/form/VpnZeroTrustGenericFieldsForm";


const DisabledButtonWithTooltip = WithDisableTooltip(Button);


const VpnZeroTrustCompanySubmit = ({config, onSubmit, title = ''}) => {
  const {register, handleSubmit, formState: {errors}, control, watch} = useForm({
    defaultValues:  {
      allowedIps: config.allowed_ips,
      serverEndpoint: config.server_endpoint,
      pubKey: config.server_pub_key,
      status: config.status,
    }
  });

  return (
    <Card>
      <CardBody>
        {
          title && <div className="card__title">
            <h4 className={'bold-text d-inline'}>{title}</h4>
          </div>
        }
        <form className="form form--vertical">
          <VpnZeroTrustGenericFieldsForm
            errors={errors}
            control={control}
            register={register}
            watch={watch}
          />
        </form>
        <Row>
          <Col>
            <DisabledButtonWithTooltip outline className={'float-right'} onClick={handleSubmit(onSubmit)} color={'success'}>
              Update configuration
            </DisabledButtonWithTooltip>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

VpnZeroTrustCompanySubmit.propTypes = {
  config: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
}


export default VpnZeroTrustCompanySubmit;
