import React from 'react';

import LoadingSpinner from "../LoadingSpinner";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import PropTypes from "prop-types";


const tableDefaultConfig = {
  withPagination: true,
  // isResizable: true,
  manualPageSize: [10, 25, 50, 100],
}

const TableWithData = ({
  data,
  isLoading,
  isError,
  tableColumns,
  tableConfig = tableDefaultConfig,
  getRowDataId,
  onRowClick,
}) => {

  if (isLoading) {
    return (
      <LoadingSpinner />
    );
  } else if (isError) {
    return (
      <p>ERROR WHILE FETCHING DATA!</p>
    );
  } else {
    return (
      <ReactTableBase
        key="modified"
        columns={tableColumns}
        data={data}
        tableConfig={tableConfig}
        getRowDataId={getRowDataId}
        onRowClick={onRowClick}
      />
    );
  }
}

TableWithData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array]), // is this prop type right?
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  tableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableConfig: PropTypes.shape(),
  getRowDataId: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default TableWithData;
