import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {useMutationWithAuthentication} from "../index";
import {addAuthToFetchOpts} from "../../config/queryopts";


export const DeleteGroupFetcher = (groupId, accessToken) => {
  return axios.delete(`${apiUrl}/operations/board-groups/${groupId}`, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useDeleteGroupMutation = (fetcher = DeleteGroupFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['delete-group'],
    (accessToken, groupId) => fetcher(groupId, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-group');
        queryClient.invalidateQueries('groups');
      }}
  )
}
