import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from "./LoadingSpinner";

const Loading = ({ loading }) => (
  <div className={`load${loading ? '' : ' loaded'}`}>
    <LoadingSpinner />
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.defaultProps = {
  loading: false,
};

export default Loading;
