import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {useMutationWithAuthentication} from "../index";
import {addAuthToFetchOpts} from "../../config/queryopts";


export const AddGroupFetcher = (accessToken, params) => {
  return axios.post(`${apiUrl}/operations/board-groups`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useAddGroupMutation = (fetcher = AddGroupFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['add-group'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('add-group');
        queryClient.invalidateQueries('groups');
      }}
  )
}
