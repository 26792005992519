import {addAuthToFetchOpts} from '../../config/queryopts'
import {useQueryWithAuthentication} from "../index";
import {apiUrlUpdate} from "../../config/api"
import axios from "axios";


export const DeviceUpdatesFetcher = (accessToken, deviceId) => {
  return axios.get(`${apiUrlUpdate}/devices/${deviceId}/updates`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useDeviceUpdatesQuery = (deviceId, fetcher = DeviceUpdatesFetcher) => {
  return useQueryWithAuthentication(
    ['device-updated', deviceId],
    (accessToken) => fetcher(accessToken, deviceId),
    {refetchInterval: 10000, cacheTime: 0}
  )
}
