export const homePath = "/";
export const loginPath = "/login";
export const companyCodePath = "/company-code";
export const boardsPath = "/boards";
export const boardDetailsPath = "/boards/:boardId";
export const eventsPath = "/events";
export const configurationGroupsPath = "/configuration/groups";
export const configurationGroupDetailsPath = "/configuration/groups/:groupId";
export const configurationCompanyPath = "/configuration/company";
export const configurationBoardsPath = "/configuration/boards";
export const configurationEmittersPath = "/configuration/emitters";
export const configurationEmitterDetailPath = "/configuration/emitters/:emitterId";
export const vpnPath = "/vpn";
export const zeroTrustPath = "/zerotrust";
export const policyConfigurationPath = "/configuration/policy";

export default {
    homePath,
    companyCodePath,
    loginPath,
    boardsPath,
    boardDetailsPath,
    eventsPath,
    configurationGroupsPath,
    configurationGroupDetailsPath,
    configurationCompanyPath,
    configurationBoardsPath,
    configurationEmittersPath,
    configurationEmitterDetailPath,
    zeroTrustPath,
    vpnPath,
    policyConfigurationPath
}
