import axios from "axios";
import { useCallback } from "react";
import {useQueryClient} from "react-query";
import {addAuthToFetchOpts} from '../../../config/queryopts'
import {useQueryWithAuthentication} from "../../../queries/index";
import {useMutationWithAuthentication} from "../..";
import {apiUrl} from "../../../config/api"

function formatPolicyResponse(res) {
  return res.map(r => {
    const { failed, success } = r.result.length
      ? JSON.parse(r.result.replaceAll(`'`, `"`))
      : { failed: [], success: [] }

    return {
      ...r,
      failed,
      success
    }
  })
}

export const createBulkPolicyUploader = (accessToken, params) => {
  return axios.post(
    `${apiUrl}/operations/blocked-lists/policy/bulk-request`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useCreateBulkPolicyUploader = (fetcher = createBulkPolicyUploader) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['create-policy-upload'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('create-policy-upload');
      }}
  )
}

export const importPolicyFetcher = (accessToken) => {
  return axios.get(
    `${apiUrl}/operations/blocked-lists/policy/bulk-requests`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => formatPolicyResponse(res.data));
}

export const useImportsPolicyFetcher = (fetcher = importPolicyFetcher) => {
  return useQueryWithAuthentication(
    ['import-policy'],
    (accessToken) => fetcher(accessToken),
    { retry: false }
  );
}

export const useImportsPolicyFetcherDataFn = () => {
  /*
  Following useCallback has a named callback with PascalCase to avoid the ESLint rule.
  More info on: https://github.com/facebook/react/issues/20531 and https://reactjs.org/docs/hooks-faq.html#what-exactly-do-the-lint-rules-enforce.
  IMPORTANT NOTE: This function returns a custom hook so to enforce hook rules please assign the return value of this
  function to a useXXX variable.
   */
  return useCallback(function MemoizedCustomHook() {
    const {data, isIdle, isLoading, isError, ...other} = useImportsPolicyFetcher();
    return {...other, isIdle, isLoading, isError, data}
  }, []);
}