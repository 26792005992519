import axios from "axios";
import {apiUrl} from "../config/api";
import {addAuthToFetchOpts} from "../config/queryopts";
import {useQueryWithAuthentication} from "./index";


export const listCompanyFetcher = (accessToken) => {
  return axios.get(`${apiUrl}/operations/companies`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useListCompanyQuery = ( fetcher = listCompanyFetcher) => {
  return useQueryWithAuthentication(['list-company'], (accessToken) => fetcher(accessToken))
}
