import Alert from "../../../shared/components/Alert";
import React, {Fragment, useEffect, useState} from "react";
import {useCompanyNumberDeviceUpdatesQuery} from "../../../queries/updates/NumberDevicesToUpdate";
import {Link} from "react-router-dom";
import paths from "../../../config/paths";
import PropTypes from "prop-types";
import {useUserInfo} from "../../../shared/helpers/auth";
import TrackingLink from '../../../shared/components/Link'


const UpdateBoardAlert = ({setIsNotificationBarVisible}) => {
  const [companyId, setCompanyId] = useState(null);
  const { companyId: userCompanyId, isLoading: isLoadingUser } = useUserInfo();
  const { data, isIdle, isLoading, isError } = useCompanyNumberDeviceUpdatesQuery(companyId);

  useEffect(() => {
    if (!isLoadingUser && userCompanyId) {
      setCompanyId(userCompanyId);
    }
  }, [isLoadingUser, userCompanyId])

  if (isLoadingUser || !companyId || isLoading || isIdle || isError || data.number === 0) {
    return <Fragment />
  }

  return (
    <div className={'topbar__alert'}>
      <Alert color="warning" className={'update-board-alert z-index-100'} onClose={() => setIsNotificationBarVisible(false)}>
        <p style={{color: 'black'}}><b>{data.number}</b> boards can be updated. <TrackingLink to={paths.boardsPath} style={{color: 'black'}}><u><span className={'bold-text'}>See Dogma Boards section.</span></u></TrackingLink>
        </p>
      </Alert>
    </div>
  )
}


UpdateBoardAlert.propTypes = {
  setIsNotificationBarVisible: PropTypes.func.isRequired
}


export default UpdateBoardAlert;
