import React, {useState} from 'react';
import axios from "axios";
import {toast} from "react-hot-toast";
import {useForm} from "react-hook-form";
import {Col, Container, Row, Card, CardBody} from 'reactstrap';
import {apiUrl} from "../../../config/api";
import {addAuthToAxiosOpts} from "../../../config/queryopts";
import {useAuth0AccessToken} from "../../../queries";
import {useQueryClient} from "react-query";
import PolicyTable, { tableColumns } from '../../../shared/components/table/withdata/PolicyTable';
import {EVENT_CLASS_POLICY} from "../../../queries/SecurityEvents";
import {WithDetailDrawer} from "../../../shared/hocs/WithDetailDrawer";
import BulkImportPolicyUploadModal from "../../../shared/components/modals/BulkPolicyUploadModal";

const PolicyTableWithDrawer = WithDetailDrawer(PolicyTable, tableColumns);

const Events = () => {
  const queryClient = useQueryClient();
  const { data: accessToken } = useAuth0AccessToken()

  const [ isDetailOpen, setIsDetailOpen ] = useState(false);

  const {handleSubmit, control} = useForm();

  const onSubmitFile = (data) => {
    const csv = data.csv;
    if (csv && csv.length > 0 && csv[0]) {
      toast('Submitting policy...', {icon: '⏳', duration: Infinity});

      const formData = new FormData();
      formData.append('formFile', csv[0].file, csv[0].filename);

      axios.post(`${apiUrl}/operations/blocked-lists/policy/bulk-request`, formData,
        addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
      ).then(
        (res) => {
          toast.dismiss()
          if (res.status === 200) {
            toast('Policy submitted, currently in pending state', {icon: '✔️'});
            queryClient.refetchQueries(['import-policy']);
          }
        }
      ).catch((error) => {
        toast.dismiss();

        if (error.request?.response) {
          toast(`Error: ${error.request.response.replace(']', '').replace('[', '')}.`, {icon: '❌'});
        } else {
          toast(`Error while uploading the CSV file.`, {icon: '❌'});
        }
      })
    }
  }

  return (
  <Container className="devices-container">
    <Row>
      <Col md={10}>
        <h3 className="page-title">Policies</h3>
      </Col>
      <Col md={2}>
        <BulkImportPolicyUploadModal control={control} onSubmit={handleSubmit(onSubmitFile)} />
      </Col>
    </Row>
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <PolicyTableWithDrawer
              isOpen={isDetailOpen}
              setIsDetailOpen={setIsDetailOpen}
              tableConfigOverride={{withHover: true}}
              title={"POLICY DETAILS"}
              eventclassName={EVENT_CLASS_POLICY}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
  )
}

export default Events;
