import React, {Fragment, useState, useMemo, useEffect} from 'react';
import {Card, CardBody} from 'reactstrap';
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import moment from "moment";
import BarChartDetailModal from "../modals/BarChartDetailModal";
import {boardsPath, eventsPath} from "../../../config/paths";
import {EVENTS_DOMAINS} from "../../../queries/SecurityEvents";
import TrackingLink from '../../components/Link'


const _ = require('lodash')


export const getTotalSpan = (totCnt) => {
  let tot;
  if (totCnt / 10**6 > 1) {
    tot = `${_.floor(totCnt / 10 ** 6)}M`;
  } else if ((totCnt / 10**3 > 1)) {
    tot = `${_.floor(totCnt / 10**3)}K`;
  } else {
    tot = totCnt;
  }

  return <span className={"chart__small_card__total-label-total"}>{tot}</span>
}

const CleanLabel = ({activeItemStat, totCnt}) => {
  return (
    <p className="chart__small_card__total-label">{activeItemStat}/{getTotalSpan(totCnt)}</p>
  )
}


const SmallBarChartCard = ({
  title,
  data,
  isLoading = false,
  isError = false,
  barDataKey,
  reverseData = true,
  modal = false,
  boardId = null
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isActiveIndexSet, setIsActiveIndexSet] = useState(false);
  const [activeDate, setActiveDate] = useState(moment());
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data && !isLoading && !isError) {
      if (reverseData) {
        setChartData(data.slice().reverse());
        if (!isActiveIndexSet || activeIndex >= data.length) {
          setIsActiveIndexSet(true);
          setActiveIndex(data.length - 1);
        }
      } else {
        setChartData(data);
      }
    }
  }, [
    data, 
    isLoading,
    isError,
    activeIndex,
    isActiveIndexSet,
    reverseData
  ]);

  useEffect(() => {
    if (isLoading) {
      setActiveDate(moment());
    } else {
      setActiveDate(moment().subtract(6 - activeIndex, 'day'));
    }
  }, [activeIndex, isLoading])

  const activeItemStat = useMemo(
    () => ((chartData && chartData.length > activeIndex) ? chartData[activeIndex][barDataKey] : 0), [chartData, activeIndex, barDataKey]
  );
  const activeItemTotCnt = useMemo(
    () => ((chartData && chartData.length > activeIndex) ? chartData[activeIndex].totCnt : 0), [chartData, activeIndex]
  );

  const eventsUrl = boardId ? `${boardsPath}/${boardId}?focus=${EVENTS_DOMAINS}&overview=1&eventClass=${barDataKey}` :
    `${eventsPath}?focus=${EVENTS_DOMAINS}&eventClass=${barDataKey}`;

  return (
    <Card>
      <CardBody className="chart__small__card">
        <div className="card__title">
          <TrackingLink to={eventsUrl}><h5 className="bold-text">{title}</h5></TrackingLink>
          <p className={"subhead"}>{activeDate.format("DD/MM/YYYY")}</p>
        </div>
        <div className="chart__small_card__wrapper">
          <TrendingUpIcon className="chart__trend-icon" />
          {
            modal ? <BarChartDetailModal
              activeItemStat={activeItemStat} activeDate={activeDate.format("YYYY/MM/DD")} title={title}
              totCnt={activeItemTotCnt} eventClass={barDataKey}
            /> :
            <CleanLabel activeItemStat={activeItemStat} totCnt={activeItemTotCnt} />
          }
          <div className="chart__small_card__wrapper-chart">
            <ResponsiveContainer height={50}>
              <BarChart data={chartData}>
                <Bar dataKey={barDataKey} minPointSize={3}>
                  {(chartData) ? (chartData.map((entry, index) => (
                    <Cell
                      cursor="pointer"
                      fill={index === activeIndex ? '#4ce1b6' : '#3f444c'}
                      key={`cell-${index}`}
                      onClick={()=>setActiveIndex(index)}
                    />
                  ))) : (
                    <Fragment />
                  )}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

SmallBarChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  barDataKey: PropTypes.string.isRequired,
  reverseData: PropTypes.bool,
  boardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default SmallBarChartCard;
