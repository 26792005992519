

const _ = require('lodash')

export const getDepsMutation = (...mutationArr) =>
  _.flattenDeep(mutationArr.map((mutation) => [
    mutation.isLoading, mutation.isIdle, mutation.isError, mutation.isSuccess
  ]));

export const areAnyMutation = (status, ...mutationArr) => {
  return mutationArr.map((mutation) => mutation[status]).includes(true);
}

export const areAllMutation = (status, ...mutationArr) => {
  return mutationArr.map((mutation) => mutation[status]).every((s) => s === true);
}

export const getFirstMutationError = (...mutationArr) => {
  return _.find(mutationArr, (mutation) => mutation.isError);
}

export const resetAllErrorMutation =  (...mutationArr) => {
  _.forEach(mutationArr, (mutation) => {
      if (mutation.isError) {
        mutation.reset();
      }
    })
}

export const resetAllMutation =  (...mutationArr) => {
  _.forEach(mutationArr, (mutation) => {
      mutation.reset();
    })
}
