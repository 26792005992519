import axios from "axios";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


export const configurationBoardFetcher = (accessToken, boardId) => {
  return axios.get(`${apiUrl}/operations/boards/${boardId}/config/customer`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useBoardConfigQuery = (boardId, fetcher = configurationBoardFetcher) => {
  return useQueryWithAuthentication(['board-config-customer', boardId], (accessToken) => fetcher(accessToken, boardId), {
    staleTile: 0, cacheTime: 0
  })
}
