export const GET_ACTIVE_FILTER = 'GET_ACTIVE_FILTER';
export const SET_FILTER = 'SET_FILTER';

export function getFilter() {
  return {
    type: GET_ACTIVE_FILTER
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    filter,
  };
}
