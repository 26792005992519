import axios from "axios";
import { apiUrl } from "../config/api";
import { useQueryWithAuthentication } from "./index";
import { addAuthToFetchOpts } from "../config/queryopts";


export const boardMacAddressesFetcher = (accessToken, boardId) => {
  return axios.get(
    `${apiUrl}/operations/boards/${boardId}/config/customer/macaddress-whitelist`,
    addAuthToFetchOpts(accessToken, {}))
    .then(
      res => {
        if (res.status === 204) {
          return {
            "whitelist_enabled": false,
            "whitelist": []
          }
        } else { return res.data }
      });
}


export const useBoardMacAddressesQuery = (boardId, fetcher = boardMacAddressesFetcher) => {
  return useQueryWithAuthentication(
    ['board-mac-addresses', boardId],
    (accessToken) => fetcher(accessToken, boardId), {
    staleTile: 0, cacheTime: 0
  })
}
