import React from "react";
import FeatureNotAvailable from "../DefaultPage/FeatureNotAvailable";


export const ZeroTrust = () => {
  return (
    <FeatureNotAvailable feature={'ZeroTrust'} />
  )
}

export default ZeroTrust;
