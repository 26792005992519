import React from 'react';
import PropTypes from "prop-types";
import CloseIcon from "mdi-react/CloseIcon";
import TableDetails from "../../components/table/TableDetails";
import classNames from "classnames";


const DetailsDrawer = ({item, isOpen, onClose, title, ...props}) => {
  const drawerClass = classNames({
    drawer__detail__wrap: true,
    'drawer__detail__wrap--open': isOpen,
  });

  return (
    <div className="drawer__detail">
      <div className={drawerClass} hidden={!isOpen}>
        <div className="drawer__detail__title-wrap">
          <h5>{title}</h5>
          <button className="drawer__detail__close-btn" type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <TableDetails columnConfig={props.columnConfig} item={item} />
      </div>
    </div>
  )
}

DetailsDrawer.propTypes = {
  item: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default DetailsDrawer;
