import {Card, CardBody} from "reactstrap";
import SimpleMapsWithData from "./SimpleMapsWithData";
import data from "./sample-data.json";
import React from "react";


const RandomMap = () =>
  <Card>
    <CardBody>
      <div className="card__title">
        <h5 className="bold-text">Events World Map</h5>
      </div>
      <SimpleMapsWithData
        data={data.map((country) => {
          const val = Math.random() * 100;

          // Lights out the map
          return {id: country.id, value: val > 50 ? val : val / 2, name: country.name}
        })}
        height={'380px'}
        labelFn={(value) => ''}
      />
    </CardBody>
  </Card>


export default RandomMap;