import React, {Fragment, useCallback, useState} from 'react';
import DetailsDrawer from "./components/DetailsDrawer";


export const WithDetailDrawer = (Component, columnConfig) => {
  function ComponentWithDetailDrawer ({isOpen, setIsDetailOpen, title, ...otherProps}) {
    const [ selectedItem, setSelectedItem ] = useState({});
    const onDetailsClose = useCallback(() => {setIsDetailOpen(false)}, [setIsDetailOpen])
    const onRowClick = useCallback((dataId, row) => {
      setSelectedItem(row.original)
      setIsDetailOpen(true);
    }, [setIsDetailOpen])

    return (
      <Fragment>
        <DetailsDrawer isOpen={isOpen} onClose={onDetailsClose} item={selectedItem} title={title} columnConfig={columnConfig} />
        <Component
          onRowClick={onRowClick}
          {...otherProps}
        />
      </Fragment>
    );
  }

  ComponentWithDetailDrawer.propTypes = {
    ...Component.propTypes,
  }

  return ComponentWithDetailDrawer;
}
