import React, {useMemo} from 'react';
import {useSecurityStatsQueryWithRefinedData, TIME_UNIT_HOURS as _TIME_UNIT_HOURS, TIME_UNIT_DAYS as _TIME_UNIT_DAYS} from "../../queries/SecurityStats";
import PropTypes from "prop-types";

export const TIME_UNIT_HOURS = _TIME_UNIT_HOURS
export const TIME_UNIT_DAYS = _TIME_UNIT_DAYS

const _ = require('lodash')


export const WithSecurityStats = (Component) => {
  function ComponentWithSecurityData ({dataArgs, ...otherProps}) {
    const {
      timeUnit,
      limit,
      blockedOnly,
      eventClass = null,
      boardId = null,
    } = dataArgs;
    const eventStatsQuery = useSecurityStatsQueryWithRefinedData(timeUnit, limit, blockedOnly, boardId, eventClass);
    const totalStatsQuery = useSecurityStatsQueryWithRefinedData(timeUnit, limit, false, boardId, null);

    const data = useMemo(() =>
      (eventStatsQuery.refinedData && !eventStatsQuery.isError && totalStatsQuery.refinedData && !totalStatsQuery.isError) ?
        _.merge(
          [],
          totalStatsQuery.refinedData.combinedStats.map(value => ({day: value.day, totCnt: value.cnt})),
          eventStatsQuery.refinedData.combinedStats.map(value => ({day: value.day, [eventClass]: value.cnt})),
        ) : null,
      [
        eventStatsQuery.refinedData,
        eventStatsQuery.isError,
        totalStatsQuery.refinedData,
        totalStatsQuery.isError,
        eventClass
      ]
    );

    return (
      <Component
        data={data}
        isLoading={eventStatsQuery.isIdle || eventStatsQuery.isLoading || totalStatsQuery.isIdle || totalStatsQuery.isLoading}
        isError={totalStatsQuery.isError || totalStatsQuery.isError}
        boardId={boardId}
        {...otherProps}
      />
    );
  }

  ComponentWithSecurityData.propTypes = {
    ...Component.propTypes,
    dataArgs: PropTypes.shape({
      timeUnit: PropTypes.oneOf([TIME_UNIT_HOURS, TIME_UNIT_DAYS]).isRequired,
      limit: PropTypes.number.isRequired,
      blockedOnly: PropTypes.bool.isRequired,
    }).isRequired,
  }

  return ComponentWithSecurityData;
}
