import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import FilteredMultiSelect from 'react-filtered-multiselect'
import {PropTypes} from "prop-types";
import {useEditGroupMutation} from "../../../../mutations/groups/UpdateGroup";
import {toast} from "react-hot-toast";


const GroupBoardsForm = ({boardsGroup = [], allBoards, groupId}) => {
  const [currentBoardsInGroup, useCurrentBoardsInGroup] = useState(
    boardsGroup.map(board => ({value: board.serialNumber, label: board.serialNumber}))
  );
  const mutation = useEditGroupMutation();
  const boards = allBoards.map(board => ({value: board.serialNumber, label: board.serialNumber}));

  const useHandleDeselect = (deselectedBoards) => {
    const selectedOptions = currentBoardsInGroup.slice();
    deselectedBoards.forEach(option => {
      selectedOptions.splice(selectedOptions.indexOf(option), 1)
    });
    useCurrentBoardsInGroup(selectedOptions);
  }

  const useHandleSelect = (selectedBoards) => {
    selectedBoards.sort((a, b) => a.serialNumber - b.serialNumber);
    useCurrentBoardsInGroup(selectedBoards);
  }

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Processing the group update', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast('Group updated successfully', {icon: '✔️'});
        } else if (mutation.isError) {
          toast('Error during the update of the group',{icon: '❌'});
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess, mutation])

  const applyChanges = () => {
    mutation.mutate({
      params: {
        serialNumbers: currentBoardsInGroup.map((b) => b.value)
      },
      groupId: groupId
    });
  }

  return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Manage Boards</h4>
          </div>

          <Row>
            <Col md={12}>
            <form className="form form--horizontal">
              <div className="form__form-group">
                <Col md={6}>
                  <h4 className={'m-2 text-center'}>Available boards</h4>
                  <FilteredMultiSelect
                    buttonText="Add"
                    classNames={{
                      filter: 'form__form-group-input-wrap',
                      select: 'form__select-filtered',
                      button: 'btn btn-block btn-success',
                      buttonActive: 'btn btn-block btn-success',
                    }}
                    onChange={useHandleSelect}
                    placeholder={'Search board'}
                    options={boards}
                    selectedOptions={currentBoardsInGroup}
                    textProp="label"
                    valueProp="value"
                  />
                </Col>
                <Col md={6}>
                  <h4 className={'m-2 text-center'}>Boards currently in the group</h4>
                  <FilteredMultiSelect
                    buttonText="Remove"
                    classNames={{
                      filter: 'form__form-group-input-wrap',
                      select: 'form__select-filtered',
                      button: 'btn btn-block btn-danger',
                      buttonActive: 'btn btn-block btn-danger'
                    }}
                    onChange={useHandleDeselect}
                    placeholder={'Search board'}
                    options={currentBoardsInGroup}
                    textProp="label"
                    valueProp="value"
                  />
                </Col>
              </div>
            </form>
            </Col>
          </Row>
          <Row>
            <Col className={'justify-content-end d-flex'}>
              <Button outline onClick={applyChanges} color={'success'}>Apply Changes</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
  )
}

GroupBoardsForm.propTypes = {
  boardsGroup: PropTypes.array.isRequired,
  allBoards: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired
}

export default GroupBoardsForm;
