import axios from "axios";
import {useQueryWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const companyBlockListIPFetcher = (accessToken, companyId) => {
  return axios.get(`${apiUrl}/operations/companies/${companyId}/blocked-lists/policy/ip`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useCompanyBlockListIPQuery = (companyId, fetcher = companyBlockListIPFetcher) => {
  return useQueryWithAuthentication(['company-blocklist-ip', companyId], (accessToken) => fetcher(accessToken, companyId), {
    staleTile: 0, cacheTime: 0
  })
}
