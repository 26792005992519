import axios from "axios";
import {useQueryWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const companyBlockListDomainFetcher = (accessToken, companyId) => {
  return axios.get(`${apiUrl}/operations/companies/${companyId}/blocked-lists/policy/dns`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useCompanyBlockListDomainQuery = (companyId, fetcher = companyBlockListDomainFetcher) => {
  return useQueryWithAuthentication(['company-blocklist-domain', companyId], (accessToken) => fetcher(accessToken, companyId), {
    staleTile: 0, cacheTime: 0
  })
}
