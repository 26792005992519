import React, {useCallback, useState, Fragment, useEffect} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import ThemeModal from "../../../../shared/components/ThemeModal";
import {useDeleteGroupMutation} from "../../../../mutations/groups/DeleteGroup";
import {useNavigate} from "react-router-dom";
import paths from "../../../../config/paths";
import {toast} from "react-hot-toast";
import {PropTypes} from "prop-types";


const DeleteGroupModal = ({group}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);
  const mutation = useDeleteGroupMutation();
  const nav = useNavigate();

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast('Deleting group', {icon: '⏳', duration: Infinity});
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast('Group deleted successfully, you will be redirect', {icon: '✔️'});
          setTimeout(() => {
            toast.dismiss();
            nav(paths.configurationGroupsPath);
          }, 2000);
        } else if (mutation.isError) {
          if (mutation?.error?.response?.status === 409) {
            toast('Can\'t delete a non-empty group, please remove any associated board first',{icon: '⚠️'});
          } else {
            toast('Error during the deletion of the group',{icon: '❌'});
          }
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess, nav, mutation])

  return (
    <Fragment>
      <Button className={"rounded float-right"} size="sm" outline color={'danger'} onClick={toggleModal}>Delete group</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal  modal__title">Delete group {group.name}</h4>
        </div>

        <div className="modal__body">
          Do you really want to delete the configuration group "<b>{group.name}</b>"?
          All the configuration will be lost.
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='danger' onClick={() => {
            toggleModal();
            mutation.mutate(group.id);
          }}>Delete</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};

DeleteGroupModal.propTypes = {
  group: PropTypes.shape().isRequired
}

export default DeleteGroupModal;
