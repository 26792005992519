import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';

import Loading from '../../shared/components/Loading';
import TrackingLink from '../../shared/components/Link';
import { companyCodePath } from '../../config/paths';

const LogIn = () => {
  const nav = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      nav('/')
    }
  }, [isLoading, nav, isAuthenticated])

  if (isLoading) {
    return (<Loading loading={isLoading} />);
  } else {
    return (
      <div className="account account--not-photo">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to the
                <span className="account__logo"> DOGMA
                <span className="account__logo-accent"> Dashboard</span>
              </span>
              </h3>
              <h4 className="account__subhead subhead">Enhance your security</h4>
            </div>
            <div>
              <Button className="account__btn" color="primary" onClick={() => loginWithRedirect({})}>Sign In</Button>
              <p className="login_separator"><span>or</span></p>
              <TrackingLink className="btn btn-outline-primary company_code_link" to={`${companyCodePath}`}>Enter Company Code</TrackingLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

LogIn.propTypes = {};

export default LogIn;