import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { toast } from "react-hot-toast";
import axios from "axios";
import { apiUrl } from "../../../../config/api";
import { addAuthToAxiosOpts } from "../../../../config/queryopts";
import { useAuth0AccessToken } from "../../../../queries";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import PropTypes from "prop-types";
//import TableWithData from "../../../../shared/components/table/TableWithData";
import { useBoardMacAddressesQuery } from "../../../../queries/BoardMacAddresses";
import RegisteredCheckBoxField from "../../../../shared/components/form/components/RegisteredCheckBoxField";
import ConfirmButtonModal from "../../../../shared/components/modals/ConfirmButtonModal";

import { isValidMac } from "../../../../shared/helpers/validations";
import { useForm } from "react-hook-form";
import CreatableSelect from "../../../../shared/components/form/components/CreatableSelect";
const WiFiAttackProtectionBoardsForm = ({ title, boardId }) => {
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white !', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };
  const { data: dataMacAddresses, isIdle: isIdleMacAddresses, isLoading: isLoadingMacAddresses, isError: isErrorMacAddresses } = useBoardMacAddressesQuery(boardId);
  const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm({
    defaultValues: []
  });
  const [valueSelect, setValueSelect] = useState([]);
  const [inputSelect, setInputSelect] = useState('');
  const { data: accessToken, isError: tokenIsError, isLoading: tokenIsLoading, isIdle: tokenIsIdle } = useAuth0AccessToken()

  useEffect(() => {
    if (!(isLoadingMacAddresses || isIdleMacAddresses || isErrorMacAddresses)) {
      if (!dataMacAddresses?.whitelist[boardId]) {
        setValueSelect([])
      } else {
        const opt = dataMacAddresses?.whitelist[boardId].map((mac) => ({ label: mac, value: mac }))
        setValueSelect(opt)
      }
      const optWifi = dataMacAddresses?.whitelist_enabled
      setValue('disableFeature', optWifi)
    }
  }, [dataMacAddresses, isIdleMacAddresses, isLoadingMacAddresses, isErrorMacAddresses])

  const handleKeyDown = (event) => {
    if (!inputSelect) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        for (const value of valueSelect) {
          if (value.label === inputSelect) {
            return;
          }
        }
        if (!isValidMac(inputSelect)) {
          return;
        }
        setValueSelect([...valueSelect, { label: inputSelect, value: inputSelect }]);
        setInputSelect('');
    }
  };
  const updateWhitelistMac = () => {
    let res =
    {
      "whitelist_enabled": watch('disableFeature'),
      "whitelist": valueSelect.map((mac) => (mac.value))
    }
    onSubmitWhitelistMac(res)
  }
  const onSubmitWhitelistMac = (param) => {
    axios.put(`${apiUrl}/operations/boards/${boardId}/config/customer/macaddress-whitelist`, param,
      addAuthToAxiosOpts(accessToken, {})
    ).then(
      (res) => {
        toast.dismiss()
        if (res.status === 200) {
          toast('Configuration updated successfully', { icon: '✔️' });
        }
      }
    ).catch((error) => {
      toast.dismiss();

      if (error.request?.response) {
        toast(`Error: ${error.request.response.replace(']', '').replace('[', '')}.`, { icon: '❌' });
      } else {
        toast(`Error while uploading the CSV file.`, { icon: '❌' });
      }
    })
  }
  if (isLoadingMacAddresses || isIdleMacAddresses) {
    return <Card style={{ height: 'auto' }}><CardBody><LoadingSpinner /></CardBody></Card>
  }

  if (isErrorMacAddresses) {
    return <Card style={{ height: 'auto' }}><CardBody><h3>Unable to load the MAC addresses</h3></CardBody></Card>
  }

  return (
    <Card style={{ height: 'auto' }}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text'}>{title}</h4>
          <h4 className={'bold-text form form--vertical'}>Allowed MAC addresses</h4>
        </div>
        <Row>
          <Col>
            {watch('disableFeature') ?
              <>
                <div className={`form__form-group `}>
                  <div className="form__form-group-field">
                    <div className='form__form-group-input-wrap'>
                      <CreatableSelect
                        components={{ DropdownIndicator: null }}
                        inputValue={inputSelect}
                        styles={styles}
                        isClearable={false}
                        isMulti
                        menuIsOpen={false}
                        placeholder="Insert MAC Address"
                        value={valueSelect}
                        onChange={(value) => { setValueSelect(value) }}
                        onInputChange={(input) => { setInputSelect(input) }}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                </div>
              </>
              : <>
                <h4 style={{'height' : '60px'}}>All devices are allowed to connect to this DOGMA</h4>
              </>}
          </Col>
          <Row>
            <Col className={'mt-3'}>
              <RegisteredCheckBoxField
                name={'disableFeature'}
                value={watch('disableFeature') ?? ""}
                onChange={(value) => { }}
                defaultChecked={false}
                label={"Enable WiFi Attack Protection feature for the Board"}
                register={register}
              />
              <ConfirmButtonModal
                buttonLabel={'Update configuration'}
                onConfirm={updateWhitelistMac}
                titleModal={'Confirm the operation'}
                confirmText={'Are you update WiFi Attack Protection feature for the Board?'}
              />
            </Col>
          </Row>
        </Row>
      </CardBody>
    </Card>
  );
}


WiFiAttackProtectionBoardsForm.propTypes = {
  title: PropTypes.string.isRequired,
  boardId: PropTypes.string.isRequired
}


export default WiFiAttackProtectionBoardsForm;
