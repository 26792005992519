import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarNotification from './TopbarNotification';
// import TopbarSearch from './TopbarSearch';
// import TopbarLanguage from './TopbarLanguage';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
// import {isUpdateNotificationVisible} from "../../../shared/helpers/storage";
import UpdateBoardButton from "./UpdateBoardButton";
import TrackingLink from '../../../shared/components/Link'


const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  user,
  isNotificationBarVisible = false,
  showNotifications = false,
  setIsNotificationBarVisible,
}) => (
  <div className="topbar">
    <div className="topbar__left">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <TrackingLink className="topbar__logo" to="/" />
    </div>
    <div className="topbar__right">
      {/*<div className="topbar__right-search">*/}
      {/*  <TopbarSearch />*/}
      {/*</div>*/}
      {
        showNotifications && !isNotificationBarVisible &&
        <UpdateBoardButton setIsNotificationBarVisible={setIsNotificationBarVisible} />
      }
      <div className="topbar__right-over">
        {/*<TopbarNotification />*/}
        {/*<TopbarMail new />*/}
        <TopbarProfile />
        {/*<TopbarLanguage />*/}
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  user: UserProps.isRequired,
  isNotificationBarVisible: PropTypes.bool,
  showNotifications: PropTypes.bool,
  setIsNotificationBarVisible: PropTypes.func
};

export default Topbar;
