import React, {useCallback, useState, Fragment} from 'react';
import {Button, ButtonToolbar, Row, Col} from 'reactstrap';
import ThemeModal from "../../../../shared/components/ThemeModal";
import PropTypes from "prop-types";
import ControlledFilepond from '../../../../shared/components/form/ControlledFilepond';

const BulkUploadBoardsGroup = ({control,onSubmit}) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);
    const _onSubmit = () => {
        toggleModal()
        onSubmit()
      }
  return (
    <Fragment>
      <Button color={'secondary'} className={"rounded float-right me-1"} size="sm" outline onClick={toggleModal}>Bulk Upload Boards</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
         <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal modal__title">Add boards group Management CSV</h4>
        </div>
        <div className="modal__body">
        <Row>
            <Col>
              Upload a CSV to add boards to groups by serial number. <br /><br />
              <i>Only well formatted files will be accepted.</i>
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col>
              <h5 className={'bold-text mb-2'}><u>Valid format</u></h5>
              <span className={'font-italic'}>
              groupids;serialnumberlist<br />
              xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx;123456789dab13245|123456789dab13246<br />
              </span>
            </Col>
          </Row>
          <Row className={'mt-3'}>
            <Col >
            <ControlledFilepond name={'csv'} control={control} />
            </Col>
          </Row>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='success' onClick={_onSubmit}>Add</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>
      </ThemeModal>
    </Fragment>
  );
};


BulkUploadBoardsGroup.propTypes = {
    control: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }
export default BulkUploadBoardsGroup;
