import React, {Fragment, useCallback, useState} from "react";
import {Button, ButtonToolbar, Col, Row} from "reactstrap";
import ThemeModal from "../../../../shared/components/ThemeModal";
import ControlledFilepond from "../../../../shared/components/form/ControlledFilepond";
import PropTypes from "prop-types";
import RegisteredCheckBoxField from "../../../../shared/components/form/components/RegisteredCheckBoxField";


const UploadCSVModal = ({control, onSubmit, watch, register}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);

  return (
    <Fragment>
      <Button color={'secondary'} className={'float-right'} outline size="sm" onClick={toggleModal}>Upload CSV</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <h4 className="text-modal modal__title">Add new MAC addresses CSV</h4>
        </div>

        <div className="modal__body">
          <Row>
            <Col>
              Upload a CSV to add new MAC addresses. <br /><br />
              <i>Only well formatted files will be accepted.</i>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <h5 className={'bold-text mb-2'}><u>Valid format</u></h5>
              <span className={'font-italic'}>
                serialNumber;macaddresslist <br />
                dab12345656789;00:00:00:00:00:00 <br />
                123456dab56789;00:00:00:00:00:00|11:11:11:11:11:11 <br />
                56789dab123456; <br />
              </span>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <ControlledFilepond name={'csv'} control={control} />
            </Col>
          </Row>

          <div className="form__form-group form__form-group-field mt-3">
            <RegisteredCheckBoxField
              name={'initialEnableFeature'}
              value={watch('initialEnableFeature')}
              onChange={(value) => {}}
              defaultChecked={false}
              label={"Enable WiFi Attack Protection feature"}
              register={register}
            />
          </div>

        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='success' onClick={onSubmit}>Add</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};


UploadCSVModal.propTypes = {
  control: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired
}


export default UploadCSVModal;
