import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {useMutationWithAuthentication} from "../index";
import {addAuthToFetchOpts} from "../../config/queryopts";


export const AddGroupFetcher = (accessToken, params, groupId) => {
  return axios.put(`${apiUrl}/operations/board-groups/${groupId}/boards/bulk`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useEditGroupMutation = (fetcher = AddGroupFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['update-group'],
    (accessToken, {params, groupId}) => fetcher(accessToken, params, groupId),
    {onSuccess: (_ , {groupId}) => {
        queryClient.invalidateQueries('update-group');
        queryClient.invalidateQueries(['dogma-boards-group', groupId]);
      }}
  )
}
