import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classNames from "classnames";

const SidebarLink = ({
  title, icon, newLink, route, onClick, exact, disabled
}) => (
  <NavLink
    exact={exact}
    to={route}
    onClick={disabled ? (e) => e.preventDefault() : onClick}
    className={({ isActive }) => isActive ? 'sidebar__link-active' : ''}
  >
    <li className={classNames({
      "sidebar__link": true,
      "sidebar__link_disabled": disabled
    })}>
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
  disabled: false
};

export default SidebarLink;
