import React from 'react';
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom";
import {useForm} from "react-hook-form";
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';

import{ useCompanyCodeLoginMutation } from '../../mutations/company-code-login'
import {getErrorMessageFromResponse} from "../../shared/helpers";
import { getFirstMutationError, resetAllErrorMutation } from "../../shared/helpers/mutation";

function CompanyCodeLogin(props) {
  const nav = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const {register, handleSubmit, formState: {errors}} = useForm();
  const companyCodeLoginMutation = useCompanyCodeLoginMutation();

  React.useEffect(() => {
    if (!isLoading && isAuthenticated) {
      nav('/')
    }
  }, [isLoading, nav, isAuthenticated])

  React.useEffect(() => { 
    if (!companyCodeLoginMutation.isIdle) {
      if (companyCodeLoginMutation.isLoading) {
        
      } else {
        toast.dismiss();
        if (companyCodeLoginMutation.isError) {
          toast(
            `Error: ${getErrorMessageFromResponse(getFirstMutationError(companyCodeLoginMutation))}`,
            {icon: '❌'}
          );
          resetAllErrorMutation(companyCodeLoginMutation);
        } else if (companyCodeLoginMutation.isSuccess) {
          const { auth0Config } = companyCodeLoginMutation.data.data.loginInfo
          loginWithRedirect({
            clientId: auth0Config.clientId,
            domain: auth0Config.domain,
            authorizationParams: {
              audience: auth0Config.audience,
              organization: auth0Config.organization,
              scope: auth0Config.scopes
            }
          })
        }
      }
    }
  }, [
    companyCodeLoginMutation.isIdle,
    companyCodeLoginMutation.isLoading,
    companyCodeLoginMutation.isError,
  ]);

  function onSubmit(data) {
    companyCodeLoginMutation.mutate(data.companyCode)
  }

  return (
    <div className="company_code">
      <div className="company_code__wrapper">
        <div className="company_code__card">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor='companyCode'>Enter company code</label>
            <input
              defaultValue=''
              placeholder={'123ACME'}
              {...register('companyCode', { required: true })}
            />
            <Button disabled={!!errors.companyCode} className="company_code__btn" color="primary">Confirm Code</Button>
          </form>
        </div>
      </div>
    </div>    
  )
}

export default CompanyCodeLogin