import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  combineBy, TIME_UNIT_DAYS, TIME_UNIT_HOURS, useSecurityStatsQueryWithRefinedData
} from "../../../../queries/SecurityStats";
import SmallGaugeChart from "../SmallGaugeChart";
import moment from "moment";


const _ = require('lodash')


const LAST_HOURS = 24;
const getIndexFromRiskScore = (riskScore) => _.ceil(riskScore / 25) - 1;
const getRiskScoreColor = (riskScore) => riskColors[getIndexFromRiskScore(riskScore)];
const getRiskScoreLabel = (riskScore) => riskLabel[getIndexFromRiskScore(riskScore)];


const riskLabel = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
const riskColors = ["#4caf50", "#ffd600", "#ff5722", "#d50000"];


const getBlockedStats = (blockedEvents, totalBlockedEvents) => {
  const blocked = _.ceil(_.meanBy(
    combineBy('hour', blockedEvents.ipStats, blockedEvents.dnsStats), (value) => value.cnt
  ));

  totalBlockedEvents.dnsStats = totalBlockedEvents.dnsStats.filter(val => val.day !== moment().format('YYYY/MM/DD'));
  totalBlockedEvents.ipStats = totalBlockedEvents.ipStats.filter(val => val.day !== moment().format('YYYY/MM/DD'));

  // Average is based on the total of blocked events, divided by all the counted days, divided by 24 hours
  // and multiply by the aggregation
  const avg = _.ceil(_.meanBy(
    combineBy('day', totalBlockedEvents.ipStats, totalBlockedEvents.dnsStats), (value) => value.cnt / 24
  ));

  if (blocked === 0) {
    return {avg: avg, blocked: 0, percentage: 0}
  } else if (avg === 0) {
    return {avg: 0, blocked: blocked, percentage: 175}
  }

  const percentage = (blocked / avg) * 100;
  return {
    avg: avg, blocked: blocked, percentage: percentage
  }
}


const getRiskTooltip = (blockedEvents, totalBlockedEvents) => {
  let {avg, blocked, percentage} = getBlockedStats(blockedEvents, totalBlockedEvents);

  if (blocked === 0) {
    return `There are no security events in the last ${LAST_HOURS} hours.`;
  }

  if (avg === 0) {
    return `There are ${blocked} security events in the last ${LAST_HOURS} hours.`;
  }

  if (percentage < 125) {
    return `There are few security events in the last ${LAST_HOURS} hours but still under the average`;
  }

  return `The number of security events is ${percentage.toFixed(2)}% higher than the average`;
}

const getRiskScore = (blockedEvents, totalBlockedEvents) => {
  const {avg, blocked, percentage} = getBlockedStats(blockedEvents, totalBlockedEvents);

  if (blocked === 0) {
    return 25;
  } else if (avg === 0) {
    return 75;
  }

  // TODO: is there something better?
  if (percentage < 125) {
    return 25 + (percentage / 10);
  }

  return _.min([percentage - 100, 100]);
}

const getFocus = (blockedEvents) => {
  const maxIPS = _.maxBy(blockedEvents.ipStats, val => val.cnt).cnt;
  const maxDNSs = _.maxBy(blockedEvents.dnsStats, val => val.cnt).cnt;

  if (maxIPS !== 0 || maxDNSs === 0) {
    return 'ips';
  } else {
    return 'domains';
  }
}


const RiskSmallGaugeChart = ({
  boardId,
  to = null
 }) => {
  const blockedStatsQuery = useSecurityStatsQueryWithRefinedData(TIME_UNIT_HOURS, LAST_HOURS, true, boardId, 'security');
  const totalBlockedStatsQuery = useSecurityStatsQueryWithRefinedData(TIME_UNIT_DAYS, 7, true, boardId, 'security');

  const riskScore = useMemo(() => (
      (blockedStatsQuery.refinedData && !blockedStatsQuery.isError && totalBlockedStatsQuery.refinedData && !totalBlockedStatsQuery.isError)
        ? getRiskScore(blockedStatsQuery.refinedData, totalBlockedStatsQuery.refinedData) : 100
    ),
    [blockedStatsQuery.refinedData, blockedStatsQuery.isError, totalBlockedStatsQuery.refinedData, totalBlockedStatsQuery.isError]
  );

  const riskTooltip = useMemo(() => (
      (blockedStatsQuery.refinedData && !blockedStatsQuery.isError && totalBlockedStatsQuery.refinedData && !totalBlockedStatsQuery.isError)
        ? getRiskTooltip(blockedStatsQuery.refinedData, totalBlockedStatsQuery.refinedData) : ""
    ),
    [blockedStatsQuery.refinedData, blockedStatsQuery.isError, totalBlockedStatsQuery.refinedData, totalBlockedStatsQuery.isError]
  );

  const focus = useMemo(() => (
      (blockedStatsQuery.refinedData && !blockedStatsQuery.isError) ? getFocus(blockedStatsQuery.refinedData) : []
    ),
    [blockedStatsQuery.refinedData, blockedStatsQuery.isError]
  );

  to = !to ? to : to.includes('?') ? `${to}&focus=${focus}` : `${to}?focus=${focus}`;

  return (
    <SmallGaugeChart
      value={riskScore}
      isError={blockedStatsQuery.isError || totalBlockedStatsQuery.isError}
      isLoading={blockedStatsQuery.isLoading || totalBlockedStatsQuery.isLoading || blockedStatsQuery.isIdle || totalBlockedStatsQuery.isIdle}
      title={"RISK SCORE"}
      label={getRiskScoreLabel(riskScore)}
      color={getRiskScoreColor(riskScore)}
      tooltipText={riskTooltip}
      to={to}
    />
  )
}


RiskSmallGaugeChart.propTypes = {
  boardId: PropTypes.string,
  to: PropTypes.string,
};

export default RiskSmallGaugeChart;
