import React, {useCallback} from 'react';
import { Card, CardBody} from 'reactstrap';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import {EVENTS_DOMAINS, EVENTS_IPS, useSecurityEventsQuery} from "../../../queries/SecurityEvents";
import LoadingSpinner from "../LoadingSpinner";
import {firstLetterCapital} from "../../helpers";

const _ = require('lodash')

const FULL_MARK = 100;
const MID_THRESH = 70;
const LOW_THRESH = 40;

// const _sampleData = [
//   {
//     subject: 'Malware', A: 50, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Adware', A: 100, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Phishing', A: 80, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Scam', A: 75, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'CnC', A: 35, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Drop Point', A: 55, fullMark: FULL_MARK,
//   },
// ];


// const _emptyData = [
//   {
//     subject: 'Malware', A: 0, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Adware', A: 0, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Phishing', A: 0, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Scam', A: 0, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'CnC', A: 0, fullMark: FULL_MARK,
//   },
//   {
//     subject: 'Drop Point', A: 0, fullMark: FULL_MARK,
//   },
// ];


const SimpleRadarChart = () => {
  const formatterFn = useCallback((value) => ((value <= LOW_THRESH) ? 'Low' : (value <= MID_THRESH) ? 'Mid' : 'High'), []);

  // TODO: WAIT FOR THE CORRECT APIs
  const { data: dataBlockListDomain, isIdle: isIdleBlockListDomain, isLoading: isLoadingBlockListDomain, isError: isErrorBlockListDomain } =  useSecurityEventsQuery(EVENTS_IPS, null, true, null);
  const { data: dataBlockListIP, isIdle: isIdleBlockListIP, isLoading: isLoadingBlockListIP, isError: isErrorBlockListIP } =  useSecurityEventsQuery(EVENTS_DOMAINS, null, true, null);

  if (isErrorBlockListDomain || isErrorBlockListIP) {
    return <Card><CardBody><div className="card__title"><h5 className="bold-text">Error while fetching data</h5></div></CardBody></Card>
  }

  if (isIdleBlockListDomain || isIdleBlockListIP || isLoadingBlockListDomain || isLoadingBlockListIP) {
    return <Card><CardBody><LoadingSpinner /></CardBody></Card>
  }

  const indicatorCategory = {
    Phishing: 0, Malicious: 0, Scam: 0, Adware: 0, C2: 0
  }

  _.forEach(dataBlockListDomain.events.concat(dataBlockListIP.events), (event) => {
    const index = firstLetterCapital(event.indicatorCategory);
    // console.log(firstLetterCapital(event.indicatorCategory), indicatorCategory)
    if (indicatorCategory[index] !== undefined) {
      indicatorCategory[index] += 1;
    }
  })

  const data = [];
  for (const [category, value] of Object.entries(indicatorCategory)) {
    data.push({subject: firstLetterCapital(category), A: value, fullMark: FULL_MARK})
  }

  const chartData = _.reverse(_.sortBy(data, x => x.A));

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Event Categories</h5>
        </div>
        <div dir="ltr">
          <ResponsiveContainer height={380}>
            <RadarChart data={chartData} outerRadius={90}>
              <PolarGrid strokeDasharray="3 3" />
              <PolarAngleAxis dataKey="subject"  />
              {/*<PolarRadiusAxis />*/}
              <Tooltip formatter={formatterFn} />
              <Radar name="Cardinality" dataKey="A" stroke="#3ea3fc" fill="rgb(112, 187, 253)" fillOpacity={0.6}  />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </CardBody>
    </Card>
  );
};

export default SimpleRadarChart;
