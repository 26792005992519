import axios from "axios";
import {useQueryClient} from "react-query";
import {useMutationWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const UpdateGroupBlockListPolicyDomainFetcher = (accessToken, {groupId, params}) => {
  return axios.put(`${apiUrl}/operations/board-groups/${groupId}/blocked-lists/policy/dns`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateGroupBlockListPolicyDomainMutation = (fetcher = UpdateGroupBlockListPolicyDomainFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-operation-group-blocklist-policy-domain'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-operation-group-blocklist-policy-domain')}
  )
}
