import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import BoardDevicesTable from "./BoardDevicesTable";


const BoardDevicesSection = ({boardId}) => {
  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <BoardDevicesTable fixedBoardId={boardId}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

BoardDevicesSection.propTypes = {
  boardId: PropTypes.string.isRequired,
}

export default BoardDevicesSection;
