import React from "react";
import FeatureNotAvailable from "../DefaultPage/FeatureNotAvailable";


export const VPN = () => {
  return (
    <FeatureNotAvailable feature={'VPN'} />
  )
}

export default VPN;
