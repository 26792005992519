import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrl} from "../config/api"
import axios from "axios";

export const dogmaBoardsFetcher = (accessToken, boardId=null) => {
  const uri = (boardId) ? `/${boardId}` : '';
  return axios.get(`${apiUrl}/operations/boards${uri}`, addAuthToFetchOpts(accessToken, {}))
    .then(res => {
      if (res.data.length) {
        return res.data.sort((a, b) => {
          return new Date(b.lastAccessDateTimeUTC).getTime() - new Date(a.lastAccessDateTimeUTC).getTime()
        })
      } else {
        return res.data
      }
    });
}

export const useDogmaBoardsQuery = (boardId = null, fetcher = dogmaBoardsFetcher) => {
  return useQueryWithAuthentication(['dogma-boards', boardId], (accessToken) => fetcher(accessToken, boardId), {refetchInterval: 10000})
  // return useQuery('dogma-devices', () => fetcher(accessToken))
}
