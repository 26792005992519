import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Link, useParams} from "react-router-dom";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import EmitterDetailsBody from "./components/EmitterDetailsBody";
import EmitterProvisionInstructions from "./components/EmitterProvisionInstructions"
// import {useDogmaBoardsQuery} from "../../../queries/DogmaBoards";
import paths from "../../../config/paths";
import {useEmitterQuery} from "../../../queries/SecurityLogs";
import AppError from "../../DefaultPage/AppError";
import TrackingLink from '../../../shared/components/Link'


const EmitterDetails = () => {
  const { emitterId } = useParams();
  const { data, isIdle, isLoading, isError, refetch } = useEmitterQuery(emitterId);

  if (isLoading || isIdle) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <AppError />
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Emitter Details</h3>
          <h3 className="page-subhead subhead">
            <TrackingLink to={`${paths.configurationEmittersPath}`}>Back to emitters</TrackingLink>
          </h3>
        </Col>
      </Row>
      <EmitterDetailsBody emitter={data} rehydrateData={refetch} />
      <EmitterProvisionInstructions apiKey={data.apiKey} />
    </Container>
  );
}

export default EmitterDetails;
