import React from 'react';
import {Card, CardBody, Col, Progress} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

export const CleanLabel = ({data}) => {
  return(
    <p className="chart__small_percentage__total-label">
      {data?.value}<span className="chart__small_percentage__fractional-label">{(data) ? `/${data?.total}` : ''}</span>
    </p>
  )
}

const SmallPercentageChartCard = ({
 title,
 data,
 isLoading = false,
 isError = false,
 labelComponent = <CleanLabel data={data} />
}) => {

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="chart__small__card">
          <div className="chart__small_percentage__wrapper">
            <div className="chart__small_percentage__top-line chart__small_percentage__top-line--turquoise">
              {labelComponent}
              <TrendingUpIcon className="chart__trend-icon" />
            </div>
            <div className="chart__small_percentage__title-label">
              <h5>{title}</h5>
            </div>
            <div className="progress-wrap progress-wrap--small progress-wrap--turquoise-gradient progress-wrap--label-top">
              <Progress value={(data) ? `${data.percent}` : ''}><p className="progress__label">{(data) ? `${data.percent}%` : ''}</p></Progress>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SmallPercentageChartCard;
