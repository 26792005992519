import axios from "axios";
import {useQueryClient} from "react-query";
import {useMutationWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const UpdateBoardBlockListPolicyDomainFetcher = (accessToken, {boardId, params}) => {
  return axios.put(`${apiUrl}/operations/boards/${boardId}/blocked-lists/policy/dns`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateBoardBlockListPolicyDomainMutation = (fetcher = UpdateBoardBlockListPolicyDomainFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-board-blocklist-policy-domain'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-board-blocklist-policy-domain')}
  )
}
