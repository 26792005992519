import React, { useState, Fragment, useEffect } from 'react';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import { useDogmaBoardsQuery } from "../../../queries/DogmaBoards";
import BoardsList from "./components/BoardsList";
import BoardConfigurationForms from "./components/BoardConfigurationForms";
import AppError from "../../DefaultPage/AppError";
import { useUserInfo } from "../../../shared/helpers/auth";


const SECT_BOARDS_CUSTOM_CONFIG = 0;
const SECT_BOARDS_COMPANY_CONFIG = 1;
export const SECT_BLOCK_LIST_CONFIG = 0
export const SECT_VPN_ZERO_CONFIG = 1
export const SECT_WIFI_PROTECTION_CONFIG = 2


const ConfigurationBoards = () => {
  const [overviewBoard, setOverviewBoard] = useState(SECT_BOARDS_CUSTOM_CONFIG);
  const [overviewConfiguration, setOverviewConfiguration] = useState(SECT_BLOCK_LIST_CONFIG);
  const [selectedBoardCustom, setSelectedBoardCustom] = useState(null);
  const [selectedBoardCompany, setSelectedBoardCompany] = useState(null);

  const { data: dataBoards, isIdle: isIdleBoards, isLoading: isLoadingBoards, isError: isErrorBoards } = useDogmaBoardsQuery();
  const { companyId, isLoading, error: userInfoError } = useUserInfo();

  if (isLoadingBoards || isIdleBoards || isLoading) {
    return <LoadingSpinner />
  }

  if (isErrorBoards) {
    console.error('Could not load boards\' data');
    return <AppError />
  }
  if (userInfoError) {
    console.error('Could not load user data or user data has some missing information');
    return <AppError />;
  }
  const customConfigBoards = dataBoards.filter((board) => board.hasBoardCustomerConfigurationDefined);
  const otherBoards = dataBoards.filter((board) => !board.hasBoardCustomerConfigurationDefined);

  if (!selectedBoardCustom && customConfigBoards.length > 0) {
    setSelectedBoardCustom(customConfigBoards[0])
  }
  if (!selectedBoardCompany && otherBoards.length > 0) {
    setSelectedBoardCompany(otherBoards[0])
  }
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Board configuration details</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Button className="rounded" size="sm" outline
            active={overviewBoard === SECT_BOARDS_CUSTOM_CONFIG} onClick={() => setOverviewBoard(SECT_BOARDS_CUSTOM_CONFIG)}
          >
            Custom Configuration
          </Button>
          <Button className="rounded" size="sm" outline
            active={overviewBoard === SECT_BOARDS_COMPANY_CONFIG} onClick={() => setOverviewBoard(SECT_BOARDS_COMPANY_CONFIG)}
          >
            Company Configuration
          </Button>

        </Col>
        <Col md={6}>
          <Button className="rounded" size="sm" outline
            active={overviewConfiguration === SECT_BLOCK_LIST_CONFIG} onClick={() => setOverviewConfiguration(SECT_BLOCK_LIST_CONFIG)}
          >
            Block List
          </Button>
          <Button
            className="rounded" size="sm" outline disabled
            active={overviewConfiguration === SECT_VPN_ZERO_CONFIG} onClick={() => setOverviewConfiguration(SECT_VPN_ZERO_CONFIG)}
          >
            VPN & ZeroTrust
          </Button>
          <Button
            className="rounded" size="sm" outline
            active={overviewConfiguration === SECT_WIFI_PROTECTION_CONFIG} onClick={() => setOverviewConfiguration(SECT_WIFI_PROTECTION_CONFIG)}
          >
            WiFi Attack Protection
          </Button>
        </Col>
      </Row>
      <Row>
        {
          (overviewBoard === SECT_BOARDS_CUSTOM_CONFIG) ?
            (
              (customConfigBoards === []) ?
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <div className="card__title">
                        <h4 className={'bold-text d-inline'}>Boards</h4>
                      </div>
                      <Fragment>You have not created a custom configuration yet.</Fragment>
                    </CardBody>
                  </Card>
                </Col>
                :
                <BoardsList
                  boards={customConfigBoards}
                  section={overviewBoard}
                  setSelectedBoard={setSelectedBoardCustom}
                />
            )
            :
            (
              (otherBoards === []) ?
                <Col md={6}>
                  <Card>
                    <CardBody>
                      <div className="card__title">
                        <h4 className={'bold-text d-inline'}>Boards</h4>
                      </div>
                      <Fragment>You have customized all the board configuration.</Fragment>
                    </CardBody>
                  </Card>
                </Col>
                :
                <BoardsList
                  boards={otherBoards}
                  section={overviewBoard}
                  setSelectedBoard={setSelectedBoardCompany}
                />
            )
        }
        {
          (overviewBoard === SECT_BOARDS_CUSTOM_CONFIG && !selectedBoardCustom) ||
            (overviewBoard === SECT_BOARDS_COMPANY_CONFIG && !selectedBoardCompany) ?
            <Col md={6}>
              <Card style={{ height: 'auto' }}>
                <CardBody>
                  <h4>
                    {
                      overviewBoard === SECT_BOARDS_CUSTOM_CONFIG ? <Fragment>You have not created a custom configuration yet.<br /><br />Go to "<b>Company Configuration</b>" to select a board.</Fragment> :
                        <Fragment>You have customized all the board configuration.<br /><br />Go to "<b>Custom Configuration</b>" to select a board.</Fragment>
                    }
                  </h4>
                </CardBody>
              </Card>
            </Col>
            :
            <BoardConfigurationForms
              board={overviewBoard === SECT_BOARDS_CUSTOM_CONFIG ? selectedBoardCustom : selectedBoardCompany}
              overview={overviewConfiguration}
              companyId={companyId}
            />
        }
      </Row>

    </Container>

  );
}

export default ConfigurationBoards;
