import React from "react";
import {Col, Row} from "reactstrap";
import ControlledMultiSelectTextWithTitle from "./components/ControlledMultiSelectTextWithTitle";
import {isValidDomain, isValidIPorSubnet} from "../../helpers/validations";
import {PropTypes} from "prop-types";


const DomainIpConfigurationForm = ({control, errors, watch}) => {
  return (
    <Row className={'w-100'}>

      <Col md={6} xs={12}>
        <ControlledMultiSelectTextWithTitle
          name={`policyDomainsBL`} title={'Blocklist policy domains'} control={control} valueFn={(value) => value}
          validationFn={isValidDomain}
          error={errors?.policyDomainsBL}
          isClearable={false}
        />
        {/* isClearable={watch(`policyDomainsBL`) && !watch(`policyDomainsBL`).find((t) => t.isFixed)}*/}

        {/*<ControlledMultiSelectTextWithTitle*/}
        {/*  name={`securityDomainsBL`} title={'Blocklist security domains'} control={control} valueFn={(value) => value}*/}
        {/*  validationFn={isValidDomain}*/}
        {/*  error={errors?.securityDomainsBL}*/}
        {/*/>*/}
        {/*<ControlledMultiSelectTextWithTitle*/}
        {/*  name={'whitelistDomains'} title={'Allowed domains'} control={control} valueFn={(value) => value}*/}
        {/*  validationFn={isValidDomain}*/}
        {/*  error={errors?.whitelistDomains}*/}
        {/*/>*/}
      </Col>
      <Col md={6} xs={12}>
        <ControlledMultiSelectTextWithTitle
          name={`policyIpsBL`} title={'Blocklist policy IPs'} control={control} valueFn={(value) => value}
          validationFn={isValidIPorSubnet}
          error={errors?.policyIpsBL}
          isClearable={false}
        />
        {/* isClearable={watch(`policyIpsBL`) && !watch(`policyIpsBL`).find((t) => t.isFixed)}*/}

        {/*<ControlledMultiSelectTextWithTitle*/}
        {/*  name={`securityIpsBL`} title={'Blocklist security IPs'} control={control} valueFn={(value) => value}*/}
        {/*  validationFn={isValidIPorSubnet}*/}
        {/*  error={errors?.securityIpsBL}*/}
        {/*/>*/}
        {/*<ControlledMultiSelectTextWithTitle*/}
        {/*  name={'whitelistIps'} title={'Allowed IPs'} control={control} valueFn={(value) => value}*/}
        {/*  validationFn={isValidIPorSubnet}*/}
        {/*  error={errors?.whitelistIps}*/}
        {/*/>*/}
      </Col>
    </Row>
  )
}

DomainIpConfigurationForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
}

export default DomainIpConfigurationForm;
