import React, {useCallback} from "react";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {useEmittersQuery} from "../../../../queries/SecurityLogs";
import {truncateStr} from "../../../../shared/helpers/display"
import PropTypes from "prop-types";


const tableColumns = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Company ID', accessor: 'companyId' },
  { Header: 'API Key', accessor: 'apiKey', Cell: ({row}) => truncateStr(row.original.apiKey)}
];

const tableConfig = {
  withPagination: true,
  withHover: true,
  withSearchEngine: true,
}

const EmittersTable = ({onRowClick}) => {
  const { data, isIdle, isLoading, isError } = useEmittersQuery();
  const getRowDataId = useCallback((row, i) => (row.original.id), [])

    return (
      <TableWithData
        tableColumns={tableColumns}
        tableConfig={tableConfig}
        data={data}
        isError={isError}
        isLoading={isLoading || isIdle}
        onRowClick={onRowClick}
        getRowDataId={getRowDataId}
      />
    )
}

EmittersTable.propTypes = {
  onRowClick: PropTypes.func.isRequired
}

export default EmittersTable;
