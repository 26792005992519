import axios from "axios";
import {useQueryClient} from "react-query";
import {useMutationWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const UpdateCompanyBlockListPolicyIPFetcher = (accessToken, {companyId, params}) => {
  return axios.put(`${apiUrl}/operations/companies/${companyId}/blocked-lists/policy/ip`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateCompanyBlockListPolicyIPMutation = (fetcher = UpdateCompanyBlockListPolicyIPFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-company-blocklist-policy-ip'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-company-blocklist-policy-ip')}
  )
}
