import React from 'react';
import PropTypes from 'prop-types';

const ScrollToTop = ({ children }) => {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return children;
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ScrollToTop;
