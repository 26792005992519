import axios from "axios";
import {useQueryClient} from "react-query";
import {addAuthToFetchOpts} from "../config/queryopts";
import {useMutationWithAuthentication} from "./index";
import {apiUrl} from "../config/api";


export const DeleteGroupFetcher = (boardId, accessToken) => {
  return axios.delete(`${apiUrl}/operations/boards/${boardId}/customer-data`, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useDeleteBoardCustomerDataMutation = (fetcher = DeleteGroupFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['delete-board-customer-data'],
    (accessToken, boardId) => fetcher(boardId, accessToken),
    {onSuccess: () => queryClient.invalidateQueries('delete-board-customer-data')}
  )
}
