import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import DomainIpConfigurationForm from "./DomainIpConfigurationForm";
import RegisteredCheckBoxField from "./components/RegisteredCheckBoxField";
import PropTypes from "prop-types";


const BoardConfigurationFieldsForm = ({control, errors, register, watch, listsOnly = false}) =>
    <Fragment>
      <DomainIpConfigurationForm
        control={control}
        errors={errors}
        watch={watch}
      />
      {!listsOnly && <Row>
        <Col>
          <div className="form__form-group form__form-group-field">
            <RegisteredCheckBoxField
              name={'dropInternet'}
              value={watch('dropInternet')}
              onChange={(value) => {}}
              defaultChecked={false}
              label={"Disable internet connection on this board"}
              register={register}
            />
          </div>
        </Col>
      </Row>}
    </Fragment>


BoardConfigurationFieldsForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  listsOnly: PropTypes.bool
}

export default BoardConfigurationFieldsForm;
