import React, {useState, Fragment} from 'react';
import {Col, Container, Row, Card, CardBody, Button} from 'reactstrap';
import {Link, useParams} from "react-router-dom";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import GroupConfigurationsForms from "./components/GroupConfigurationsForms";
import GroupBoardsForm from "./components/GroupBoardsForm";
import DeleteGroupModal from "./components/DeleteGroupModal";
import {useDogmaBoardsQuery} from "../../../queries/DogmaBoards";
import paths from "../../../config/paths";
import {useGroupDetailQuery} from "../../../queries/DogmaGroupDetail";
import AppError from "../../DefaultPage/AppError";
import {useBoardsGroupQuery} from "../../../queries/DogmaBoardsGroup";
import TrackingLink from '../../../shared/components/Link'


const SECT_CONFIG = 0;
const SECT_BOARDS = 1;

export const SECT_GROUP_BLOCK_LIST_CONFIG = 0
export const SECT_GROUP_VPN_ZERO_CONFIG = 1


const ConfigurationGroupsDetails = () => {
  const [overview, setOverview] = useState(SECT_CONFIG);
  const [overviewConfiguration, setOverviewConfiguration] = useState(SECT_GROUP_BLOCK_LIST_CONFIG);
  const { groupId } = useParams();
  const { data, isIdle, isLoading, isError } = useGroupDetailQuery(groupId);
  const { data: dataGroupBoards, isIdle: isIdleGroupBoards, isLoading: isLoadingGroupBoards, isError: isErrorGroupBoards } = useBoardsGroupQuery(groupId);
  const { data: dataBoards, isIdle: isIdleBoards, isLoading: isLoadingBoards, isError: isErrorBoards } = useDogmaBoardsQuery();


  if (isLoading || isIdle || isLoadingBoards || isIdleBoards || isIdleGroupBoards || isLoadingGroupBoards) {
    return <LoadingSpinner />
  }

  if (isError || isErrorBoards || isErrorGroupBoards) {
    return <AppError />
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Group "{data.name}" details</h3>
          <h3 className="page-subhead subhead">
            <TrackingLink to={`${paths.configurationGroupsPath}`}>Back to configuration groups</TrackingLink>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Button className="rounded" size="sm" outline active={overview === SECT_CONFIG} onClick={() => setOverview(SECT_CONFIG)}>Configuration</Button>
          <Button className="rounded" size="sm" outline active={overview === SECT_BOARDS} onClick={() => setOverview(SECT_BOARDS)}>Boards</Button>
          <DeleteGroupModal group={data} />
        </Col>
      </Row>
      {
        (overview === SECT_CONFIG) ? (
          <Fragment>
            <Row>
              <Col md={12}>
                <Button className="rounded" size="sm" outline
                        active={overviewConfiguration === SECT_GROUP_BLOCK_LIST_CONFIG} onClick={() => setOverviewConfiguration(SECT_GROUP_BLOCK_LIST_CONFIG)}
                >
                  Block List
                </Button>
                <Button className="rounded" size="sm" outline disabled
                        active={overviewConfiguration === SECT_GROUP_VPN_ZERO_CONFIG} onClick={() => setOverviewConfiguration(SECT_GROUP_VPN_ZERO_CONFIG)}
                >
                  VPN & Zero Trust
                </Button>
              </Col>
            </Row>

            <GroupConfigurationsForms groupId={groupId} overview={overviewConfiguration} />
          </Fragment>
        ) : (overview === SECT_BOARDS) ? (
          <GroupBoardsForm boardsGroup={dataGroupBoards} allBoards={dataBoards} groupId={groupId} />
        ) : (
          <Row><Col><Card><CardBody>ERROR! SHOULD NEVER REACH THIS!</CardBody></Card></Col></Row>
        )
      }
    </Container>
  );
}

export default ConfigurationGroupsDetails;
