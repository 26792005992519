import React, {Fragment, useCallback, useMemo, useState} from "react";
import {
  EVENTS_DOMAINS,
  EVENTS_IPS,
  useSecurityEventsQuery,
} from "../../../queries/SecurityEvents";
import LoadingSpinner from "../LoadingSpinner";
import Collapse from "../Collapse";
import PropTypes from "prop-types";
import {getTotalSpan} from "../charts/SmallBarChartCard";
import ThemeModal from "../ThemeModal";
import moment from "moment";
import TableDetails from "../table/TableDetails";
import {Link} from "react-router-dom";
import TrackingLink from '../../components/Link'


const _ = require('lodash')


const getCollapseTitle = (event) => {
  let ioc;
  if (event.eventType === EVENTS_IPS) {
    ioc = ` ${event.ipDest}`;
  } else if (event.eventType === EVENTS_DOMAINS) {
    ioc = ` ${event.requestedDomain}`;
  } else {
    return "";
  }

  return `[${moment(event.logDatetimeOnDogma).format("HH:mm:ss")} ${event.boardId}] ${ioc} [${event.indicatorCategory}]`;
}

const BarChartDetailModal = ({activeItemStat, activeDate, title, totCnt, eventClass}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {setShowModal(!showModal)}, [setShowModal, showModal]);

  // TODO: WAIT FOR THE CORRECT APIs
  const eventIPS = useSecurityEventsQuery(EVENTS_IPS, null, true, eventClass);
  const eventDomains = useSecurityEventsQuery(EVENTS_DOMAINS, null, true, eventClass);
  const isLoading = eventIPS.isLoading || eventDomains.isLoading;
  const isIdle = eventIPS.isIdle || eventDomains.isIdle;
  const isError = eventIPS.isError || eventDomains.isError;

  const data = useMemo(() =>
      (eventIPS.data && eventDomains.data && !isLoading && !isError) ?
        _.concat(
          eventIPS.data.events.map((val) => { val.eventType = EVENTS_IPS; return val; }),
          eventDomains.data.events.map((val) => { val.eventType = EVENTS_DOMAINS; return val; }),
        ).filter(
          val => moment(val.logDatetimeOnDogma).format('YYYY/MM/DD') === activeDate
        ) : [] ,
    [eventIPS.data, eventDomains.data, isLoading, isError, activeDate]
  );

  const focus = useMemo(() =>
      (eventIPS.data && eventDomains.data && !isLoading && !isError) &&
        eventIPS.data.events.filter(
          val => moment(val.logDatetimeOnDogma).format('YYYY/MM/DD') === activeDate
        ).length === 0 && eventDomains.data.events.filter(
        val => moment(val.logDatetimeOnDogma).format('YYYY/MM/DD') === activeDate
      ).length > 0 ?
        'domains' : 'ips',
    [eventIPS.data, eventDomains.data, isLoading, isError, activeDate]
  );

  return (
    <Fragment>
      <p className="chart__small_card__total-label chart__small_card__total-label-modal" onClick={toggleModal}>
        {activeItemStat}/{getTotalSpan(totCnt)}
      </p>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
          <TrackingLink to={`/events?focus=${focus}&eventClass=${eventClass}`}>
            <h4 className="text-modal modal__title">{`${title} (${data.length})`}</h4>
          </TrackingLink>
        </div>
        <div className="modal__body">
          {(!data || isLoading || isIdle) ? 
            <LoadingSpinner /> : (
              isError ? <p>ERROR WHILE FETCHING DATA!</p> :
              <div>
                {
                  data.length > 0 ? data.map(
                    (event, index) => <Collapse
                      title={getCollapseTitle(event)} className={"with-shadow"} key={index}
                    >
                      <TableDetails item={event} />
                    </Collapse>
                  ) : <p className={'bold-text'}>There are no events to show</p>
                }
              </div>
            )
          }
        </div>

      </ThemeModal>
    </Fragment>
  );
}

BarChartDetailModal.propTypes = {
  title: PropTypes.string.isRequired,
  activeItemStat: PropTypes.number.isRequired,
  eventClass: PropTypes.string.isRequired,
  activeDate: PropTypes.string.isRequired,
  totCnt: PropTypes.number.isRequired,
}

export default BarChartDetailModal;