import React, { Fragment, useCallback, useState } from 'react';
import ThemeModal from '../components/ThemeModal';
import { Button, ButtonToolbar } from 'reactstrap';

export const WithConfirmationModal = (Component, actionPropName) => {
    function ComponentWithConfirmationModal({
        modalEnabled,
        onConfirm,
        titleModal,
        confirmText,
        color = 'success',
        ...otherProps}
    ) {
        const [showModal, setShowModal] = useState(false);
        const toggleModal = useCallback(() => { setShowModal(!showModal) }, [setShowModal, showModal]);
        const actionPropDict = {}
        actionPropDict[actionPropName] = modalEnabled ? toggleModal : onConfirm
        return (
            <Fragment>
                <Component 
                {...actionPropDict} {...otherProps}
                />
                <ThemeModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    modalClassName={'ltr-support'}
                    className={'modal-dialog--header modal-dialog--dark'}
                >
                    <div className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
                        <h4 className="text-modal  modal__title">{titleModal}</h4>
                    </div>

                    <div className="modal__body">
                        {confirmText}
                    </div>

                    <ButtonToolbar className="modal__footer">
                        <Button outline className="rounded" color={color} onClick={() => {
                            toggleModal();
                            onConfirm();
                        }}>Confirm</Button>
                        <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
                    </ButtonToolbar>
                </ThemeModal>
            </Fragment>
        );
    }
    ComponentWithConfirmationModal.propTypes = {
        ...Component.propTypes,
    }
    return ComponentWithConfirmationModal
}