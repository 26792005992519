import axios from "axios";
import {useQueryClient} from "react-query";
import {useMutationWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const UpdateCompanyBlockListPolicyDomainFetcher = (accessToken, {companyId, params}) => {
  return axios.put(`${apiUrl}/operations/companies/${companyId}/blocked-lists/policy/dns`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateCompanyBlockListPolicyDomainMutation = (fetcher = UpdateCompanyBlockListPolicyDomainFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-company-blocklist-policy-domain'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-company-blocklist-policy-domain')}
  )
}
