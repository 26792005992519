import axios from "axios";
import {apiUrl} from "../config/api";
import {addAuthToFetchOpts} from "../config/queryopts";
import {useQueryWithAuthentication} from "./index";


export const dogmaBoardsGroupFetcher = (accessToken, groupId) => {
  return axios.get(`${apiUrl}/operations/board-groups/${groupId}/boards`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useBoardsGroupQuery = (groupId, fetcher = dogmaBoardsGroupFetcher) => {
  return useQueryWithAuthentication(['dogma-boards-group', groupId], (accessToken) => fetcher(accessToken, groupId))
}
