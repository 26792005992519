import {copyTargetContent} from "../../../helpers";
import toast from "react-hot-toast";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import EyeIcon from "mdi-react/EyeIcon";
import React, {useState} from "react";
import PropTypes from "prop-types";


const RegisteredInputFieldCopyHidden = ({
  title,
  register,
  name,
  errors,
  rules = {},
  withCopy = false,
  withHidden = false,
  copyValue = '',
  isDisabled = false,
}) => {
  const [isPasswordKeyShown, setIsPasswordKeyShown] = useState(!withHidden);

  return (
    <div className={'form__form-group'}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-input-wrap">
          <input type={isPasswordKeyShown ? 'text' : 'password'} {...register(name, rules)} disabled={isDisabled} />
          {errors[name] && <span className="form__form-group-error">{errors[name].message}</span>}
        </div>
        {
          withCopy && <button
            className={'form__form-group-button form__form-group-button-with-hover'}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              copyTargetContent(copyValue);
              toast(`Copied ${title}`, {icon: '📄'});
            }}
          ><ContentCopyIcon/>
          </button>}
        {
          withHidden && <button
            className={`form__form-group-button${isPasswordKeyShown ? ' active' : ''}`} type="button" onClick={() => setIsPasswordKeyShown(!isPasswordKeyShown)}
          ><EyeIcon/>
          </button>
        }
      </div>
    </div>
  )
}


RegisteredInputFieldCopyHidden.propTypes = {
  title: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape().isRequired,
  rules: PropTypes.shape(),
  withCopy: PropTypes.bool,
  withHidden: PropTypes.bool,
  copyValue: PropTypes.string,
  isDisabled: PropTypes.bool,
}


export default RegisteredInputFieldCopyHidden;
