import axios from "axios";
import {useQueryWithAuthentication} from "../../index";
import {addAuthToFetchOpts} from "../../../config/queryopts";
import {apiUrl} from "../../../config/api";


export const groupBlockListIPFetcher = (accessToken, groupId) => {
  return axios.get(`${apiUrl}/operations/board-groups/${groupId}/blocked-lists/policy/ip`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useGroupBlockListIPQuery = (groupId, fetcher = groupBlockListIPFetcher) => {
  return useQueryWithAuthentication(['group-blocklist-ip', groupId], (accessToken) => fetcher(accessToken, groupId))
}
