import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Col, Container, Row, Card, CardBody, Button, Badge} from "reactstrap"
import {useParams, Link, useNavigate, useLocation } from "react-router-dom";
import {boardsPath} from "../../config/paths";
import {EVENT_CLASS_POLICY, EVENT_CLASS_SECURITY, EVENTS_DOMAINS, EVENTS_IPS} from "../../queries/SecurityEvents";
import BoardOverviewSection from "./components/BoardOverviewSection";
import BoardEventsSection from "./components/BoardEventsSection";
import BoardDevicesSection from "./components/BoardDevicesSection";
import {
  areParamsValid,
  fixHistoryURL,
  isParamValid,
  useQueryParams
} from "../../shared/components/router/QueryNavigationHelpers";
import {
  getBoardRunningState,
  getBoardStatusLabel,
  getStatusColorFromLabel
} from "../../shared/helpers/querydata/boardsdata";
import {useDogmaBoardsQuery} from "../../queries/DogmaBoards";
import NotFound404 from "../DefaultPage/404";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import AppError from "../DefaultPage/AppError";
import BoardConfigSection from "./components/BoardConfigSection";
import {useDeleteBoardCustomerDataMutation} from "../../mutations/DeleteBoardCustomerData";
import DeleteByIdBinModal from "../../shared/components/modals/DeleteByIdBinModal";
import {useQueryClient} from "react-query";
import {useUserInfo} from "../../shared/helpers/auth";
import {useDeviceUpdatesQuery} from "../../queries/updates/DeviceToUpdate";
import UpdateBoardModal from "../../shared/components/modals/UpdateBoardModal";
import {useUpdateBoardConfigMutation} from "../../mutations/updates/UpdateDevices";
import TrackingLink from '../../shared/components/Link'


const _ = require('lodash');


export const SECT_OVERVIEW = "0";
export const SECT_EVENTS = "1";
export const SECT_DEVICES = "2";
export const SECT_CONFIG = "3";


const DogmaBoardDetails = () => {
  const { boardId } = useParams();
  const { data, isIdle, isLoading, isError, error } = useDogmaBoardsQuery(boardId);
  const { data: dataUpdates, isIdle: isIdleUpdates, isLoading: isLoadingUpdates } = useDeviceUpdatesQuery(boardId);
  const mutation = useUpdateBoardConfigMutation();
  const nav = useNavigate();
  const location = useLocation();
  const params = useQueryParams();
  const deleteMutation = useDeleteBoardCustomerDataMutation();
  const queryClient = useQueryClient();
  const [companyId, setCompanyId] = useState(null)
  const { companyId: userCompanyId, isLoading: isLoadingUser, isError: isErrorUser } = useUserInfo();
  const requiredParams = {
    overview: [SECT_OVERVIEW, SECT_DEVICES, SECT_EVENTS, SECT_CONFIG]
  }
  const reloadSection = () => {
    queryClient.invalidateQueries();
  };

  const overview = isParamValid(params.get('overview'), requiredParams.overview) ? params.get('overview') : SECT_CONFIG;
  let eventClass;
  const optionalParams = {
    eventClass: [EVENT_CLASS_SECURITY, EVENT_CLASS_POLICY],
  }

  if (params.has('eventClass')) {
    if (isParamValid(params.get('eventClass'), optionalParams.eventClass)) {
      eventClass = params.get('eventClass');
    } else {
      eventClass = EVENT_CLASS_SECURITY;
    }

    if (!areParamsValid(params, requiredParams) || !areParamsValid(params, optionalParams)) {
      fixHistoryURL(params, _.merge(requiredParams, optionalParams), nav, location);
    }

  } else if (!areParamsValid(params, requiredParams)) {
    fixHistoryURL(params, requiredParams, nav, location);
  }

  const boardStatus = useMemo(() =>
      (!isIdle && !isError && !isLoading && data) ? getBoardStatusLabel(getBoardRunningState(data)) : "",
    [isIdle, isError, isLoading, data]
  )

  useEffect(() => {
    if (!isLoadingUser && userCompanyId) {
      setCompanyId(userCompanyId);
    }
  }, [isLoadingUser, userCompanyId])

  if (error) {
    return error?.response?.status === 404 ? <NotFound404 /> : <AppError />;
  }

  if (isIdle || isLoading || isLoadingUser || isLoadingUpdates || isIdleUpdates) {
    return <LoadingSpinner />
  }

  if (isErrorUser || !companyId ) {
    console.error('Could not load user data or user data has some missing information');
    return <AppError />;
  }

  return (
    <Container className="dogma-boards-container dashboard devices-container">
      <Row>
        <Col md={12}>
          <h3 className="page-title">DOGMA Board Details</h3>
          <h3 className={'subhead mb-2'}>Board: {boardId}
            { boardStatus && <Badge color={getStatusColorFromLabel(boardStatus)} className={"outline d-inline ml-3 status-badge"}>{boardStatus}</Badge> }
          </h3>
          <h3 className="page-subhead subhead">
            <TrackingLink to={`${boardsPath}`}>Back to DOGMA Boards</TrackingLink>
            {/*<Breadcrumb>*/}
            {/*  <BreadcrumbItem><TrackingLink to={boardsPath}>Boards</TrackingLink></BreadcrumbItem>*/}
            {/*  <BreadcrumbItem>Details</BreadcrumbItem>*/}
            {/*</Breadcrumb>*/}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TrackingLink to={`?overview=${SECT_OVERVIEW}`} className={"p-sm-1"}>
            <Button className="rounded" size="sm" outline active={overview === SECT_OVERVIEW}>Overview</Button>
          </TrackingLink>
          <TrackingLink to={`?overview=${SECT_EVENTS}&focus=${EVENTS_IPS}`} className={"p-sm-1"}>
            <Button className="rounded" size="sm" outline active={overview === SECT_EVENTS}>Events</Button>
          </TrackingLink>
          <TrackingLink to={`?overview=${SECT_DEVICES}`} className={"p-sm-1"}>
            <Button className="rounded" size="sm" outline active={overview === SECT_DEVICES}>Devices</Button>
          </TrackingLink>

          <DeleteByIdBinModal
            mutation={deleteMutation}
            itemId={boardId}
            classNameButton={'float-right mx-sm-2'}
            deleteTitle={`Delete board ${boardId} data`}
            deleteMsg={`The operation will delete all navigation data regarding the board ${boardId}. Do you want to continue?`}
            onDeleteSuccessFn={() => {
              queryClient.resetQueries(['events-security', EVENTS_IPS, boardId, null]);
              queryClient.resetQueries(['events-security', EVENTS_DOMAINS, boardId, null]);
              queryClient.refetchQueries(['events-security', EVENTS_IPS, boardId, null]);
              queryClient.refetchQueries(['events-security', EVENTS_DOMAINS, boardId, null]);
              reloadSection()
            }}
          />

          <TrackingLink to={`?overview=${SECT_CONFIG}`} className={"p-sm-1"}>
            <Button className="rounded float-right" outline size="sm" active={overview === SECT_CONFIG}><span className="lnr lnr-cog" /></Button>
          </TrackingLink>

          {
            dataUpdates && dataUpdates.update &&
            <UpdateBoardModal
              classNameButton={'rounded float-right mx-sm-2'}
              mutation={mutation}
              boardSerials={[boardId]}
              updateTitle={'Board update'}
              updateMsg={'Update this board to the lastest software version?'}
              onUpdateSuccessFn={() => {
                queryClient.resetQueries(['devices-updated', companyId]);
                queryClient.resetQueries(['company-number-updates', companyId]);
                queryClient.resetQueries(['device-updated', boardId]);
              }}
            />
          }

        </Col>
      </Row>
      {
        (overview === SECT_OVERVIEW) ? (
          <BoardOverviewSection boardId={boardId} />
        ) : (overview === SECT_EVENTS) ? (
          <BoardEventsSection boardId={boardId} eventclassName={eventClass} />
        ) : (overview === SECT_DEVICES) ? (
          <BoardDevicesSection boardId={boardId} />
        ) : (overview === SECT_CONFIG) ? (
          <BoardConfigSection boardId={boardId} companyId={companyId} hasCustomConfig={data.hasBoardCustomerConfigurationDefined} />
        ) : (
          <Row><Col><Card><CardBody>ERROR! SHOULD NEVER REACH THIS!</CardBody></Card></Col></Row>
        )
      }
    </Container>
  );
}

DogmaBoardDetails.propTypes = {
  boardsUrl: PropTypes.string.isRequired,
}

export default DogmaBoardDetails;
