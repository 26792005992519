import React from 'react';
import {Col, Container, Row} from 'reactstrap';

import EventsPanel from "./components/EventsPanel";
import SmallBarChartCard from "../../../shared/components/charts/SmallBarChartCard";
import {WithSecurityStats, TIME_UNIT_DAYS} from "../../../shared/hocs/WithSecurityStats";
// import {useDogmaBoardsQuery} from "../../../queries/DogmaBoards";
import ActiveBoardsSmallPercentageChartCard from "../../../shared/components/charts/withdata/ActiveBoardsSmallPercentageChartCard";

import {connect} from "react-redux";
// import {BasicNotification, showNotification} from "../../../shared/components/Notification";
import RiskSmallGaugeChart from "../../../shared/components/charts/withdata/RiskSmallGaugeChart";
// import SmallPieChart from "../../../shared/components/charts/SmallPieChart";
import {eventsPath} from "../../../config/paths"
import SmallRadarChart from "../../../shared/components/charts/SmallRadarChart";
import RandomMap from "../../../shared/components/maps/EasyVectorMap/RandomMap";


const SecurityEventsSmallBarChartCard = WithSecurityStats(SmallBarChartCard)


const CompanyDashboard = ({rtl, theme}) => {
  // useEffect(() => {
  //   // const title = 'Welcome to the EasyDev!';
  //   // const message = 'You have successfully registered in the EasyDev. Now you can start to explore the dashboard'
  //   //   + 'interface with a bunch of components and applications. Enjoy!';
  //   // const notificationInitialProps = {
  //   //   content: <BasicNotification
  //   //     title={title}
  //   //     message={message}
  //   //     theme={theme}
  //   //   />,
  //   //   closable: true,
  //   //   duration: 10,
  //   //   style: { top: 0, left: 'calc(100vw - 100%)' },
  //   //   className: `right-up ${rtl.direction}-support`,
  //   // };
  //
  //   setTimeout(() => showNotification(theme, rtl, 'Security Event', 'A security risk has been prevented!'), 7000);
  //   return (() => (null));
  // }, [rtl.direction, theme]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Security Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={3} lg={6} xs={12}>
          <RiskSmallGaugeChart to={eventsPath} />
        </Col>

        <Col md={12} xl={3} lg={6} xs={12}>
          <SecurityEventsSmallBarChartCard
            title={"Daily Security Events"}
            dataArgs={{
              timeUnit: TIME_UNIT_DAYS,
              limit: 7,
              blockedOnly: true,
              eventClass: 'security',
            }}
            barDataKey={'security'}
            modal={true}
          />
        </Col>

        <Col md={12} xl={3} lg={6} xs={12}>
          <SecurityEventsSmallBarChartCard
            title={"Daily Policy Events"}
            dataArgs={{
              timeUnit: TIME_UNIT_DAYS,
              limit: 7,
              blockedOnly: true,
              eventClass: 'policy',
            }}
            barDataKey={'policy'}
            modal={true}
          />
        </Col>
        <ActiveBoardsSmallPercentageChartCard
          title='Running boards' modal={true}
        />
      </Row>
      <Row>
        <Col md={12}>
          <EventsPanel />
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={8}>
          <RandomMap />
        </Col>
        <Col xs={12} md={12} xl={4}>
          {/*<SmallPieChart />*/}
          <SmallRadarChart />
        </Col>
      </Row>
    </Container>
  );
};

CompanyDashboard.propTypes = {
};

export default connect(state => ({ theme: state.theme, rtl: state.rtl }))(CompanyDashboard);
