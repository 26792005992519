import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const UpdateBoardConfigFetcher = (accessToken, {boardId, params}) => {
  return axios.put(`${apiUrl}/operations/boards/${boardId}/config/customer`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateBoardConfigMutation = (fetcher = UpdateBoardConfigFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-board-config-customer'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-board-config-customer')}
  )
}
