import React from "react";
import PropTypes from 'prop-types';
import {Card, CardBody, Col, Row} from "reactstrap";
// import {PropTypes} from "prop-types";
import {apiUrl} from "../../../../config/api";

const EmitterProvisionInstructions = ({ apiKey }) => {

  return (
    <Card className="emitter-provision-card">
      <CardBody>
        <Row>
          <Col md={12}>
            <div className="card__title">
              <h5 className="bold-text mb-2">To install this emitter on Debian OS:</h5>
              <code className="emitter-script">
              curl -s https://packagecloud.io/install/repositories/duskrise/syslog_emitter/script.deb.sh | sudo os=any dist=any bash &&
sudo DR_API_KEY={apiKey} DR_API_ENDPOINT={apiUrl} apt-get install duskrise-syslog-emitter
              </code>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

EmitterProvisionInstructions.propTypes = {
  apiKey: PropTypes.string
}

export default EmitterProvisionInstructions