import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const UpdateStrictModeFetcher = (accessToken, companyId, params) => {
  return axios.put(`${apiUrl}/operations/companies/${companyId}/config/customer/strictmode`, { enabled: params } , addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateStrictMode = (fetcher = UpdateStrictModeFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-strict-mode-customer'],
    (accessToken, companyId, params) => fetcher(accessToken, companyId,  params),
    {onSuccess: () => queryClient.invalidateQueries('update-strict-mode-customer')}
  )
}
