import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { Badge } from 'reactstrap';
import { useDogmaBoardsQuery } from "../../../queries/DogmaBoards";
import { getBoardRunningState, getBoardStatusLabel, getStatusColorFromLabel } from "../../../shared/helpers/querydata/boardsdata";
import TableWithData from "../../../shared/components/table/TableWithData";
import {mergeBoardUpdatesList} from "../../../shared/helpers";


const statusAccessor = (row) => getBoardStatusLabel(getBoardRunningState(row))


const DogmaBoardsTable = ({onRowClick, boardsUpdates = [], ...props}) => {
  const { data, isIdle, isLoading, isError } = useDogmaBoardsQuery()
  const getRowDataId = useCallback((row, i) => (row.original.serialNumber), [])

  const tableColumns = [
    { Header: 'Serial Number', accessor: 'serialNumber', minWidth: 300 },
    { Header: 'Last Access Time', accessor: 'lastAccessDateTimeUTC',
      Cell: ({value}) => moment.utc(value).local().format("DD/MM/YYYY HH:mm:ss")},
    {
      Header: '', accessor: 'update',
      Cell: ({value}) => value ? <Badge color={'warning'} className={"outline"}><span className="lnr lnr-upload mr-2" />Update available</Badge> :
        <Fragment />
    },
    { Header: 'Status', accessor: statusAccessor,
      Cell: ({value}) => (<Badge color={getStatusColorFromLabel(value)} className={"outline"}>{value}</Badge>)},
  ];

  const tableConfig = {
    withPagination: true,
    withHover: true,
    withSearchEngine: true,
  }

  const visibleData = boardsUpdates.length > 0 && data ? mergeBoardUpdatesList(data.slice(), boardsUpdates) : data?.slice()

  return (
    <TableWithData
      data={visibleData}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
      getRowDataId={getRowDataId}
      onRowClick={onRowClick}
      isError={isError}
      isLoading={isLoading || isIdle}
      {...props}
    />
  );
}

DogmaBoardsTable.propTypes = {
  onRowClick: PropTypes.func,
  boardsUpdates: PropTypes.array,
};

export default DogmaBoardsTable;
