import axios from "axios";
import {apiUrl} from "../config/api";
import {addAuthToFetchOpts} from "../config/queryopts";
import {useQueryWithAuthentication} from "./index";


export const dogmaGroupDetailFetcher = (accessToken, groupId) => {
  return axios.get(`${apiUrl}/operations/board-groups/${groupId}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useGroupDetailQuery = (groupId, fetcher = dogmaGroupDetailFetcher) => {
  return useQueryWithAuthentication(['group-detail', groupId], (accessToken) => fetcher(accessToken, groupId))
}
