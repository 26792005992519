import React from 'react';
const ToggleButton = ({
    checked,
    onChange,
    onClick,
    float = 'right'}) => {
        return (
            <>
                <div className={`toggle-switch small-switch float-${float}`} onClick={onClick} >
                    <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        checked={checked || ''}
                        onChange={e => onChange(e.target.checked)}
                    />
                    <label className="toggle-switch-label" htmlFor="toggleSwitch">
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                    </label>
                </div>
            </>
        )

    }
    export default ToggleButton