import React, {Fragment} from "react";
import ControlledMultiSelectTextWithTitle from "./components/ControlledMultiSelectTextWithTitle";
import {isValidIP, isValidIPorSubnet} from "../../helpers/validations";
import PropTypes from "prop-types";
import RegisteredInputFieldCopyHidden from "./components/RegisteredInputFieldCopyHidden";
import RegisteredCheckBoxField from "./components/RegisteredCheckBoxField";


const VpnZeroTrustGenericFieldsForm = ({control, errors, register, watch}) => {
    return (
      <Fragment>
        <ControlledMultiSelectTextWithTitle
          name={`allowedIps`} title={'Allowed IPs'} control={control} valueFn={(value) => value}
          validationFn={isValidIPorSubnet}
          error={errors?.policyIpsBL}
        />
        <ControlledMultiSelectTextWithTitle
          name={`serverEndpoint`} title={'Server Endpoint'} control={control} valueFn={(value) => value}
          validationFn={isValidIP}
          error={errors?.securityIpsBL}
        />
        <RegisteredInputFieldCopyHidden
          name={'pubKey'}
          register={register}
          title={'Public Key'}
          errors={errors}
        />
        <div className="form__form-group form__form-group-field">
          <RegisteredCheckBoxField
            name={'status'}
            value={watch('dropInternet')}
            onChange={(value) => {}}
            defaultChecked={false}
            label={"Active the configuration"}
            register={register}
            disabled
          />
        </div>
    </Fragment>
  )
}

VpnZeroTrustGenericFieldsForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
}

export default VpnZeroTrustGenericFieldsForm;
