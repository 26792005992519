import React, {Fragment, useState} from 'react';
import PropTypes from "prop-types";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import EventsTable from "../../../shared/components/table/withdata/EventsTable";
import {EVENTS_DOMAINS, EVENTS_IPS} from "../../../queries/SecurityEvents";
import {WithDetailDrawer} from "../../../shared/hocs/WithDetailDrawer";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {
  areParamsValid,
  fixHistoryURL,
  isParamValid,
  useQueryParams
} from "../../../shared/components/router/QueryNavigationHelpers";
import {SECT_EVENTS} from "../index";
import SecurityEventsExport from "../../Events/components/EventsExport";
import TrackingLink from '../../../shared/components/Link'


const EventTableWithDrawer = WithDetailDrawer(EventsTable);


const BoardEventsSection = ({boardId, eventClass = null}) => {
  const nav = useNavigate();
  const location = useLocation();
  const params = useQueryParams();
  const [isExportOpen, setExportOpen] = React.useState(false)

  const requiredParams = {
    overview: [SECT_EVENTS],
    focus: [EVENTS_IPS, EVENTS_DOMAINS]
  }

  function toggleExport(_isExportOpen) {
    setExportOpen(!_isExportOpen)
  }

  const focus = isParamValid(params.get('focus'), requiredParams.focus) ? params.get('focus') : EVENTS_IPS;
  if (!areParamsValid(params, requiredParams)) {
    fixHistoryURL(params, requiredParams, nav, location);
  }

  const [ isDetailOpen, setIsDetailOpen ] = useState(false);

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <TrackingLink to={`?overview=${SECT_EVENTS}&focus=${EVENTS_IPS}${eventClass ? '&eventClass=' + eventClass : ""}`} className={"p-sm-1"}>
            <Button className="rounded" size="sm" outline active={focus === EVENTS_IPS} onClick={() => setIsDetailOpen(false)}>IPs</Button>
          </TrackingLink>
          <TrackingLink to={`?overview=${SECT_EVENTS}&focus=${EVENTS_DOMAINS}${eventClass ? '&eventClass=' + eventClass : ""}`} className={"p-sm-1"}>
            <Button className="rounded" size="sm" outline active={focus === EVENTS_DOMAINS} onClick={() => setIsDetailOpen(false)}>Domains</Button>
          </TrackingLink>
          <div className='event-export-btn'>
            <SecurityEventsExport isOpen={isExportOpen} toggle={() => toggleExport(isExportOpen)} eventType={focus} boardId={boardId} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <EventTableWithDrawer
                eventType={focus}
                fixedBoardId={boardId}
                isOpen={isDetailOpen}
                setIsDetailOpen={setIsDetailOpen}
                tableConfigOverride={{withHover: true}}
                title={"EVENT DETAILS"}
                eventclassName={eventClass}
                daysBack={30}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

BoardEventsSection.propTypes = {
  boardId: PropTypes.string.isRequired,
}

export default BoardEventsSection;
