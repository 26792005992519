import React, {Fragment, useCallback, useState} from 'react';
import {Col, Container, Row, Card, CardBody} from 'reactstrap';

// import { useDetailsContext } from "../../../contexts/DetailContext";
import EventsTable from '../../../shared/components/table/withdata/EventsTable';
import {Drawer} from "@material-ui/core";

const Events = () => {


  const [ isDetailOpen, setIsDetailOpen ] = useState(false);
  // const [ isDetailLoading, setIsDetailLoading ] = useState(false);
  // const [ _selectedEventId, setSelectedEventId ] = useState(null);

  const onRowClick = useCallback((dataId) => {
    // setSelectedEventId(dataId)
    setIsDetailOpen(true);
  }, [])

  const onDetailsClose = useCallback(() => {setIsDetailOpen(false)}, [])

  return (
    <Fragment>
      <Container className="devices-container">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Security Events</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <EventsTable onRowClick={onRowClick} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Drawer open={isDetailOpen} onClose={onDetailsClose} id={"myCollapse"} anchor={"right"} >
        <Container>
          <Row>
            <Col>
              {/*<EventDetailsContent eventId={selectedEventId} />*/}
            </Col>
          </Row>
        </Container>
      </Drawer>
    </Fragment>);
};

export default Events;
