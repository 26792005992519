import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrl} from "../config/api"
import {useCallback} from "react";
import moment from "moment";
import toast from 'react-hot-toast';
import axios from "axios";

import {addAuthToAxiosOpts} from "../config/queryopts";

export const EVENTS_IPS = 'ips';
export const EVENTS_DOMAINS = 'domains';

export const EVENT_CLASS_SECURITY = 'security';
export const EVENT_CLASS_POLICY = 'policy';

const fileDownload = require('js-file-download');
const formatDateUTCtoYearMonthDayTimeFile = (date) => moment.utc(date).local().format("YYYYMMDDHHmmss");

export function exportEvents(accessToken, eventType, format, searchFilter, boardId, blockedOnly) {
  toast.dismiss()
  format = format.toLowerCase();
  let uri = `${apiUrl}/operations/security-data/export/events/${eventType}`;
  uri += (boardId) ? `/${boardId}` : '';
  // Mapping following datetime to the "start of UTC day" is used to hit the query cache - DON'T change it
  uri += `?exportFormat=${format}&searchTerm=${searchFilter}&blockedOnly=${!!blockedOnly}`;
  uri += `&from=${moment().utc().subtract(365, 'days').startOf('day').format()}`;
  toast.loading('Generating export file');
  axios.get(
    uri,
    addAuthToAxiosOpts(accessToken, {})
  )
    .then(res => {
      toast.dismiss();
      if (res.data) {
        fileDownload(String(res.data), `export-${eventType}-${formatDateUTCtoYearMonthDayTimeFile(moment())}.` + format);
        toast('Events exported successfully');
      } else {
        console.error('Received empty response');
        toast('Error while exporting events');
      }
    })
    .catch(err => {
      toast.dismiss();
      console.error(err);
      if (err.request?.response) {
        toast(`Error: ${err.request.response.replace(']', '').replace('[', '')}.`, {icon: '❌'});
      } else {
        toast(`Error while exporting events.`, {icon: '❌'});
      }
    });
}


export const eventsSecurityFetcher = (accessToken, eventType, boardId, blockedOnly, eventClass, daysBack) => {
  let uri = '';
  if (eventType === EVENTS_IPS) {
    uri += '/ips';
  } else if (eventType === EVENTS_DOMAINS) {
    uri += '/domains';
  } else {
    throw new Error('eventType must be one of EVENT_IPS or EVENT_DOMAINS');
  }
  uri += (boardId) ? `/${boardId}` : '';
  let params = "?";
  params += `blockedOnly=${!!blockedOnly}`;
  // Mapping following datetime to the "start of UTC day" is used to hit the query cache - DON'T change it
  params += daysBack ? `&from=${moment().utc().subtract(daysBack, 'days').startOf('day').format()}` : '';
  params += eventClass ? `&eventClass=${eventClass}` : '';
  return axios.get(`${apiUrl}/operations/security-data/events${uri}${params}`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useSecurityEventsQuery = (eventType, boardId = null, blockedOnly = true, eventClass= null, daysBack = null, fetcher = eventsSecurityFetcher) => {
  return useQueryWithAuthentication(
    ['events-security', eventType, boardId, eventClass],
    (accessToken) => fetcher(accessToken, eventType, boardId, blockedOnly, eventClass, daysBack)
  )
}



/*****************************************************************************************************
 Shorthands -> returning data-hooks with lists [same as useQuery but data contains the selected list]
 *****************************************************************************************************/


export const useSecurityEventsQueryDataFn = (eventType, boardId = null, blockedOnly = true, eventClass= null, daysBack = null) => {
  /*
  Following useCallback has a named callback with PascalCase to avoid the ESLint rule.
  More info on: https://github.com/facebook/react/issues/20531 and https://reactjs.org/docs/hooks-faq.html#what-exactly-do-the-lint-rules-enforce.
  IMPORTANT NOTE: This function returns a custom hook so to enforce hook rules please assign the return value of this
  function to a useXXX variable.
   */
  return useCallback(function MemoizedCustomHook() {
    const {data, ...other} = useSecurityEventsQuery(eventType, boardId, blockedOnly, eventClass, daysBack);
    const listData = data?.events;
    return {...other, data:listData}
  }, [boardId, eventType, blockedOnly, eventClass]);
}
