import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Tooltip} from 'recharts';
import {Card, CardBody} from "reactstrap";
import LoadingSpinner from "../LoadingSpinner";
import {Link} from "react-router-dom";
import TrackingLink from '../../components/Link'

// const _ = require('lodash')


const SmallGaugeChart = ({
   title,
   value,
   color,
   subtitle = "",
   label = "",
   isLoading = false,
   tooltipText =  "",
   isError = false,
   to = null
 }) => {

  label = label ? label : value;
  const pieChartData = [
    { value: value, fill: color},
    { value: 100 - value, fill: '#787985' }
  ];

  return (
    <Card>
      <CardBody>
        {isLoading ? <LoadingSpinner /> : (
          <Fragment>
            <div className="card__title">
              <h5 className="bold-text">{title}</h5>
              <h5 className="subhead">{subtitle ? subtitle : ""}</h5>
            </div>
            <div className="chart__gauge__wrapper">
              <div className="chart__gauge__wrapper-pie">
                <PieChart height={150} width={280}
                >
                  <Pie
                    data={pieChartData}
                    dataKey="value"
                    cx={135}
                    cy={140}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={113}
                    outerRadius={128}
                    paddingAngle={0}
                  />
                  <Tooltip
                    separator={''}
                    wrapperStyle={{zIndex: "10000"}}
                    formatter={(value, name, props) =>  ["", tooltipText] }
                    itemStyle={{color: "white", whiteSpace: "break-spaces"}}
                  />
                </PieChart>
                {
                  to ? <TrackingLink to={to}><p style={{color: color}} className="chart__gauge__value-label">{label}</p></TrackingLink>
                  : <p style={{color: color}} className="chart__gauge__value-label">{label}</p>
                }
              </div>
            </div>
          </Fragment>
          )
        }
      </CardBody>
    </Card>
  )
}


SmallGaugeChart.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  to: PropTypes.string,
  tooltipText: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default SmallGaugeChart;
