import React from 'react';
import { Badge } from 'reactstrap';

import TableWithDataHook from "../../../shared/components/table/TableWithDataHook";
import PropTypes, {number, string} from "prop-types";
import {useDogmaDevicesQueryDataFn} from "../../../queries/DogmaDevices";
import {
  getDeviceRunningState,
  getDeviceStatusLabel,
  getStatusColorFromLabel
} from "../../../shared/helpers/querydata/devicesdata";


const statusAccessor = (row) => getDeviceStatusLabel(getDeviceRunningState(row))

const tableColumns = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'IP', accessor: 'ip' },
  { Header: 'MAC', accessor: 'mac' },
  { Header: 'Status', accessor: statusAccessor, Cell: ({value}) => (<Badge color={getStatusColorFromLabel(value)} className={"outline"}>{value}</Badge>) },
];

const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}

const BoardDevicesTable = ({fixedBoardId, ...props}) => {
  const useDataQuery = useDogmaDevicesQueryDataFn(fixedBoardId);

  return (
    <TableWithDataHook
      useDataQuery={useDataQuery}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
      {...props}
    />
  );
};

BoardDevicesTable.propTypes = {
  fixedBoardId: PropTypes.oneOfType([string, number]).isRequired,
};

export default BoardDevicesTable;
