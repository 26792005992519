import {Controller} from "react-hook-form";
import CreatableSelect from "./CreatableSelect";
import React, {useState} from "react";
import PropTypes from "prop-types";

const ControlledMultiSelectTextWithTitle = ({
  control,
  title,
  name,
  valueFn,
  rules = {},
  error = null,
  validationFn = (value) => true,
  ...selectProps
}) => {
  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const orderOptions = values => {
    return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
  };

  const onChangeCustom = (value, { action, removedValue }, onChange) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = value.filter(v => v.isFixed);
        break;
      default:
        throw new Error('Option not supported')
    }

    onChange(orderOptions(value))
  }

  const [inputValue, setInputValue] = useState('');

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (e, onChange, value) => {
    if (!inputValue || !validationFn(inputValue)) return;
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        // DO NOT INSERT DUPLICATE OPTION
        if (value.map((opt) => opt.value).includes(inputValue)) {
          return;
        }

        onChange(value ? value.concat([createOption(inputValue)]) : [createOption(inputValue)]);
        setInputValue('');
        e.preventDefault();
        break;
      default: 
        return
    }
  }

  return (
    <div className={`form__form-group ${error ? "form__form-group-error-validation" : ""}`}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className='form__form-group-input-wrap'>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: {onChange, value, ref}}) => (
              <CreatableSelect
                components={{DropdownIndicator: null}}
                inputValue={inputValue}
                isMulti
                inputRef={ref}
                styles={styles}
                value={valueFn(value)}
                onChange={(valueSelect, select) => onChangeCustom(valueSelect, select, onChange)}
                onKeyDown={(evt) => handleKeyDown(evt, onChange, value)}
                onInputChange={(input) => setInputValue(input)}
                menuIsOpen={false}
                placeholder={'Type a word and press enter'}
                {...selectProps}
              />
            )}
          />
          {error && <span className="form__form-group-error">{error.message}</span>}
        </div>
      </div>
    </div>
  )
}


ControlledMultiSelectTextWithTitle.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  valueFn: PropTypes.func,
  title: PropTypes.string.isRequired,
}


export default ControlledMultiSelectTextWithTitle;
