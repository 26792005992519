import axios from "axios";
import {apiUrl} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


export const configurationCompanyFetcher = (accessToken, companyId) => {
  return axios.get(`${apiUrl}/operations/companies/${companyId}/config/customer`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.data);
}

export const useCompanyConfigQuery = (companyId, fetcher = configurationCompanyFetcher) => {
  return useQueryWithAuthentication(['company-config-customer', companyId], (accessToken) => fetcher(accessToken, companyId), {
    staleTile: 0, cacheTime: 0
  })
}
