import { createClient } from 'urql'
import { makeOperation, fetchExchange } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'

// --

export const LOGIN_TYPES = {
  CUSTOMER_CONSOLE: 'CUSTOMER_CONSOLE',
  NATIVE: 'NATIVE'
}

const addAuthToOperation = ({ authState, operation }) => {
  if (!authState || !authState.token) {
    return operation
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {}

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      // withCredentials: true,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer: ${authState.token}`,
      },
    },
  })
}

export function buildClient(
  apiUrl,
  getAuth
) {
  const exchanges = getAuth
    ? [
      authExchange({
        addAuthToOperation,
        getAuth
      }),
      fetchExchange
    ]
    : [fetchExchange]
  return createClient({
    url: `${apiUrl}`,
    exchanges
  })
}
