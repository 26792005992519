import {useAuth0} from "@auth0/auth0-react";
import auth0Config from "../../config/auth0";

export const useUserInfo = () => {
  const {user, isLoading, error} = useAuth0()
  const metadataKey = auth0Config.audience + '/metadata';
  const rolesKey = auth0Config.audience + '/roles';
  const hasRole = (role) =>{
    return user[rolesKey].includes(role)
  }
  return {
    companyId: (!isLoading && !error && user) ? user[metadataKey]?.company_id : null,
    //companyIdLegacy: (!isLoading && !error && user) ? user[metadataKey]?.company_id_legacy : null,
    isLoading: isLoading,
    error: error || (!isLoading && !error && (!user || !user.hasOwnProperty(metadataKey))),
    roles: (!isLoading && !error && user) ? user[rolesKey] : null,
    hasRole
  }
}

export const ROLE_SUPERADMIN = 'superadmin'