import {useQuery} from "react-query";
import {useAuth0} from "@auth0/auth0-react";
import {toast} from "react-hot-toast";


export const useAuth0AccessToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery('auth0-access-token', async () => (await getAccessTokenSilently()))
}


export const useQueryWithAuthentication = (queryKey, queryFnWithToken, options = null) => {
  /*
  Check isIdle before isLoading since it will be true while getting the accessToken.
  For more info refer to "Dependent Queries" on react-query docs.
   */
  const { data: accessToken } = useAuth0AccessToken()
  options = (options) ? options : {};
  options.enabled = (options.hasOwnProperty('enabled')) ? options.enabled && !!accessToken : !!accessToken;
  const query = useQuery(queryKey, () => queryFnWithToken(accessToken), options);

  if (query.isError) {
    if (query.error.response.status === 401) {
      window.location.reload()
    }
  
    if (query.error.response.status === 403) {
      toast.error('Access Forbidden')
    }
  }
  
  return query
}
