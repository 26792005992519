import {addAuthToFetchOpts} from '../../config/queryopts'
import {useQueryWithAuthentication} from "../index";
import {apiUrlUpdate} from "../../config/api"
import axios from "axios";


export const devicesUpdateListFetcher = (accessToken, companyId) => {
  return axios.get(`${apiUrlUpdate}/companies/${companyId}/devices/updates`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useDevicesUpdateListQuery = (companyId, fetcher = devicesUpdateListFetcher) => {
  return useQueryWithAuthentication(
    ['devices-updated', companyId],
    (accessToken) => fetcher(accessToken, companyId),
    {refetchInterval: 10000, enabled: companyId !== null, cacheTime: 0}
  )
}
