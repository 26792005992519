import {addAuthToFetchOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrl} from "../config/api"
import axios from "axios";

export const dogmaGroupsFetcher = (accessToken) => {
  return axios.get(`${apiUrl}/operations/board-groups`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useDogmaGroupsQuery = (fetcher = dogmaGroupsFetcher) => {
  return useQueryWithAuthentication(['groups'], (accessToken) => fetcher(accessToken))
}
