export const isValidDomain = (value) =>
  new RegExp(/^([a-z0-9|-]+[a-z0-9]+\.)*[a-z0-9|-]+[a-z0-9]+\.[a-z]{2,}$/).test(value);

export const isValidIPorSubnet = (value) =>
  new RegExp(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?(\/[0-9]{1,2})?)$/).test(value);

export const isValidIP = (value) =>
  new RegExp(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value);

export const isValidMac = (value) =>
  new RegExp(/^((([0-9A-F]{2}:){5})|(([0-9A-F]{2}-){5})|([0-9A-F]{10}))([0-9A-F]{2})$/i).test(value)
