import React from 'react';
import ReactDOM from 'react-dom';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Routes
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './containers/App/App';
import UnknownError from './containers/App/Error'
import * as serviceWorker from './serviceWorker';
import sentryConfig from './config/sentry'

Sentry.init({
  dsn: `https://${sentryConfig.sentryKey}.ingest.sentry.io/6534133`,
  enabled: process.env.NODE_ENV === 'production',
  environment: window.location.href.includes('staging') ? 'staging' : 'production',
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    ),
})],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const Main = Sentry.withProfiler(
  Sentry.withErrorBoundary(App, { fallback: <UnknownError /> })
)

ReactDOM.render(
  <React.StrictMode>
    <Main routes={SentryRoutes} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
