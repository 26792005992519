import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrlUpdate} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const UpdateBoardConfigFetcher = (accessToken, params) => {
  return axios.put(`${apiUrlUpdate}/devices/acceptedUpdate`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useUpdateBoardConfigMutation = (fetcher = UpdateBoardConfigFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-boards'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('update-boards')
    }
  )
}
