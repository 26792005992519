import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {useDogmaBoardsQuery} from "../../../../queries/DogmaBoards";
import SmallPercentageChartCard, {CleanLabel} from "../SmallPercentageChartCard";
import {
  BOARD_STATUS_RUNNING,
  enrichBoardsDataWithRunningState
} from "../../../helpers/querydata/boardsdata";
import DetailBoardsModal from "../../modals/DetailBoardsModal";


const SecurityEventsSmallBarChartCard = ({title, modal}) => {

  const { data, isIdle, isLoading, isError } = useDogmaBoardsQuery();
  const chartData = useMemo(() => {
    if (!isIdle && !isLoading && !isError) {
      const enrichedBoardData = enrichBoardsDataWithRunningState(data);
      const activeBoards = enrichedBoardData.filter(b => b.runningState === BOARD_STATUS_RUNNING)
      return {
        value: activeBoards.length,
        total: enrichedBoardData.length,
        percent: Math.floor(activeBoards.length / enrichedBoardData.length * 100),
      }
    }
    return null;
  }, [data, isIdle, isLoading, isError])

  return (
    <SmallPercentageChartCard
      title={title}
      data={chartData}
      isLoading={isIdle || isLoading}
      isError={isError}
      labelComponent={modal ? <DetailBoardsModal data={chartData} />: <CleanLabel data={chartData} /> }
    />
  );
};

SecurityEventsSmallBarChartCard.propTypes = {
  title: PropTypes.string.isRequired,
}

export default SecurityEventsSmallBarChartCard;
