import React, {useCallback} from "react";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {useDogmaGroupsQuery} from "../../../../queries/DogmaGroups";
import PropTypes from "prop-types";


const tableColumns = [
  { Header: 'Name', accessor: 'name' },
];

const tableConfig = {
  withPagination: true,
  withHover: true,
  withSearchEngine: true,
}

const ConfigurationGroupsTable = ({onRowClick}) => {
  const { data, isIdle, isLoading, isError } = useDogmaGroupsQuery();
  const getRowDataId = useCallback((row, i) => (row.original.id), [])

    return (
      <TableWithData
        tableColumns={tableColumns}
        tableConfig={tableConfig}
        data={data}
        isError={isError}
        isLoading={isLoading || isIdle}
        onRowClick={onRowClick}
        getRowDataId={getRowDataId}
      />
    )
}

ConfigurationGroupsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired
}

export default ConfigurationGroupsTable;
